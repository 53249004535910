import React, { useState } from 'react';

import { TextField, MenuItem, Button } from '@material-ui/core';
import { HiDocumentReport } from 'react-icons/hi';
import { ManifestContainer } from '../../styles';

import { useAuth } from '~/hooks/useAuth';

interface IProps {
  open: boolean;
  onClose: () => void;
  chNfe: string;
}

const Manifest: React.FC<IProps> = ({ open, onClose, chNfe }) => {
  const {
    currentCompany: { cnpj },
  } = useAuth();
  const [filial, setFilial] = useState(
    `${cnpj.slice(8, 12)}-${cnpj.slice(12, 14)}`
  );
  return (
    <ManifestContainer className={`${open ? 'opened' : ''}`}>
      <h4>
        <HiDocumentReport className="mr-2" />
        Realizar Manifestação
      </h4>
      <div className="d-flex align-items-center mt-4">
        <TextField
          label="CNPJ"
          disabled
          className="mr-2"
          size="small"
          value={`${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}`}
          variant="outlined"
        />
        /
        <TextField
          className="ml-2"
          size="small"
          style={{ maxWidth: '100px' }}
          variant="outlined"
          value={filial}
          onChange={(e) => setFilial(e.target.value)}
          placeholder="0000-00"
        />
      </div>
      <TextField
        label="Chave NF-e"
        className="mt-3"
        size="small"
        variant="outlined"
        value={chNfe}
        disabled
      />
      <TextField
        label="Manifestação"
        select
        className="mt-3"
        variant="outlined"
        size="small"
      >
        <MenuItem value={1}>Ciência da Operação</MenuItem>
        <MenuItem value={2}>Confirmação da Operação</MenuItem>
        <MenuItem value={3}>Desconhecimento da Operação</MenuItem>
        <MenuItem value={4}>Operação não Realizada</MenuItem>
      </TextField>
      <TextField
        label="Justificativa"
        className="mt-3"
        size="small"
        variant="outlined"
      />
      <div className="d-flex mt-4">
        <Button type="button" color="primary" variant="contained">
          Enviar
        </Button>
        <Button
          type="button"
          color="primary"
          variant="outlined"
          className="ml-2"
          onClick={() => onClose()}
        >
          Cancelar
        </Button>
      </div>
    </ManifestContainer>
  );
};

export default Manifest;
