import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import { editUnActive } from '~/store/modules/register/actions';

import { Container } from './styles';

import DetailDocs from './components/DetailDocs';
import DocItemList from './components/DocItemList';

// import { normalizeCnpj, normalizeCpf } from '../../utils';
import { useAuth } from '~/hooks/useAuth';

interface IUpdateDoc {
  id: string;
  type: 'company' | 'pf' | 'pj';
}
interface itemDocumentsInt {
  id: string;
  label: string;
  status: string;
  file: string;
  rejectReasons: string[];
}
interface itemInt {
  id: string;
  name: string;
  doc: string;
  type: 'company' | 'pf' | 'pj';
  documents: itemDocumentsInt[];
}
interface documentActiveInt {
  active: boolean;
  item: itemInt;
}

interface INextItem {
  (): void;
}

interface IProps {
  handleNextItem: INextItem;
}

const Documents: React.FC<IProps> = () => {
  // const queryString = window.location.search;
  // const urlParams = new URLSearchParams(queryString);
  const [loading] = useState(false);
  const [documentActive, setDocumentActive] = useState<documentActiveInt>({
    active: false,
    item: {
      id: '',
      name: '',
      doc: '',
      type: 'company',
      documents: [],
    },
  });

  const {
    currentCompany: { clieId: id },
  } = useAuth();
  const dispatch = useDispatch();

  const [items, setItems] = useState<itemInt[]>([]);

  useEffect(() => {
    async function loadDocs() {
      // setLoading(true);
      // try {
      //   const { data } = await axios.get(`/documents/documents`);
      //   const { data: companyData } = await axios.get(`/companies/company`);
      //   const { data: pfData } = await axios.get('/partners/physical-persons');
      //   const companyDocs: any = [];
      //   const memberDocs: any = [];
      //   data.forEach((d: any) => {
      //     if (d.documentOwnerType.type === 'COMPANY') {
      //       companyDocs.push({
      //         id: d.id,
      //         label: d.documentType.type,
      //         status: d.documentStatus.status,
      //         file: d.file,
      //         rejectReasons: d.rejectReasons,
      //       });
      //     }
      //     if (d.documentOwnerType.type === 'PHYSICAL PERSON PARTNER') {
      //       memberDocs.push({
      //         id: d.id,
      //         memberId: d.documentOwnerId,
      //         label: d.documentType.type,
      //         status: d.documentStatus.status,
      //         file: d.file,
      //         rejectReasons: d.rejectReasons,
      //       });
      //     }
      //   });
      //   const itemsPf: itemInt[] = [];
      //   pfData.forEach((m: any) => {
      //     const docsM: any = [];
      //     memberDocs.forEach((d: any) => {
      //       if (d.memberId === m.id) {
      //         docsM.push({
      //           id: d.id,
      //           label: d.label,
      //           status: d.status,
      //           file: d.file,
      //           rejectReasons: d.rejectReasons,
      //         });
      //       }
      //     });
      //     itemsPf.push({
      //       id: m.id,
      //       name: m.name || `Sócio PF`,
      //       doc: normalizeCpf(m.cpf),
      //       documents: docsM,
      //       type: 'pf',
      //     });
      //   });
      //   setItems([
      //     {
      //       id: companyData.id,
      //       name: companyData.companyName,
      //       doc: normalizeCnpj(companyData.cnpj),
      //       documents: companyDocs,
      //       type: 'company',
      //     },
      //     ...itemsPf,
      //   ]);
      //   if (urlParams.get('pendency') === '1') {
      //     const type = urlParams.get('type');
      //     const id = urlParams.get('id');
      //     if (type === 'company') {
      //       setDocumentActive({
      //         active: true,
      //         item: {
      //           id: '',
      //           name: companyData.companyName,
      //           doc: normalizeCnpj(companyData.cnpj),
      //           documents: companyDocs,
      //           type: 'company',
      //         },
      //       });
      //     } else {
      //       const member = itemsPf.find((i) => i.id === id);
      //       if (member) {
      //         setDocumentActive({
      //           active: true,
      //           item: member,
      //         });
      //       }
      //     }
      //   }
      // } catch (err) {
      //   if (err.response && err.response.status === 401) {
      //     dispatch(signOut());
      //     toast.error('Sua sessão expirou, entre novamente');
      //   } else if (err.response && err.response.status === 403) {
      //     toast.error('Você não está autorizado a acessar este recurso');
      //   } else if (err.response && err.response.status === 500) {
      //     toast.error(
      //       'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
      //     );
      //   } else {
      //     toast.error('Ocorreu um erro, tente novamente mais tarde');
      //   }
      // }
      // setLoading(false);
    }
    loadDocs();
  }, [id]); // eslint-disable-line

  function handleChangeDoc({ id, type }: IUpdateDoc) {
    const itemObj = items;
    itemObj.map((i) => {
      if (i.type === type) {
        i.documents.map((d) => {
          if (d.id === id) {
            d.status = 'UPLOADED'; // eslint-disable-line
            return d;
          }
          return d;
        });
        return i;
      }
      return i;
    });
    setItems(itemObj);
  }

  function resetForm() {
    dispatch(editUnActive());
    setDocumentActive({
      active: false,
      item: {
        id: '',
        name: '',
        doc: '',
        type: 'company',
        documents: [],
      },
    });
  }

  return (
    <Container>
      {documentActive.active && Object.keys(documentActive.item).length > 0 ? (
        <DetailDocs
          item={documentActive.item}
          resetForm={() => resetForm()}
          updateDoc={(e) =>
            handleChangeDoc({
              id: e,
              type: documentActive.item.type,
            })
          }
        />
      ) : (
        <>
          <div className="header_component d-flex">
            <h4>Documentos</h4>
          </div>
          <div className="content_component">
            {loading && <LinearProgress />}
            <div className="grid-docs">
              {items.length > 0 &&
                items.map((item, index) => (
                  <DocItemList
                    item={item}
                    key={String(index)}
                    setDocumentActive={(e) => setDocumentActive(e)}
                  />
                ))}
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default Documents;
