import React, { useState, useEffect } from 'react';

import { Avatar, TextField, Button, LinearProgress } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { toast } from 'react-toastify';
import { authApi } from '~/services/auth';
import { Container } from './styles';
import { useAuth } from '~/hooks/useAuth';
import ModalConfirmation from './ModalConfirmation';

interface IUsers {
  id: string;
  name: string;
  email: string;
  password: string;
}

interface ICompany {
  users: IUsers[];
}

interface IDeleteUser {
  id: null | string;
  active: boolean;
}

const Configuration: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState<number>(1);
  const [users, setUsers] = useState<IUsers[]>([]);

  const { currentCompany, signOut, user: loggedUser } = useAuth();

  const [newUserActive, setNewUserActive] = useState(false);
  const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    password: '',
  });
  const [newUserError, setNewUserError] = useState({
    name: '',
    email: '',
    password: '',
  });

  const [deleteActive, setDeleteActive] = useState<IDeleteUser>({
    active: false,
    id: null,
  });

  useEffect(() => {
    async function loadUsers() {
      setLoading(true);
      try {
        const { data } = await authApi.get<ICompany>(
          `/companies/${currentCompany.id}`
        );
        setUsers(
          data.users.filter(
            (u) =>
              u.email.indexOf('@bfcdigital.com.br') === -1 &&
              u.email.indexOf('@grupobfc.com.br') === -1
          )
        );
      } catch (err) {
        if (err.response && err.response.status === 401) {
          signOut();
          toast.error('Sua sessão expirou, entre novamente');
        } else if (err.response && err.response.status === 403) {
          toast.error('Você não está autorizado a acessar este recurso');
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
          );
        } else {
          toast.error('Ocorreu um erro, tente novamente mais tarde');
        }
      }
      setLoading(false);
    }
    loadUsers();
  }, [currentCompany]); // eslint-disable-line

  function resetNewUser() {
    setNewUser({
      name: '',
      email: '',
      password: '',
    });
    setNewUserError({
      name: '',
      email: '',
      password: '',
    });
    setNewUserActive(false);
  }

  const handleNewUser = async () => { //eslint-disable-line
    setNewUserError({
      name: '',
      email: '',
      password: '',
    });
    const lockError = {
      name: '',
      email: '',
      password: '',
    };
    if (newUser.name.length === 0) {
      lockError.name = 'Preencha esse campo';
    } else if (newUser.name.indexOf(' ') === -1) {
      lockError.name = 'Preencha seu nome completo';
    }

    const splitedEmail = newUser.email.split('@');
    if (newUser.email.length === 0) {
      lockError.email = 'Preencha esse campo';
    } else if (newUser.email.indexOf('@') === -1) {
      lockError.email = 'Preencha um email válido';
    } else if (splitedEmail[1].indexOf('.') === -1) {
      lockError.email = 'Preencha um email válido';
    }
    if (
      lockError.name !== '' ||
      lockError.email !== '' ||
      lockError.password !== ''
    ) {
      return setNewUserError(lockError);
    }

    try {
      const response = await authApi.post('/company-users', {
        name: newUser.name,
        email: newUser.email,
        password: newUser.password,
        companyId: currentCompany.id,
      });
      setUsers([...users, response.data]);
      setNewUser({
        name: '',
        email: '',
        password: '',
      });
      setNewUserActive(false);
      toast.success('Usuário cadastrado');
    } catch (err) {
      if (err.response && err.response.status === 401) {
        signOut();
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error(err.response.data.message);
      }
    }
  };

  async function handleDelete() {
    setLoading(true);
    try {
      await authApi.delete(
        `/company-users/${deleteActive.id}/${currentCompany.id}`
      );
      setUsers(users.filter((u) => u.id !== deleteActive.id));
      setDeleteActive({
        active: false,
        id: null,
      });
      toast.info('Usuário Deletado da Empresa');
    } catch (err) {
      if (err.response && err.response.status === 401) {
        signOut();
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
    setLoading(false);
  }

  return (
    <Container>
      <h3>Configurações</h3>
      <ModalConfirmation
        open={deleteActive.active}
        setOpen={() =>
          setDeleteActive({
            active: false,
            id: null,
          })
        }
        loading={loading}
        onDelete={handleDelete}
      />
      {loading && <LinearProgress />}
      <div className="d-flex">
        <button
          type="button"
          className="btn"
          onClick={() => setSection(1)}
          style={{ borderBottom: section === 1 ? '2px solid #3757a1' : '' }}
        >
          Usuários
        </button>
      </div>
      {section === 1 && (
        <div id="users_company">
          <div id="grid_users">
            {users.length > 0 &&
              users.map((user, index) => (
                <div key={String(index)} className="grid-card">
                  <div className="d-flex align-items-center">
                    <Avatar>{user.name[0]}</Avatar>
                    <div>
                      <h5>{user.name}</h5>
                      <span>{user.email}</span>
                    </div>
                  </div>
                  {user.id !== loggedUser.id &&
                    loggedUser.email === 'ti@bfcdigital.com.br' && (
                      <button
                        type="button"
                        onClick={() =>
                          setDeleteActive({
                            active: true,
                            id: user.id,
                          })
                        }
                      >
                        <Delete />
                      </button>
                    )}
                </div>
              ))}
            {newUserActive ? (
              <div className="grid-card new-user new-user-active">
                <TextField
                  label="Nome Completo"
                  value={newUser.name}
                  onChange={(e) => {
                    setNewUser({ ...newUser, name: e.target.value });
                    if (newUserError.name !== '') {
                      setNewUserError({
                        ...newUserError,
                        name: '',
                      });
                    }
                  }}
                  error={newUserError.name !== ''}
                  helperText={newUserError.name}
                />
                <TextField
                  label="Email"
                  value={newUser.email}
                  onChange={(e) => {
                    setNewUser({ ...newUser, email: e.target.value });
                    if (newUserError.email !== '') {
                      setNewUserError({
                        ...newUserError,
                        email: '',
                      });
                    }
                  }}
                  error={newUserError.email !== ''}
                  helperText={newUserError.email}
                />
                <TextField
                  label="Senha"
                  type="password"
                  value={newUser.password}
                  onChange={(e) => {
                    setNewUser({ ...newUser, password: e.target.value });
                    if (newUserError.password !== '') {
                      setNewUserError({
                        ...newUserError,
                        password: '',
                      });
                    }
                  }}
                  error={newUserError.password !== ''}
                  helperText={newUserError.password}
                />
                <div className="d-flex mt-4 mb-2">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNewUser}
                  >
                    Salvar
                  </Button>
                  <button type="button" className="btn" onClick={resetNewUser}>
                    Cancelar
                  </button>
                </div>
              </div>
            ) : (
              <>
                {!loading && (
                  <div className="grid-card new-user">
                    <button
                      type="button"
                      className="btn"
                      onClick={() => setNewUserActive(true)}
                    >
                      Novo Usuário
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </Container>
  );
};

export default Configuration;
