import { useSelector, TypedUseSelectorHook } from 'react-redux';

import { IInitialState as IAuth } from './modules/auth/types';
import { IInitialState as IUser } from './modules/user/types';
import { IInitialState as IRegister } from './modules/register/types';
import { IInitialState as IAnticipation } from './modules/anticipation/types';
import { IInitialState as ICheckAnticipation } from './modules/checkAnticipation/types';
import { IInitialState as INfseAnticipation } from './modules/nfseAnticipation/types';

export interface RootState {
  auth: IAuth;
  user: IUser;
  register: IRegister;
  anticipation: IAnticipation;
  checkAnticipation: ICheckAnticipation;
  nfseAnticipation: INfseAnticipation;
}

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default useTypedSelector;
