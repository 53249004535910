import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { Container } from './styles';

const Confirm: React.FC = ({ match }: any) => {
  const history = useHistory();

  async function handleSubmit(e: any) {
    e.preventDefault();

    history.push('/auth/login');
  }

  return (
    <Container>
      <div id="wrap-login">
        <div className="logo-section">
          <img
            src="/assets/images/logo-branca.png"
            alt="BFC Capital Partners LTDA"
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-container">
            <h4>ESTAMOS QUASE LÁ!</h4>
            <p className="descript">
              Enviamos uma mensagem de verificação para seu e-mail (se não
              encontrá-lo, confira sua caixa de spam).
              <br />O seu acesso será liberado assim que seu cadastro for
              verificado.
            </p>
            <div className="button-wrap">
              <Button
                type="submit"
                color="primary"
                size="large"
                variant="contained"
              >
                Entrar
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default Confirm;
