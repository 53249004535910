import React from 'react';

import { Modal } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import { Report } from '@material-ui/icons';

interface IProps {
  open: boolean;
  setOpen: () => void;
  onConfirm: () => void;
}

const ModalDeleteAll: React.FC<IProps> = ({ open, setOpen, onConfirm }) => {
  return (
    <Modal show={open} onHide={setOpen} centered>
      <Modal.Body>
        <div className="d-flex align-items-center">
          <Report
            className="mr-3"
            style={{ fontSize: '80px', color: '#ec4a4a' }}
          />
          <div>
            <h6 className="mb-3 text-right" style={{ fontWeight: 'bolder' }}>
              Tem certeza que deseja excluir todos os títulos?
            </h6>
            <div className="d-flex justify-content-end">
              <Button
                type="button"
                color="primary"
                variant="outlined"
                onClick={onConfirm}
                size="small"
              >
                SIM, EXCLUIR
              </Button>
              <Button
                type="button"
                color="primary"
                variant="contained"
                className="ml-2"
                onClick={setOpen}
                size="small"
              >
                CANCELAR
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalDeleteAll;
