import React, { memo, useState, useEffect } from 'react';

import {
  LinearProgress,
  TextField,
  Menu,
  MenuItem,
  Button,
} from '@material-ui/core';
import { format, addMinutes } from 'date-fns';

import { ArrowBackIos, ArrowForwardIos, ExpandMore } from '@material-ui/icons';
import {
  FaPrint,
  FaSearch,
  FaTimes,
  FaTable,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
} from 'react-icons/fa';

import { Table, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { normalizeCpfCnpj, normalizeCurrency } from '../../utils';
import { normalizeBrl } from '~/pages/main/utils/normalize';

import { ITitlePaid } from '../ITitlePaid';
import { useAuth } from '~/hooks/useAuth';

interface INextItem {
  (): void;
}

interface IPaymentType {
  code: string;
  name: string;
}

interface IProps {
  handleNextItem: INextItem;
}

const Open: React.FC<IProps> = () => {
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [lastPage, setLastPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [totalValue, setTotalValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filters, setFilters] = useState({
    dataTituStart: '',
    dataTituEnd: '',
    dataPagoStart: '',
    dataPagoEnd: '',
    sacaId: '',
    numeDoct: '',
    baixCadaDetaId: '',
    valoTitu: '',
  });
  const [paymentTypes] = useState<IPaymentType[]>([
    {
      code: 'EXCL',
      name: 'BAIXA POR EXCLUSAO',
    },
    {
      code: 'BCS',
      name: 'BX COB SIMPLES',
    },
    {
      code: 'PROR',
      name: 'BX JRS PRORROGACAO',
    },
    {
      code: 'PREJ',
      name: 'BX POR PREJUIIZO',
    },
    {
      code: 'REAP',
      name: 'CH.DEV.REAPRESENTADO',
    },
    {
      code: 'DP_BRAD',
      name: 'DEP. BRADESCO',
    },
    {
      code: 'DP_BRAS',
      name: 'DEP. BRASIL',
    },
    {
      code: 'DP_CH_CAR',
      name: 'DEP.CHEQUE CARTEIRA',
    },
    {
      code: 'DP_CH_CUS',
      name: 'DEP.CHEQUE CUSTODIA',
    },
    {
      code: 'ENC_CON_CE',
      name: 'ENCONTRO DE CONTAS CEDENTE',
    },
    {
      code: 'ENC_CON_SA',
      name: 'ENCONTRO DE CONTAS SACADO',
    },
    {
      code: 'LIQBAIX',
      name: 'LIQUIDACAO APOS BAIXA',
    },
    {
      code: 'PG BCO',
      name: 'LIQUIDACAO COBRANCA',
    },
    {
      code: 'PG_TIT_CH',
      name: 'PAGTO EM CHEQUE',
    },
    {
      code: 'PG_TESOU',
      name: 'PAGTO TESOURARIA',
    },
    {
      code: 'PG TAR CED',
      name: 'PGTO DE TARIFA P/CEDENTE',
    },
    {
      code: 'PG TAR SAC',
      name: 'PGTO DE TARIFA P/SACADO',
    },
    {
      code: 'PGDES',
      name: 'PGTO DESCONTO TITULO',
    },
    {
      code: 'B_ATR',
      name: 'PGTO DUPL.ATRASADA',
    },
    {
      code: 'CARTORIO',
      name: 'PGTO EM CARTORIO',
    },
    {
      code: 'PAR',
      name: 'PGTO PARCIAL',
    },
    {
      code: '60',
      name: 'RECOMPRA CHQ DEVOLVIDO',
    },
    {
      code: '61',
      name: 'RECOMPRA CHQ NAO DEPOSITADOS',
    },
    {
      code: '62',
      name: 'RECOMPRA DUPLICATA',
    },
    {
      code: 'RETENCAO',
      name: 'RETENÇÃO EM BORDERÔ',
    },
    {
      code: 'DEB TARIFA',
      name: 'TARIFA DEBITADA EM OPERAÇÃO',
    },
    {
      code: 'DEBITO',
      name: 'TITULO DEBITADO EM OPERAÇÃO',
    },
    {
      code: 'APROP',
      name: 'PGTO VIA APROPRIAÇÃO',
    },
    {
      code: 'COBTERC',
      name: 'BAIXA BORDERÔ TERCEIROS',
    },
    {
      code: 'DEPO',
      name: 'DEPÓSITOS',
    },
    {
      code: 'DP_BRADCED',
      name: 'RECOMPRA PELO CEDENTE',
    },
    {
      code: 'JURBCO',
      name: 'PGTO JUROS P/BCO',
    },
    {
      code: 'NORMAL',
      name: 'DEPOSITO EM CARTEIRA',
    },
    {
      code: 'PG SAC',
      name: 'PGTO DE TITULO EFETUADO P/SACADO',
    },
    {
      code: 'PG TRAVA',
      name: 'PGTO TRAVA BANCÁRIA',
    },
    {
      code: 'PGBOR',
      name: 'PGTO BORDERO TITULO',
    },
    {
      code: 'PGBORTI',
      name: 'PGTO BORDERO TARIFA',
    },
  ]);

  const [titles, setTitles] = useState<ITitlePaid[]>([]);
  const {
    currentCompany: { clieId: id },
    signOut,
  } = useAuth();

  async function loadTitles(refresh?: boolean) {
    const query: string[] = [];
    if (!refresh) {
      if (filters.dataTituStart) {
        query.push(`dataTituStart=${filters.dataTituStart}`);
      }
      if (filters.dataTituEnd) {
        query.push(`dataTituEnd=${filters.dataTituEnd}`);
      }
      if (filters.dataPagoStart) {
        query.push(`dataPagoStart=${filters.dataPagoStart}`);
      }
      if (filters.dataPagoEnd) {
        query.push(`dataPagoEnd=${filters.dataPagoEnd}`);
      }
      if (filters.sacaId) {
        query.push(`sacaId=${filters.sacaId.replace(/[^\d]/g, '')}`);
      }
      if (filters.numeDoct) {
        query.push(`numeDoct=${filters.numeDoct}`);
      }
      if (filters.valoTitu) {
        query.push(
          `valoTitu=${Number(filters.valoTitu.replace(/[^\d]/g, '')) / 100}`
        );
      }
      if (filters.baixCadaDetaId) {
        query.push(`baixCadaDetaId=${filters.baixCadaDetaId}`);
      }
    }
    try {
      const { data: dataTitlesTotal } = await axios.get(
        `${
          process.env.REACT_APP_API_FACTA
        }/total/titles?situation=paid&${query.map((i) => i).join('&')}`
      );
      setTotalValue(dataTitlesTotal.total);
    } catch (error) {
      console.log(error.message); // eslint-disable-line
    }
    if (page) {
      query.push(`page=${page}`);
    }
    if (perPage) {
      query.push(`perpage=${perPage}`);
    }

    setLoading(true);
    try {
      const { data: dataTitles } = await axios.get(
        `${process.env.REACT_APP_API_FACTA}/titles?situation=paid&${query
          .map((i) => i)
          .join('&')}`
      );
      setTitles(dataTitles.data);
      setLastPage(dataTitles.meta.last_page);
      setTotal(dataTitles.meta.total);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        signOut();
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
    setLoading(false);
  }

  async function handlePrint() {
    const query: string[] = [];

    if (filters.dataTituStart) {
      query.push(`dataTituStart=${filters.dataTituStart}`);
    }
    if (filters.dataTituEnd) {
      query.push(`dataTituEnd=${filters.dataTituEnd}`);
    }
    if (filters.dataPagoStart) {
      query.push(`dataPagoStart=${filters.dataPagoStart}`);
    }
    if (filters.dataPagoEnd) {
      query.push(`dataPagoEnd=${filters.dataPagoEnd}`);
    }
    if (filters.sacaId) {
      query.push(`sacaId=${filters.sacaId.replace(/[^\d]/g, '')}`);
    }
    if (filters.numeDoct) {
      query.push(`numeDoct=${filters.numeDoct}`);
    }
    if (filters.valoTitu) {
      query.push(
        `valoTitu=${Number(filters.valoTitu.replace(/[^\d]/g, '')) / 100}`
      );
    }
    if (filters.baixCadaDetaId) {
      query.push(`baixCadaDetaId=${filters.baixCadaDetaId}`);
    }

    query.push(`clieId=${id}`);

    window.open(
      `${process.env.REACT_APP_API_FACTA}/print/titles/paid?${query
        .map((i) => i)
        .join('&')}`,
      '_blank'
    );
  }

  async function handleCSV() {
    const query: string[] = [];

    query.push(`clieId=${id}`);
    query.push('situation=paid');

    if (filters.dataTituStart) {
      query.push(`dataTituStart=${filters.dataTituStart}`);
    }
    if (filters.dataTituEnd) {
      query.push(`dataTituEnd=${filters.dataTituEnd}`);
    }
    if (filters.dataPagoStart) {
      query.push(`dataPagoStart=${filters.dataPagoStart}`);
    }
    if (filters.dataPagoEnd) {
      query.push(`dataPagoEnd=${filters.dataPagoEnd}`);
    }
    if (filters.sacaId) {
      query.push(`sacaId=${filters.sacaId.replace(/[^\d]/g, '')}`);
    }
    if (filters.numeDoct) {
      query.push(`numeDoct=${filters.numeDoct}`);
    }
    if (filters.valoTitu) {
      query.push(
        `valoTitu=${Number(filters.valoTitu.replace(/[^\d]/g, '')) / 100}`
      );
    }
    if (filters.baixCadaDetaId) {
      query.push(`baixCadaDetaId=${filters.baixCadaDetaId}`);
    }
    query.push(`order=ASC`);

    window.open(
      `${process.env.REACT_APP_API_FACTA}/csv?${query.map((i) => i).join('&')}`,
      '_blank'
    );
  }

  useEffect(() => {
    loadTitles();
  }, [id, page, perPage]); // eslint-disable-line

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function nameTipoBaixa(value) {
    switch (value) {
      case 'EXCL':
        return 'BAIXA POR EXCLUSAO';
      case 'BCS':
        return 'BX COB SIMPLES';
      case 'PROR':
        return 'BX JRS PRORROGACAO';
      case 'PREJ':
        return 'BX POR PREJUIIZO';
      case 'REAP':
        return 'CH.DEV.REAPRESENTADO';
      case 'DP_BRAD':
        return 'DEP. BRADESCO';
      case 'DP_BRAS':
        return 'DEP. BRASIL';
      case 'DP_CH_CAR':
        return 'DEP.CHEQUE CARTEIRA';
      case 'DP_CH_CUS':
        return 'DEP.CHEQUE CUSTODIA';
      case 'ENC_CON_CE':
        return 'ENCONTRO DE CONTAS CEDENTE';
      case 'ENC_CON_SA':
        return 'ENCONTRO DE CONTAS SACADO';
      case 'LIQBAIX':
        return 'LIQUIDACAO APOS BAIXA';
      case 'PG BCO':
        return 'LIQUIDACAO COBRANCA';
      case 'PG_TIT_CH':
        return 'PAGTO EM CHEQUE';
      case 'PG_TESOU':
        return 'PAGTO TESOURARIA';
      case 'PG TAR CED':
        return 'PGTO DE TARIFA P/CEDENTE';
      case 'PG TAR SAC':
        return 'PGTO DE TARIFA P/SACADO';
      case 'PGDES':
        return 'PGTO DESCONTO TITULO';
      case 'B_ATR':
        return 'PGTO DUPL.ATRASADA';
      case 'CARTORIO':
        return 'PGTO EM CARTORIO';
      case 'PAR':
        return 'PGTO PARCIAL';
      case '60':
        return 'RECOMPRA CHQ DEVOLVIDO';
      case '61':
        return 'RECOMPRA CHQ NAO DEPOSITADOS';
      case '62':
        return 'RECOMPRA DUPLICATA';
      case 'RETENCAO':
        return 'RETENÇÃO EM BORDERÔ';
      case 'DEB TARIFA':
        return 'TARIFA DEBITADA EM OPERAÇÃO';
      case 'DEBITO':
        return 'TITULO DEBITADO EM OPERAÇÃO';
      case 'APROP':
        return 'PGTO VIA APROPRIAÇÃO';
      case 'COBTERC':
        return 'BAIXA BORDERÔ TERCEIROS';
      case 'DEPO':
        return 'DEPÓSITOS';
      case 'DP_BRADCED':
        return 'RECOMPRA PELO CEDENTE';
      case 'JURBCO':
        return 'PGTO JUROS P/BCO';
      case 'NORMAL':
        return 'DEPOSITO EM CARTEIRA';
      case 'PG SAC':
        return 'PGTO DE TITULO EFETUADO P/SACADO';
      case 'PG TRAVA':
        return 'PGTO TRAVA BANCÁRIA';
      case 'PGBOR':
        return 'PGTO BORDERO TITULO';
      case 'PGBORTI':
        return 'PGTO BORDERO TARIFA';
      default:
        return '';
    }
  }

  return (
    <div id="titles_table_wrap">
      <div className="align-items-center d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <h4 style={{ margin: '0' }}>Títulos liquidados</h4>
          {totalValue > 0 && (
            <span
              style={{
                background: '#3757a1',
                padding: '4px 8px',
                marginLeft: '16px',
                borderRadius: '4px',
                color: '#fff',
                fontSize: '12px',
                letterSpacing: '0.025em',
              }}
            >
              Total: {normalizeBrl(totalValue)}
            </span>
          )}
        </div>
        <div>
          <Button
            color="default"
            variant="contained"
            className="mr-2"
            type="button"
            disabled={titles.length === 0}
            onClick={() => handlePrint()}
          >
            <FaPrint className="mr-2" />
            Imprimir
          </Button>
          <Button
            color="default"
            variant="contained"
            className="mr-2"
            type="button"
            disabled={titles.length === 0}
            onClick={() => handleCSV()}
          >
            <FaTable className="mr-2" />
            Exportar em Excel
          </Button>
          <Button
            color="primary"
            variant="contained"
            className="mr-2"
            type="button"
            disabled={loading}
            onClick={() => loadTitles()}
          >
            <FaSearch className="mr-2" />
            Filtrar
          </Button>
          {Object.entries(filters).filter(([k, v]) =>
            k === 'status' ? false : !!v
          ).length > 0 && (
            <Button
              color="default"
              variant="contained"
              type="button"
              onClick={() => {
                setFilters({
                  dataTituStart: '',
                  dataTituEnd: '',
                  dataPagoStart: '',
                  dataPagoEnd: '',
                  sacaId: '',
                  numeDoct: '',
                  valoTitu: '',
                  baixCadaDetaId: '',
                });
                loadTitles(true);
              }}
            >
              <FaTimes className="mr-2" />
              Limpar filtros
            </Button>
          )}
        </div>
      </div>
      <div className="content_component">
        <div className="filters">
          <Row className="mb-2">
            <Col sm={3} className="mb-2">
              <div className="input-group">
                <TextField
                  id="date"
                  type="date"
                  label="De (Data de vencimento)"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filters.dataTituStart}
                  onChange={(e) =>
                    setFilters({ ...filters, dataTituStart: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col sm={3} className="mb-2">
              <div className="input-group">
                <TextField
                  id="date"
                  label="Até (Data de vencimento)"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filters.dataTituEnd}
                  onChange={(e) =>
                    setFilters({ ...filters, dataTituEnd: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col sm={3} className="mb-2">
              <div className="input-group">
                <TextField
                  id="date"
                  type="date"
                  label="De (Data de liquidação)"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filters.dataPagoStart}
                  onChange={(e) =>
                    setFilters({ ...filters, dataPagoStart: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col sm={3} className="mb-2">
              <div className="input-group">
                <TextField
                  id="date"
                  label="Até (Data de liquidação)"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filters.dataPagoEnd}
                  onChange={(e) =>
                    setFilters({ ...filters, dataPagoEnd: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col sm={4} className="mb-2">
              <div className="input-group">
                <TextField
                  label="CPF/CNPJ Sacado"
                  value={filters.sacaId}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      sacaId: normalizeCpfCnpj(e.target.value),
                    })
                  }
                />
              </div>
            </Col>
            <Col sm={2} className="mb-2">
              <div className="input-group">
                <TextField
                  label="Documento"
                  value={filters.numeDoct}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      numeDoct: e.target.value,
                    })
                  }
                />
              </div>
            </Col>
            <Col sm={3} className="mb-2">
              <div className="input-group">
                <TextField
                  label="Valor"
                  value={filters.valoTitu}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      valoTitu:
                        Number(e.target.value.replace(/[^\d]/g, '')) === 0
                          ? ''
                          : normalizeCurrency(
                              Number(e.target.value.replace(/[^\d]/g, ''))
                            ),
                    })
                  }
                />
              </div>
            </Col>
            <Col sm={3} className="mb-2">
              <div className="input-group">
                <TextField
                  label="Tipo de Baixa"
                  select
                  value={filters.baixCadaDetaId}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      baixCadaDetaId: e.target.value,
                    })
                  }
                >
                  {paymentTypes.map((t) => (
                    <MenuItem key={t.code} value={t.code}>
                      {t.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Col>
          </Row>
        </div>
        {loading && <LinearProgress />}
        <Table responsive hover>
          <thead>
            <tr>
              <th>Título</th>
              <th>Nr. Documento</th>
              <th>Vencimento</th>
              <th>Sacado</th>
              <th>Pago</th>
              {/* <th>Encargos</th> */}
              <th>Tipo</th>
              <th>Liquidação</th>
              <th>Tipo de Baixa</th>
            </tr>
          </thead>
          <tbody>
            {titles.map((t, i) => (
              <tr key={`paid_titles_${i + 1}`}>
                <td>{t.titu_id}</td>
                <td>{t.nume_doct}</td>
                <td>
                  {format(
                    addMinutes(
                      new Date(t.data_titu),
                      new Date(t.data_titu).getTimezoneOffset()
                    ),
                    'dd/MM/yyyy'
                  )}
                </td>
                <td className="text-left">
                  {t.nome} - {normalizeCpfCnpj(t.saca_id)}
                </td>
                <td className="text-right">{normalizeBrl(t.valo_pago)}</td>
                <td>{t.tipo_titu}</td>
                <td>
                  {format(
                    addMinutes(
                      new Date(t.data_pago),
                      new Date(t.data_pago).getTimezoneOffset()
                    ),
                    'dd/MM/yyyy'
                  )}
                </td>
                <td>{nameTipoBaixa(t.baix_cada_deta_id)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="d-flex justify-content-between align-items-center p-2 flex-wrap footer_component">
          <div className="d-flex">
            <small>Total de Registros: {total}</small>
          </div>
          <div>
            Exibir:
            <button
              type="button"
              aria-controls="simple-menu"
              className="ml-4"
              aria-haspopup="true"
              style={{ color: '#444' }}
              onClick={handleClickMenu}
            >
              {perPage} <ExpandMore color="primary" />
            </button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setPage(1);
                  setPerPage(25);
                  setPage(1);
                  handleClose();
                }}
              >
                25
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setPage(1);
                  setPerPage(50);
                  setPage(1);
                  handleClose();
                }}
              >
                50
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setPage(1);
                  setPerPage(100);
                  setPage(1);
                  handleClose();
                }}
              >
                100
              </MenuItem>
            </Menu>
            <button
              type="button"
              disabled={page === 1 || loading}
              onClick={() => setPage(1)}
            >
              <FaAngleDoubleLeft style={{ fontSize: '12px' }} />
            </button>
            <button
              type="button"
              disabled={page === 1 || loading}
              onClick={() => setPage(page - 1)}
            >
              <ArrowBackIos style={{ fontSize: '12px' }} />
            </button>
            <span>
              {page} de {lastPage}
            </span>
            <button
              type="button"
              onClick={() => setPage(page + 1)}
              disabled={lastPage <= page || loading}
            >
              <ArrowForwardIos style={{ fontSize: '12px' }} />
            </button>
            <button
              type="button"
              disabled={page === lastPage || loading}
              onClick={() => setPage(lastPage)}
            >
              <FaAngleDoubleRight style={{ fontSize: '12px' }} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Open);
