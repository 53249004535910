import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useAuth } from '~/hooks/useAuth';

import AuthPage from '../pages/auth/AuthPage';
import MainPage from '../pages/main/MainPage';

const Routes: React.FC = () => {
  const { signed } = useAuth();

  return (
    <Switch>
      {!signed ? <AuthPage /> : <MainPage />}
      {!signed ? <Redirect to="/auth" /> : <Redirect to="/" />}
    </Switch>
  );
};

export default Routes;
