import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 15px 0px;
  border-radius: 15px;
  background: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  div.header_component {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 20px 25px;
    padding-bottom: 10px;
    h4 {
      margin: 0 0 15px;
    }
  }
  .content_component {
    .filters {
      padding: 10px 10px;
    }
  }
  table {
    thead {
      tr {
        background: rgb(55 87 161 / 20%);
        th {
          padding: 6px 7.5px;
          border: none;
          font-weight: 500;
          font-size: 12px;
          text-align: center;
          vertical-align: middle;
        }
        th:first-child {
          text-align: left;
          padding-left: 15px;
        }
        th:last-child {
          text-align: right;
          padding-right: 15px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 6px 7.5px;
          text-align: center;
          vertical-align: middle;
          font-size: 12px;
        }
        td:first-child {
          text-align: left;
          padding-left: 15px;
        }
        td:last-child {
          text-align: right;
          padding-right: 15px;
        }
      }
    }
  }
`;

export const NoEcnpjInstalled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  .message {
    h4 {
      color: #666;
      font-size: 28px;
      font-weight: bolder;
    }
    p {
      color: #333;
      margin-bottom: 26px;
    }
    a {
      padding: 10px 15px;
      border-radius: 4px;
      text-decoration: none;
      background: ${(props) => props.theme.colors.primary};
      color: #fff;
      font-weight: 800;
    }
  }
`;

export const ManifestContainer = styled.div`
  position: fixed;
  bottom: -380px;
  right: 65px;
  width: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  max-width: 550px;
  min-height: 380px;
  background: #fff;
  z-index: 100;
  color: ${(props) => props.theme.colors.primary};
  border-radius: 20px 20px 0px 0px;
  padding: 25px;
  transition: 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  &.opened {
    bottom: 0px;
  }
`;
