import React from 'react';

import { useHistory } from 'react-router-dom';
import { Container } from './styles';

import Open from './components/Open';
import Expired from './components/Expired';
import Settled from './components/Settled';

const Register: React.FC = ({ match }: any) => {
  const current = String(match.params.current);

  const history = useHistory();

  const handleNextItem = (actual: string) => {
    history.push(`/titles/${actual}`);
  };

  return (
    <Container>
      {current === 'a-vencer' && (
        <Open handleNextItem={() => handleNextItem('a-vencer')} />
      )}
      {current === 'vencidos' && (
        <Expired handleNextItem={() => handleNextItem('vencidos')} />
      )}
      {current === 'liquidados' && (
        <Settled handleNextItem={() => handleNextItem('liquidados')} />
      )}
    </Container>
  );
};

export default Register;
