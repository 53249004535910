import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { toast } from 'react-toastify';

import {
  Menu,
  MenuItem,
  LinearProgress,
  Checkbox,
  TextField,
  Button,
} from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos, ExpandMore } from '@material-ui/icons';
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaPrint,
  FaSearch,
  FaTimes,
} from 'react-icons/fa';
import { Table, Row, Col } from 'react-bootstrap';

import { format, addMinutes } from 'date-fns';
import { Container } from './styles';

import { normalizeBrl, normalizeCpfCnpj } from '../utils/normalize';

import { ITitle } from './ITitle';
import { useAuth } from '~/hooks/useAuth';

const Billet: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [billets, setBillets] = useState<ITitle[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [lastPage, setLastPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [total, setTotal] = useState(0);

  const [selected, setSelected] = useState<ITitle[]>([]);

  const [filters, setFilters] = useState({
    dataTituEnd: '',
    dataTituStart: '',
    sacaId: '',
    numeDoct: '',
    bordId: '',
    orderBy: 'expireDate',
  });

  const {
    currentCompany: { clieId: id },
    signOut,
  } = useAuth();

  async function loadBillets(refresh?: boolean) {
    const query: string[] = [];
    if (!refresh) {
      if (filters.dataTituStart) {
        query.push(`dataTituStart=${filters.dataTituStart}`);
      }
      if (filters.dataTituEnd) {
        query.push(`dataTituEnd=${filters.dataTituEnd}`);
      }
      if (filters.sacaId) {
        query.push(`sacaId=${filters.sacaId.replace(/[^\d]/g, '')}`);
      }
      if (filters.numeDoct) {
        query.push(`numeDoct=${filters.numeDoct}`);
      }
      if (filters.bordId) {
        query.push(`bordId=${filters.bordId}`);
      }
    }
    if (page) {
      query.push(`page=${page}`);
    }
    if (perPage) {
      query.push(`perpage=${perPage}`);
    }

    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_FACTA}/titles?situation=boleto&${query
          .map((i) => i)
          .join('&')}`
      );
      setBillets(data.data);
      setLastPage(data.meta.last_page);
      setTotal(data.meta.total);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        signOut();
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    loadBillets();
  }, [id, page, perPage, filters.orderBy]); // eslint-disable-line

  function handleSelect(item) {
    if (selected.find((i) => i.titu_id === item.titu_id)) {
      setSelected(selected.filter((i) => i.titu_id !== item.titu_id));
    } else {
      setSelected([...selected, item]);
    }
  }

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleDownload() {
    window.open(
      `${process.env.REACT_APP_API_FACTA}/boletos?titles=${selected
        .map((s) => s.titu_id)
        .join(',')}`
    );
  }

  return (
    <Container>
      <div id="billets_table_wrap">
        <div className="align-items-center d-flex justify-content-between">
          <div>
            <h4>Boletos</h4>
          </div>
          <div>
            <Button
              color="default"
              variant="contained"
              className="mr-2"
              type="button"
              onClick={handleDownload}
              disabled={selected.length === 0}
            >
              <FaPrint className="mr-2" />
              Imprimir
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="mr-2"
              type="button"
              disabled={loading}
              onClick={() => loadBillets()}
            >
              <FaSearch className="mr-2" />
              Filtrar
            </Button>
            {Object.entries(filters).filter(
              ([k, v], i) => !!v && k !== 'orderBy'
            ).length > 0 && (
              <Button
                color="default"
                variant="contained"
                type="button"
                onClick={() => {
                  setFilters({
                    dataTituEnd: '',
                    dataTituStart: '',
                    sacaId: '',
                    numeDoct: '',
                    bordId: '',
                    orderBy: 'expireDate',
                  });
                  loadBillets(true);
                }}
              >
                <FaTimes className="mr-2" />
                Limpar filtros
              </Button>
            )}
          </div>
        </div>
        <div className="content_component">
          <div className="filters">
            <Row className="mb-2">
              <Col sm={2}>
                <div className="input-group">
                  <TextField
                    type="text"
                    label="Bôrdero"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filters.bordId}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        bordId: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div className="input-group">
                  <TextField
                    type="date"
                    label="Vencimento de"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filters.dataTituStart}
                    onChange={(e) =>
                      setFilters({ ...filters, dataTituStart: e.target.value })
                    }
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div className="input-group">
                  <TextField
                    label="até"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filters.dataTituEnd}
                    onChange={(e) =>
                      setFilters({ ...filters, dataTituEnd: e.target.value })
                    }
                  />
                </div>
              </Col>
              <Col sm={2}>
                <div className="input-group">
                  <TextField
                    type="text"
                    label="CPF/CNPJ do Sacado"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filters.sacaId}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        sacaId: normalizeCpfCnpj(e.target.value),
                      })
                    }
                  />
                </div>
              </Col>
              <Col sm={2}>
                <div className="input-group">
                  <TextField
                    type="text"
                    label="Documento"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filters.numeDoct}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        numeDoct: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>
            </Row>
          </div>
          {loading && <LinearProgress />}
          <Table responsive hover>
            <thead>
              <tr>
                <th>Título</th>
                <th>Bôrdero</th>
                <th>Documento</th>
                <th>Vencimento</th>
                <th>Valor</th>
                <th>Sacado</th>
                <th>Nosso Número</th>
                <th>Obs</th>
                <th
                  onClick={() => {
                    if (selected.length === billets.length) {
                      setSelected([]);
                    } else {
                      setSelected(billets);
                    }
                  }}
                >
                  <Checkbox
                    checked={selected.length === billets.length}
                    color="primary"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {billets.length > 0 &&
                billets.map((billet, index) => {
                  const checked = selected.find(
                    (i) => i.titu_id === billet.titu_id
                  );
                  const { noss_nr } = billet;
                  if (noss_nr !== '') {
                    return (
                      <tr
                        key={String(index)}
                        onClick={() => handleSelect(billet)}
                        style={{ cursor: 'pointer' }}
                      >
                        <td>{billet.titu_id}</td>
                        <td>{billet.bord_id}</td>
                        <td>{billet.nume_doct}</td>
                        <td>
                          {format(
                            addMinutes(
                              new Date(billet.data_titu),
                              new Date(billet.data_titu).getTimezoneOffset()
                            ),
                            'dd/MM/yyyy'
                          )}
                        </td>
                        <td className="text-right">
                          {normalizeBrl(Number(billet.valo_titu))}
                        </td>
                        <td className="text-left">
                          {billet.nome}
                          <br />
                          {normalizeCpfCnpj(billet.saca_id)}
                        </td>
                        <td>{billet.noss_nr}</td>
                        <td />
                        <td>
                          <Checkbox checked={!!checked} color="primary" />
                        </td>
                      </tr>
                    );
                  }
                  return null;
                })}
            </tbody>
          </Table>
          <div className="d-flex justify-content-between align-items-center p-2 flex-wrap footer_component">
            <div className="d-flex">
              <small>Total de Registros: {total}</small>
            </div>
            <div>
              Exibir:
              <button
                type="button"
                aria-controls="simple-menu"
                className="ml-4"
                aria-haspopup="true"
                style={{ color: '#444' }}
                onClick={handleClickMenu}
              >
                {perPage} <ExpandMore color="primary" />
              </button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    setPerPage(25);
                    setPage(1);
                    handleClose();
                  }}
                >
                  25
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setPerPage(50);
                    setPage(1);
                    handleClose();
                  }}
                >
                  50
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setPerPage(100);
                    setPage(1);
                    handleClose();
                  }}
                >
                  100
                </MenuItem>
              </Menu>
              <button
                type="button"
                disabled={page === 1 || loading}
                onClick={() => setPage(1)}
              >
                <FaAngleDoubleLeft style={{ fontSize: '12px' }} />
              </button>
              <button
                type="button"
                disabled={page === 1 || loading}
                onClick={() => setPage(page - 1)}
              >
                <ArrowBackIos style={{ fontSize: '12px' }} />
              </button>
              <span>
                {page} de {lastPage}
              </span>
              <button
                type="button"
                onClick={() => setPage(page + 1)}
                disabled={lastPage <= page || loading}
              >
                <ArrowForwardIos style={{ fontSize: '12px' }} />
              </button>
              <button
                type="button"
                disabled={page === lastPage || loading}
                onClick={() => setPage(lastPage)}
              >
                <FaAngleDoubleRight style={{ fontSize: '12px' }} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Billet;
