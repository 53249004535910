import React from 'react';

import { Container } from './styles';

const PrivacyPolicy: React.FC = () => {
  return (
    <Container>
      <div className="row">
        <div className="col-md-12">
          <h4 className="mb-4 mt-3 text-center">Política de privacidade</h4>
          <p>
            <strong>I - Introdução</strong>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              O presente documento tem o objetivo de tornar pública de maneira
              simples, clara e objetiva a forma que a empresa
            </span>
            <strong> BFC CAPITAL PARTNERS LTDA</strong>
            <span style={{ fontWeight: 400 }}>
              , pessoa jurídica de direito privado, inscrita no CNPJ sob o
              número 01.410.785/0 001-33 com sede na Avenida Domin gos Ferreira,
              2589, sala 1001 - Bairro de Viagem, CEP: 51020-0 31 - Recife - PE
              promove o tratamento de dados pessoais.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Os termos aqui dispos tos foram elaborados de forma a cumprir
              fielmente com as disposições previstas na Lei Federal n º
              12.965/2014 (Marco Civil da Internet), na Lei Federal nº
              13.709/2018 (Lei Gera l de Proteção de Dados) e nas demais
              legislações aplicáveis, podendo ser alterados a qualquer tempo.  
            </span>
          </p>
          <p>
            <strong>II – Definições</strong>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Para os fins deste instrumento, os termos abaixo listados possue
              mas seguintes definições: 
            </span>
          </p>
          <ol>
            <li>
              <strong>a) GRUPO BFC</strong>
              <span style={{ fontWeight: 400 }}>
                : forma de denominação da empresa
              </span>
              <strong> BFC CAPITAL PARTNERS LTDA </strong>
              <span style={{ fontWeight: 400 }}>
                e demais empresas de seu grupo econômico. 
              </span>
            </li>
            <li>
              <strong>b) Website da Grupo BFC</strong>
              <span style={{ fontWeight: 400 }}>: É o endereço virtual da</span>
              <strong> BFC Capital P artners</strong>
              <span style={{ fontWeight: 400 }}>; </span>
              <strong>c) Plataforma da BFC Capital Partners</strong>
              <span style={{ fontWeight: 400 }}>
                : É a plataforma pra clientes da
              </span>
              <strong> BFC Capital Partners;</strong>
            </li>
            <li>
              <strong>d) Dados Pessoais: </strong>
              <span style={{ fontWeight: 400 }}>
                qualquer informação elacionada à pessoa natural identificada ou
                identificável. São considerados dados pessoais, por exemplo:
                nome, número de CPF, número de RG, fotografias, impressões
                digitais, endereços de e-mails, entre outros.
              </span>
            </li>
            <li>
              <strong>e) Dados Pessoais Sensíveis</strong>
              <span style={{ fontWeight: 400 }}>
                : informações de pessoa natural relacionadas a origem racial ou
                étnica, convicção religiosa, opinião política, filiação a
                sindicato ou a organização de caráter religioso, filosófico ou
                político, dado referente à saúde ou à vida sexual, dado genético
                ou biométrico.
              </span>
            </li>
            <li>
              <strong>f) Tratamento</strong>
              <span style={{ fontWeight: 400 }}>
                : é toda e qualquer operação realizada com dados pessoais como a
                coleta, o compartilhamento, o armazenamento e até a exclusão. 
              </span>
            </li>
            <li>
              <strong>g) Controlador</strong>
              <span style={{ fontWeight: 400 }}>
                : é a pessoa natural ou jurídica a quem competem as decisões
                referentes ao tratamento de dados pessoais. 
              </span>
            </li>
            <li>
              <strong>h) Operador: </strong>
              <span style={{ fontWeight: 400 }}>
                é a pessoa natural ou jurídica que realiza o tratamento de dados
                pessoais em nome do controlador.  
              </span>
            </li>
            <li>
              <strong>i) Titular</strong>
              <span style={{ fontWeight: 400 }}>
                : é a pessoa natural a quem se referem os dados pessoais que são
                objeto de tratamento. 
              </span>
            </li>
            <li>
              <strong>j) Usuário</strong>
              <span style={{ fontWeight: 400 }}>
                : termo utilizado para designar o titular dos dados pessoais,
                clientes e/ou pessoas que acessam o website da
              </span>
              <strong> BFC Capital Partners</strong>
              <span style={{ fontWeight: 400 }}>.  </span>
            </li>
            <li>
              <strong>k) Cookies</strong>
              <span style={{ fontWeight: 400 }}>
                : São arquivos armazenados no dispositivo do usuário para que as
                ações e preferências do mesmo sejam "lembradas" temporariamente,
                melhorando e facilitando a navegação e usabilidade do sistema.  
              </span>
            </li>
          </ol>
          <p>
            <strong>III – Como Coletamos Seus Dados </strong>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>A </span>
            <strong> BFC Capital Partners </strong>
            <span style={{ fontWeight: 400 }}>
              pode coletar os dados pessoais enviados diretamente e de forma
              voluntária pelos seus usuários e pelos representantes das pessoas
              jurídicas contratantes dos serviços ofertados, como por exemplo:
              nome, número de CPF, data de nascimento, endereço de
            </span>
            <em>
              <span style={{ fontWeight: 400 }}>e-mail</span>
            </em>
            <span style={{ fontWeight: 400 }}>
              , estado civil, informações constantes na declaração de imposto de
              renda, profissão, endereço de correspondência, entre outros.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Tais dados podem ser coletados quando o usuário cria sua conta no
              website da
            </span>
            <strong> BFC Capital Partners</strong>
            <span style={{ fontWeight: 400 }}>
              , quando o usuário decide contratar os serviços da
            </span>
            <strong> BFC Capital Partners, </strong>
            <span style={{ fontWeight: 400 }}>
              quando o usuário faz alterações em seu perfil, quando o usuário
              entra em contato com a
            </span>
            <strong> BFC Capital Partners</strong>
            <span style={{ fontWeight: 400 }}>, entre outras situações. </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Ainda, ao utilizar a Plataforma da
            </span>
            <strong> BFC Capital Partners</strong>
            <span style={{ fontWeight: 400 }}>
              , poderão ser coletados outros dados pessoais do usuário, como por
              exemplo informações financeiras e patrimoniais.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>Ainda poderá a </span>
            <strong> BFC Capital Partners </strong>
            <span style={{ fontWeight: 400 }}>
              de forma automática coletar dados pessoais dos titulares em
              navegação pelo seu
            </span>
            <em>
              <span style={{ fontWeight: 400 }}>website</span>
            </em>
            <span style={{ fontWeight: 400 }}>
              , como por exemplo: IP com data e hora da conexão, sistema
              operacional utilizado, geolocalização, entre outros. Também poderá
              coletar dados pessoais que  
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              estejam em posse de terceiros, como instituições bancárias ou
              órgãos de proteção ao crédito. 
            </span>
          </p>
          <p>
            <strong>IV – Para quais Fins Utilizamos Seus Dados </strong>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>A </span>
            <strong> BFC Capital Partners </strong>
            <span style={{ fontWeight: 400 }}>
              promoverá o tratamento dos dados pessoais dos usuários,
              respeitando as disposições previstas na Lei Geral de Proteção de
              Dados (Lei 13.709/2018), garantindo inclusive que as atividades de
              tratamento estarão sempre ancoradas nas bases legais previstas na
              referida legislação.  
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              De forma exemplificada, mas não limitada, a
            </span>
            <strong> BFC Capital Partners </strong>
            <span style={{ fontWeight: 400 }}>
              poderá promover o tratamento de dados pessoais para as seguintes
              finalidades:  
            </span>
          </p>
          <ul>
            <li>
              <span style={{ fontWeight: 400 }}>
                Sanar dúvidas e questionamentos realizados por usuários através
                do 
              </span>
              <em>
                <span style={{ fontWeight: 400 }}>website </span>
              </em>
              <span style={{ fontWeight: 400 }}>ou da plataforma da </span>
              <strong> BFC Capital Partners </strong>
              <span style={{ fontWeight: 400 }}>
                ou por qualquer outro meio;  
              </span>
            </li>
            <li>
              <span style={{ fontWeight: 400 }}>
                Execução dos serviços ofertados pela
              </span>
              <strong> BFC Capital Partners; </strong>
              <span style={{ fontWeight: 400 }}>
                • Melhoria e aprimoramento na usabilidade da Plataforma e na
                navegação no website da
              </span>
              <strong> BFC Capital Partners</strong>
              <span style={{ fontWeight: 400 }}>
                , promovendo ao usuário mais efetividade, eficiência e
                satisfação;  
              </span>
            </li>
            <li>
              <span style={{ fontWeight: 400 }}>
                Prestação de suporte técnico e melhoras nas medidas de segurança
                que são ou que poderão ser implantadas pela
              </span>
              <strong> BFC Capital Partners</strong>
              <span style={{ fontWeight: 400 }}>
                ; • Comunicação com o usuário, que poderá ser feita através de 
                notificações, SMS, alertas, avisos, ligações telefônicas,
              </span>
              <em>
                <span style={{ fontWeight: 400 }}>e-mails </span>
              </em>
              <span style={{ fontWeight: 400 }}>
                ou qualquer outra forma válida;  
              </span>
            </li>
            <li>
              <span style={{ fontWeight: 400 }}>
                Envio de publicidade, que poderá ser feita de forma direcionada;
                • Promover a combinação das finalidades aqui previstas;  
              </span>
            </li>
          </ul>
          <p>
            <strong>V</strong>
            <strong> – Com Quem Compartilhamos os Seus Dados</strong>
            <strong> </strong>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>A </span>
            <strong> BFC Capital Partners </strong>
            <span style={{ fontWeight: 400 }}>
              não promove a comercialização dos dados pessoais de seus
              usuários.  
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Em alguns casos específicos e sempre respeitando os termos da Lei
              Geral de Proteção de Dados, a
            </span>
            <strong> BFC Capital Partners </strong>
            <span style={{ fontWeight: 400 }}>
              poderá, quando figurar na posição de controladora de dados
              pessoais, promover o compartilhamento dos dados com terceiros,
              como por exemplo nas seguintes hipóteses: 
            </span>
          </p>
          <ul>
            <li>
              <span style={{ fontWeight: 400 }}>
                Compartilhamento com prestadores de serviços para o tratamento
                dos dados em nome da
              </span>
              <strong> BFC Capital Partners</strong>
              <span style={{ fontWeight: 400 }}>
                , como por exemplo, serviços de armazenamento em nuvem, serviços
                analíticos, serviços de
              </span>
              <em>
                <span style={{ fontWeight: 400 }}>marketing</span>
              </em>
              <span style={{ fontWeight: 400 }}>
                , serviços de automação de
              </span>
              <em>
                <span style={{ fontWeight: 400 }}>e-mails</span>
              </em>
              <span style={{ fontWeight: 400 }}>
                , serviços de processamento financeiro, serviços de
                correspondentes bancários, entre outros;
              </span>
            </li>
            <li>
              <span style={{ fontWeight: 400 }}>
                Compartilhamento com terceiros quando exigido pela legislação 
                aplicável;  
              </span>
            </li>
            <li>
              <span style={{ fontWeight: 400 }}>
                Compartilhamento com terceiros quando exigido pela Autoridade
                Nacional de Proteção de Dados – ANPD;  
              </span>
            </li>
            <li>
              <span style={{ fontWeight: 400 }}>
                Compartilhamento com terceiros quando exigido por determinação 
                judicial; 
              </span>
            </li>
            <li>
              <span style={{ fontWeight: 400 }}>
                Compartilhamento com terceiros por determinação de autoridades 
                públicas e/ou governamentais; 
              </span>
            </li>
            <li>
              <span style={{ fontWeight: 400 }}>
                Compartilhamento com terceiros para o cumprimento de obrigações 
                legais; 
              </span>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>A </span>
            <strong> BFC Capital Partners</strong>
            <span style={{ fontWeight: 400 }}>
              , sempre que possível, firmará instrumento contratual com os
              terceiros destinatários de dados pessoais, garantindo que tais
              terceiros adotarão elevado padrão de cuidado e respeito com os 
              dados pessoais transferidos.  
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>É facultado à </span>
            <strong> BFC Capital Partners </strong>
            <span style={{ fontWeight: 400 }}>
              promover a transferência/compartilhamento internacional de dados,
              garantindo-se que o país de destino possua legislação adequada de
              proteção de dados e/ou que o terceiro destinatário adote elevado
              padrão de segurança e respeito com a privacidade.  
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Dados pessoais sensíveis eventualmente tratados não serão
              compartilhados com terceiros, salvo nas hipóteses previstas na Lei
              Geral de Proteção de Dados. 
            </span>
          </p>
          <p>
            <strong>V</strong>
            <strong>I - Cuidados com a Segurança da Informação</strong>
            <strong> </strong>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Comprometida com a segurança dos dados pessoais dos usuários, a
            </span>
            <strong> BFC Capital Partners </strong>
            <span style={{ fontWeight: 400 }}>
              buscará se utilizar de meios técnicos razoáveis que estejam a seu
              alcance para evitar a ocorrência de incidentes indesejados, como
              por exemplo o acesso indevido de terceiros, o vazamento de 
              informações, a perda de dados, entre outros. 
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Para a garantia da segurança da informação sob tratamento, serão
              adotadas soluções que levem em consideração as técnicas adequadas,
              os custos de aplicação, a natureza, o âmbito, o contexto e as
              finalidades do tratamento e os riscos para os direitos e
              liberdades do usuário.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              São exemplos de medidas de segurança adotadas pela
            </span>
            <strong> BFC Capital Partners</strong>
            <span style={{ fontWeight: 400 }}>
              : uso de protocolo HTTPS, uso de criptografia de ponta a ponta,
              uso de 
            </span>
            <em>
              <span style={{ fontWeight: 400 }}>firewalls</span>
            </em>
            <span style={{ fontWeight: 400 }}>
              , servidores sem acesso externo à internet, entre outras. 
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Tais medidas serão adotadas pela
            </span>
            <strong> BFC Capital Partners </strong>
            <span style={{ fontWeight: 400 }}>
              quando a mesma estiver promovendo o tratamento de dados pessoais,
              seja na condição de controladora ou de operadora.  
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>Assim, considerando que a </span>
            <strong> BFC Capital Partners </strong>
            <span style={{ fontWeight: 400 }}>
              adota as técnicas de segurança necessárias, a mesma não se
              responsabilizará por eventuais incidentes de segurança da
              informação causados pelo próprio usuário e/ou por terceiros que
              possam provocar, de modo acidental ou ilícito, a destruição, a
              perda, a alteração, a divulgação ou o acesso não autorizado aos
              dados pessoais sob tratamento. 
            </span>
          </p>
          <p>
            <strong>V</strong>
            <strong>II – Seus Direitos</strong>
            <strong> </strong>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>Quando a </span>
            <strong> BFC Capital Partners </strong>
            <span style={{ fontWeight: 400 }}>
              figurar na condição de controladora dos dados pessoais, o usuário
              poderá enviar solicitação com a finalidade de exercer quaisquer
              dos direitos em seguida listados:  
            </span>
          </p>
          <ul>
            <li>
              <span style={{ fontWeight: 400 }}>
                Confirmação de existência de tratamento dos seus dados pessoais;
                • Acesso aos dados pessoais sob tratamento; 
              </span>
            </li>
            <li>
              <span style={{ fontWeight: 400 }}>
                Correção de dados que, por qualquer motivo, estejam incompletos,
                inexatos ou desatualizados;  
              </span>
            </li>
            <li>
              <span style={{ fontWeight: 400 }}>
                Requisição de eliminação de dados desnecessários, excessivos ou 
                tratados em desconformidade com o anonimização, bloqueio ou
                disposto na Lei 13.709/2018;  
              </span>
            </li>
            <li>
              <span style={{ fontWeight: 400 }}>
                Requisição de eliminação dos dados pessoais;  
              </span>
            </li>
            <li>
              <span style={{ fontWeight: 400 }}>
                Obtenção de informações relacionadas às entidades públicas ou
                privadas com as quais a
              </span>
              <strong> BFC Capital Partners </strong>
              <span style={{ fontWeight: 400 }}>
                tenha, eventualmente, compartilhado seus dados pessoais; 
              </span>
            </li>
            <li>
              <span style={{ fontWeight: 400 }}>
                Revogação do consentimento; 
              </span>
            </li>
            <li>
              <span style={{ fontWeight: 400 }}>
                Outras hipóteses previstas na Lei 13.709/2018. 
              </span>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>
              Tais solicitações deverão ser encaminhadas através do endereço 
              eletrônico dpo@grupobfc.com.br, sendo a empresa
            </span>
            <strong> BFC SOLUÇÕES FINANCEIRAS LTDA</strong>
            <span style={{ fontWeight: 400 }}>
              . pessoa jurídica de direito privado, inscrita no CNPJ sob o
              número 01.955.337/0001-15, pessoa jurídica competente para tratar
              sobre tais solicitações.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              No referido e-mail, o usuário deverá informar e/ou apresentar: (i)
              seu nome completo; (ii) número do CPF, (iii) e-mail cadastrado;
              (iv) direito que deseja exercer; (v) todo e qualquer documento ou
              argumento que possa demonstrar ou justificar o exercício de seu
              direito.  
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>Quando a </span>
            <strong> BFC Capital Partners </strong>
            <span style={{ fontWeight: 400 }}>
              promover o tratamento de dados pessoais na posição de operadora,
              deverá o titular, caso queira, buscar o controlador para exercer
              eventuais direitos previstos na Lei 13.709/2018 ou em qualquer
              outra legislação cujos termos sejam aplicáveis.  
            </span>
          </p>
          <p>
            <strong>V</strong>
            <strong>III - Do Uso de Cookies</strong>
            <strong> </strong>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>A </span>
            <strong> BFC Capital Partners </strong>
            <span style={{ fontWeight: 400 }}>poderá se utilizar de </span>
            <em>
              <span style={{ fontWeight: 400 }}>cookies </span>
            </em>
            <span style={{ fontWeight: 400 }}>
              para aperfeiçoar a navegação e melhorar a usabilidade do usuário
              no
            </span>
            <em>
              <span style={{ fontWeight: 400 }}>website, </span>
            </em>
            <span style={{ fontWeight: 400 }}>no </span>
            <em>
              <span style={{ fontWeight: 400 }}>App </span>
            </em>
            <span style={{ fontWeight: 400 }}>
              ou na plataforma, que poderá, caso queira, se opor ou limitar o
              registro de cookies. Nesta hipótese, sua experiência de navegação
              poderá ser afetada e o funcionamento regular do website da
            </span>
            <strong> BFC Capital Partners </strong>
            <span style={{ fontWeight: 400 }}>poderá sofrer alterações. </span>
          </p>
          <p>
            <strong>IX – Alterações </strong>
            <strong> </strong>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              A atual versão da presente Política de Privacidade foi atualizada
              em
            </span>
            <span style={{ fontWeight: 400 }}>[x]</span>
            <span style={{ fontWeight: 400 }}> de </span>
            <span style={{ fontWeight: 400 }}>[x] </span>
            <span style={{ fontWeight: 400 }}>de 2021. </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>A </span>
            <strong> BFC Capital Partners </strong>
            <span style={{ fontWeight: 400 }}>
              se reserva ao direito de modificar a qualquer tempo e sem a
              necessidade de aviso prévio os termos do presente instrumento.
              Tais modificações poderão ser realizadas sempre que houver:  
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              (i) modificações legais; (ii) alterações nas ferramentas da
            </span>
            <strong> BFC Capital Partners</strong>
            <span style={{ fontWeight: 400 }}>
              ; (iii) oferta de novas funcionalidades; e/ou (iv) por 
              conveniência da
            </span>
            <strong> BFC Capital Partners</strong>
            <span style={{ fontWeight: 400 }}>.  </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>Assim sendo, a </span>
            <strong> BFC Capital Partners </strong>
            <span style={{ fontWeight: 400 }}>
              incentiva o usuário a consultar periodicamente o endereço virtual
              onde se encontra disponível a presente Política de Privacidade. 
            </span>
          </p>
          <p>
            <strong>X – Disposições Finais</strong>
            <strong> </strong>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Os usuários que utilizam a plataforma da
            </span>
            <strong> BFC Capital Partners</strong>
            <span style={{ fontWeight: 400 }}>, o seu </span>
            <em>
              <span style={{ fontWeight: 400 }}>App</span>
            </em>
            <span style={{ fontWeight: 400 }}>
              , ou mesmo que navegarem pelo seu
            </span>
            <em>
              <span style={{ fontWeight: 400 }}>website </span>
            </em>
            <span style={{ fontWeight: 400 }}>
              concordam com os termos presentes neste Política de Privacidade,
              de forma que, caso alguma 
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              disposição específica deste instrumento venha a ser
              considerada inválida, as demais permanecerão vigentes e
              eficazes.  
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Esta Política de Privacidade será regida e interpretada de acordo
              com as Leis Brasileiras, especialmente de acordo com a Lei Federal
              nº 12.965/2014 (Marco Civil da Internet), com a Lei Federal nº
              13.709/2018 (Lei Geral de Proteção de Dados), sendo competente
              para dirimir eventuais dúvidas decorrentes deste documento o foro
              da Comarca de Recife/PE.
            </span>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
