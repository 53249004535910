import React, { memo, useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import {
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  FormGroup,
  Avatar,
  LinearProgress,
} from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';
import { PlusOneRounded, Close } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import companyValidation from './utils/validation';
import StateSelect from '../Member/components/StateSelect';

import consultCep from '../../../../../services/consultCep';

import {
  editActive,
  editUnActive,
} from '../../../../../store/modules/register/actions';
import { signOut } from '../../../../../store/modules/auth/actions';
import {
  normalizeCnpj,
  normalizeTelephone,
  normalizeNumber,
  normalizeCep,
} from '../../utils';

import { Container } from './styles';
import { useAuth } from '~/hooks/useAuth';

interface ICompanyAddress {
  logradouro: string;
  numero: string;
  complemento: string;
  cep: string;
  estado: string;
  cidade: string;
  bairro: string;
}
interface ICompanyError {
  companyName: string;
  fantasyName: string;
  inscState: string;
  stateRegistration: string;
  address: ICompanyAddress;
  addressOpt: ICompanyAddress;
  anotherCompanies: string;
  financialInstitutions: string;
  numberOfColabs: string;
  website: string;
  telephones: number[];
}

interface INextItem {
  (): void;
}

interface IProps {
  handleNextItem: INextItem;
}

const Geral: React.FC<IProps> = ({ handleNextItem }) => {
  const [loading] = useState(false);
  const [editing, setEditing] = useState(false);
  const {
    currentCompany: { cnpj, clieId: id, name: cName },
  } = useAuth();

  const [status] = useState<
    | 'Incompleto'
    | 'Em análise'
    | 'Aguardando geração do contrato'
    | 'Aguardando assinatura do contrato'
    | 'Em operação'
  >('Em operação');

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const history = useHistory();

  const [companyName, setCompanyName] = useState('');
  const [fantasyName, setFantasyName] = useState('');
  const [telephones, setTelephones] = useState(['']);
  const [inscState, setInscState] = useState('isent');
  const [stateRegistration, setStateRegistration] = useState('');
  const [diferentAddress, setDiferentAddress] = useState(false);
  const [address, setAddress] = useState({
    logradouro: '',
    numero: '',
    complemento: '',
    cep: '',
    estado: '',
    cidade: '',
    bairro: '',
  });
  const [addressOpt, setAddressOpt] = useState({
    logradouro: '',
    numero: '',
    complemento: '',
    cep: '',
    estado: '',
    cidade: '',
    bairro: '',
  });
  const [numberOfColabs, setNumberOfColabs] = useState('');
  const [website, setWebsite] = useState('');
  const [anotherCompanies, setAnotherCompanies] = useState('0');
  const [financialInstitutions, setFinancialInstitutions] = useState('');

  const [errorsCompany, setErrorsCompany] = useState<ICompanyError>({
    companyName: '',
    fantasyName: '',
    inscState: '',
    stateRegistration: '',
    anotherCompanies: '',
    financialInstitutions: '',
    address: {
      logradouro: '',
      numero: '',
      complemento: '',
      cep: '',
      estado: '',
      cidade: '',
      bairro: '',
    },
    addressOpt: {
      logradouro: '',
      numero: '',
      complemento: '',
      cep: '',
      estado: '',
      cidade: '',
      bairro: '',
    },
    telephones: [],
    numberOfColabs: '',
    website: '',
  });

  const dispatch = useDispatch();

  const startEditing = () => {
    if (!editing) {
      setEditing(true);
      dispatch(editActive());
    }
  };

  useEffect(() => {
    async function loadGeralInfo() {
      // setLoading(true);
      // try {
      //   const { data } = await axios.get(`/companies/status/${id}`);
      //   setStatus(data.status);
      // } catch (err) {}
      // try {
      //   const { data } = await axios.get(`/companies/company`);
      //   setCompanyName(data.companyName);
      //   setFantasyName(data.fantasyName);
      //   setStateRegistration(data.stateRegistration);
      //   setInscState(
      //     data.stateRegistration === '' || data.stateRegistration === null
      //       ? 'isent'
      //       : 'required'
      //   );
      //   setNumberOfColabs(
      //     data.numberOfCollaborators ? String(data.numberOfCollaborators) : ''
      //   );
      //   const phonesFormated: string[] = [];
      //   data.telephones &&
      //     data.telephones.forEach((t: any) => {
      //       phonesFormated.push(normalizeTelephone(t.replace(/[^\d]/g, '')));
      //     });
      //   setFinancialInstitutions(data.financialInstitutions);
      //   setAnotherCompanies(
      //     data.financialInstitutions === '' ||
      //       data.financialInstitutions === null
      //       ? '0'
      //       : '1'
      //   );
      //   setTelephones(phonesFormated.length > 0 ? phonesFormated : ['']);
      //   setWebsite(data.website);
      //   setAddress({
      //     bairro: data.address.district,
      //     cep:
      //       data.address.cep &&
      //       normalizeCep(data.address.cep.replace(/[^\d]/g, '')),
      //     cidade: data.address.city,
      //     complemento: data.address.complement,
      //     estado: data.address.state,
      //     logradouro: data.address.street,
      //     numero: data.address.number,
      //   });
      //   setDiferentAddress(data.diferentAddress);
      //   if (data.diferentAddress) {
      //     setAddressOpt({
      //       bairro: data.billingAddress.district,
      //       cep: data.billingAddress.cep,
      //       cidade: data.billingAddress.city,
      //       complemento: data.billingAddress.complement,
      //       estado: data.billingAddress.state,
      //       logradouro: data.billingAddress.street,
      //       numero: data.billingAddress.number,
      //     });
      //   }
      // } catch (err) {
      //   if (err.response && err.response.status === 401) {
      //     dispatch(signOut());
      //     toast.error('Sua sessão expirou, entre novamente');
      //   } else if (err.response && err.response.status === 403) {
      //     toast.error('Você não está autorizado a acessar este recurso');
      //   } else if (err.response && err.response.status === 500) {
      //     toast.error(
      //       'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
      //     );
      //   } else {
      //     toast.error('Ocorreu um erro, tente novamente mais tarde');
      //   }
      // }
      // setLoading(false);
    }
    loadGeralInfo();
  }, [id]); // eslint-disable-line

  const handleSubmit = async () => {
    setErrorsCompany({
      companyName: '',
      fantasyName: '',
      inscState: '',
      stateRegistration: '',
      anotherCompanies: '',
      financialInstitutions: '',
      address: {
        logradouro: '',
        numero: '',
        complemento: '',
        cep: '',
        estado: '',
        cidade: '',
        bairro: '',
      },
      addressOpt: {
        logradouro: '',
        numero: '',
        complemento: '',
        cep: '',
        estado: '',
        cidade: '',
        bairro: '',
      },
      telephones: [],
      numberOfColabs: '',
      website: '',
    });
    const { count_errors, errors } = companyValidation({
      companyName,
      fantasyName,
      inscState,
      stateRegistration,
      address: {
        logradouro: address.logradouro,
        numero: address.numero,
        complemento: address.complemento,
        cep: address.cep,
        estado: address.estado,
        cidade: address.cidade,
        bairro: address.bairro,
      },
      anotherCompanies,
      financialInstitutions,
      diferentAddress,
      addressOpt: {
        logradouro: addressOpt.logradouro,
        numero: addressOpt.numero,
        complemento: addressOpt.complemento,
        cep: addressOpt.cep,
        estado: addressOpt.estado,
        cidade: addressOpt.cidade,
        bairro: addressOpt.bairro,
      },
      numberOfColabs,
      website,
      telephones,
    });
    if (count_errors > 0) {
      return setErrorsCompany(errors);
    }

    try {
      await axios.put(`/companies`, {
        companyName,
        fantasyName,
        stateRegistration: inscState === 'required' ? stateRegistration : '',
        numberOfCollaborators: Number(numberOfColabs),
        telephones,
        financialInstitutions:
          anotherCompanies === '1' ? financialInstitutions : '',
        website,
        address: {
          street: address.logradouro,
          number: address.numero,
          complement: address.complemento,
          cep: address.cep,
          state: address.estado,
          city: address.cidade,
          district: address.bairro,
        },
        diferentAddress: !!diferentAddress,
        billingAddress: diferentAddress
          ? {
              street: addressOpt.logradouro,
              number: addressOpt.numero,
              complement: addressOpt.complemento,
              cep: addressOpt.cep,
              state: addressOpt.estado,
              city: addressOpt.cidade,
              district: addressOpt.bairro,
            }
          : {
              street: address.logradouro,
              number: address.numero,
              complement: address.complemento,
              cep: address.cep,
              state: address.estado,
              city: address.cidade,
              district: address.bairro,
            },
      });
      toast.info('Informações Salvas');
      setEditing(false);
      dispatch(editUnActive());
      if (urlParams.get('pendency') === '1') {
        history.push(
          `/cadastro/documentos?pendency=1&doc=${urlParams.get(
            'doc'
          )}&type=company&id=${urlParams.get('id')}`
        );
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(signOut());
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
  };

  const inicials = cName ? cName[0] + cName[1] : cnpj[0] + cnpj[1];

  async function handleCepAddress() {
    const consult = await consultCep(address.cep);
    if (consult) {
      setAddress({
        ...address,
        ...consult,
      });
    }
  }
  async function handleCepAddressOpt() {
    const consult = await consultCep(addressOpt.cep);
    if (consult) {
      setAddressOpt({
        ...addressOpt,
        ...consult,
      });
    }
  }

  return (
    <Container>
      <div className="header_component d-flex">
        <Avatar
          variant="circle"
          style={{ color: '#3757a1', background: '#f7f7f7' }}
        >
          {inicials}
        </Avatar>
        <div className="ml-4">
          <h4>{cName}</h4>
          <p>{normalizeCnpj(cnpj)}</p>
        </div>
      </div>
      <div className="content_component">
        {loading && <LinearProgress />}
        <form>
          <fieldset>
            <h4>Dados Cadastrais</h4>
            <div className="row">
              <div className="col-md-3">
                <TextField
                  label="Razão social"
                  value={companyName}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                    startEditing();
                    if (errorsCompany.companyName !== '') {
                      setErrorsCompany({
                        ...errorsCompany,
                        companyName: '',
                      });
                    }
                  }}
                  error={errorsCompany.companyName !== ''}
                  helperText={errorsCompany.companyName}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                  required
                />
              </div>
              <div className="col-md-3">
                <TextField
                  label="Nome fantasia"
                  value={fantasyName}
                  onChange={(e) => {
                    startEditing();
                    setFantasyName(e.target.value);
                    if (errorsCompany.fantasyName !== '') {
                      setErrorsCompany({
                        ...errorsCompany,
                        fantasyName: '',
                      });
                    }
                  }}
                  error={errorsCompany.fantasyName !== ''}
                  helperText={errorsCompany.fantasyName}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                  required
                />
              </div>
              <div className="col-md-3">
                <div>
                  {telephones &&
                    telephones.length > 0 &&
                    telephones.map((item, index) => {
                      const error_item = errorsCompany.telephones.find(
                        (p, ind) => p === index
                      );
                      return (
                        <div className="d-flex" key={index}>
                          <TextField
                            label="Telefone"
                            value={item}
                            error={error_item !== undefined}
                            onChange={(e) => {
                              startEditing();
                              const telephones_lock = [...telephones];
                              telephones_lock[index] = normalizeTelephone(
                                e.target.value
                              );
                              setTelephones(telephones_lock);
                            }}
                            className="mb-2"
                            disabled={
                              loading ||
                              (status !== 'Incompleto' &&
                                status !== 'Em análise')
                            }
                            required
                          />
                          {index > 0 && (
                            <button
                              type="button"
                              className="btn btn-sm"
                              onClick={() =>
                                setTelephones(
                                  telephones.filter((_, ind) => ind !== index)
                                )
                              }
                              disabled={
                                loading ||
                                (status !== 'Incompleto' &&
                                  status !== 'Em análise')
                              }
                            >
                              <Close />
                            </button>
                          )}
                        </div>
                      );
                    })}
                </div>
                <button
                  type="button"
                  className="btn"
                  onClick={() => setTelephones([...telephones, ''])}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                >
                  <PlusOneRounded />
                </button>
              </div>
              <div className="col-md-3">
                <FormLabel component="legend">Inscrição Estadual</FormLabel>
                <RadioGroup
                  value={inscState}
                  onChange={(e) => {
                    setInscState(e.target.value);
                    startEditing();
                  }}
                >
                  <div className="d-flex">
                    <FormControlLabel
                      value="isent"
                      control={
                        <Radio
                          style={{ color: '#3757a1' }}
                          disabled={
                            loading ||
                            (status !== 'Incompleto' && status !== 'Em análise')
                          }
                        />
                      }
                      label="Isento"
                    />
                    <FormControlLabel
                      value="required"
                      control={
                        <Radio
                          style={{ color: '#3757a1' }}
                          disabled={
                            loading ||
                            (status !== 'Incompleto' && status !== 'Em análise')
                          }
                        />
                      }
                      label="Obrigatório"
                    />
                  </div>
                </RadioGroup>

                {inscState === 'required' && (
                  <TextField
                    label="Obrigatório"
                    className="d-block"
                    value={stateRegistration}
                    onChange={(e) => {
                      startEditing();
                      setStateRegistration(normalizeNumber(e.target.value));
                      if (errorsCompany.stateRegistration !== '') {
                        setErrorsCompany({
                          ...errorsCompany,
                          stateRegistration: '',
                        });
                      }
                    }}
                    disabled={
                      loading ||
                      (status !== 'Incompleto' && status !== 'Em análise')
                    }
                    error={errorsCompany.stateRegistration !== ''}
                    helperText={errorsCompany.stateRegistration}
                    required
                  />
                )}
              </div>
            </div>
          </fieldset>
          <fieldset>
            <h4>Endereço de Cadastro</h4>
            <div className="row">
              <div className="col-md-3 mt-2">
                <TextField
                  label="CEP"
                  value={address.cep}
                  required
                  error={errorsCompany.address.cep !== ''}
                  helperText={errorsCompany.address.cep}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                  onChange={(e) => {
                    startEditing();
                    setAddress({
                      ...address,
                      cep: normalizeCep(e.target.value),
                    });
                    if (errorsCompany.address.cep !== '') {
                      setErrorsCompany({
                        ...errorsCompany,
                        address: {
                          ...address,
                          cep: '',
                        },
                      });
                    }
                  }}
                  onBlur={handleCepAddress}
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Logradouro"
                  value={address.logradouro}
                  error={errorsCompany.address.logradouro !== ''}
                  helperText={errorsCompany.address.logradouro}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                  required
                  onChange={(e) => {
                    startEditing();
                    setAddress({ ...address, logradouro: e.target.value });
                    if (errorsCompany.address.logradouro !== '') {
                      setErrorsCompany({
                        ...errorsCompany,
                        address: {
                          ...address,
                          logradouro: '',
                        },
                      });
                    }
                  }}
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Número"
                  value={address.numero}
                  error={errorsCompany.address.numero !== ''}
                  helperText={errorsCompany.address.numero}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                  required
                  onChange={(e) => {
                    startEditing();
                    setAddress({ ...address, numero: e.target.value });
                    if (errorsCompany.address.numero !== '') {
                      setErrorsCompany({
                        ...errorsCompany,
                        address: {
                          ...address,
                          numero: '',
                        },
                      });
                    }
                  }}
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Complemento"
                  value={address.complemento}
                  onChange={(e) => {
                    startEditing();
                    setAddress({ ...address, complemento: e.target.value });
                  }}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Bairro"
                  value={address.bairro}
                  error={errorsCompany.address.bairro !== ''}
                  helperText={errorsCompany.address.bairro}
                  required
                  onChange={(e) => {
                    startEditing();
                    setAddress({ ...address, bairro: e.target.value });
                    if (errorsCompany.address.bairro !== '') {
                      setErrorsCompany({
                        ...errorsCompany,
                        address: {
                          ...address,
                          bairro: '',
                        },
                      });
                    }
                  }}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Cidade"
                  value={address.cidade}
                  error={errorsCompany.address.cidade !== ''}
                  helperText={errorsCompany.address.cidade}
                  required
                  onChange={(e) => {
                    startEditing();
                    setAddress({ ...address, cidade: e.target.value });
                    if (errorsCompany.address.cidade !== '') {
                      setErrorsCompany({
                        ...errorsCompany,
                        address: {
                          ...address,
                          cidade: '',
                        },
                      });
                    }
                  }}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <StateSelect
                  label="Estado"
                  value={address.estado}
                  error={errorsCompany.address.estado !== ''}
                  helperText={errorsCompany.address.estado}
                  required
                  onChange={(e: any) => {
                    startEditing();
                    setAddress({ ...address, estado: e.target.value });
                    if (errorsCompany.address.estado !== '') {
                      setErrorsCompany({
                        ...errorsCompany,
                        address: {
                          ...address,
                          estado: '',
                        },
                      });
                    }
                  }}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                />
              </div>
            </div>
          </fieldset>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: '#3757a1' }}
                    checked={diferentAddress}
                    onChange={(e) => {
                      startEditing();
                      setDiferentAddress(Boolean(e.target.checked));
                      setAddressOpt({
                        logradouro: '',
                        numero: '',
                        complemento: '',
                        cep: '',
                        estado: '',
                        cidade: '',
                        bairro: '',
                      });
                      setErrorsCompany({
                        ...errorsCompany,
                        addressOpt: {
                          logradouro: '',
                          numero: '',
                          complemento: '',
                          cep: '',
                          estado: '',
                          cidade: '',
                          bairro: '',
                        },
                      });
                    }}
                    disabled={
                      loading ||
                      (status !== 'Incompleto' && status !== 'Em análise')
                    }
                  />
                }
                label="Usar outro endereço para cobrança"
              />
            </FormGroup>
          </FormControl>
          {diferentAddress && (
            <fieldset>
              <legend>Endereço de Cobrança</legend>
              <div className="row">
                <div className="col-md-3 mt-2">
                  <TextField
                    label="CEP"
                    error={errorsCompany.addressOpt.cep !== ''}
                    helperText={errorsCompany.addressOpt.cep}
                    value={addressOpt.cep}
                    onChange={(e) => {
                      startEditing();
                      setAddressOpt({
                        ...addressOpt,
                        cep: normalizeCep(e.target.value),
                      });
                      if (errorsCompany.addressOpt.cep !== '') {
                        setErrorsCompany({
                          ...errorsCompany,
                          addressOpt: {
                            ...addressOpt,
                            cep: '',
                          },
                        });
                      }
                    }}
                    onBlur={handleCepAddressOpt}
                    disabled={
                      loading ||
                      (status !== 'Incompleto' && status !== 'Em análise')
                    }
                  />
                </div>
                <div className="col-md-3 mt-2">
                  <TextField
                    label="Logradouro"
                    error={errorsCompany.addressOpt.logradouro !== ''}
                    helperText={errorsCompany.addressOpt.logradouro}
                    value={addressOpt.logradouro}
                    onChange={(e) => {
                      startEditing();
                      setAddressOpt({
                        ...addressOpt,
                        logradouro: e.target.value,
                      });
                      if (errorsCompany.addressOpt.logradouro !== '') {
                        setErrorsCompany({
                          ...errorsCompany,
                          addressOpt: {
                            ...addressOpt,
                            logradouro: '',
                          },
                        });
                      }
                    }}
                    disabled={
                      loading ||
                      (status !== 'Incompleto' && status !== 'Em análise')
                    }
                  />
                </div>
                <div className="col-md-3 mt-2">
                  <TextField
                    label="Número"
                    error={errorsCompany.addressOpt.numero !== ''}
                    helperText={errorsCompany.addressOpt.numero}
                    value={addressOpt.numero}
                    onChange={(e) => {
                      startEditing();
                      setAddressOpt({
                        ...addressOpt,
                        numero: e.target.value,
                      });
                      if (errorsCompany.addressOpt.numero !== '') {
                        setErrorsCompany({
                          ...errorsCompany,
                          addressOpt: {
                            ...addressOpt,
                            numero: '',
                          },
                        });
                      }
                    }}
                    disabled={
                      loading ||
                      (status !== 'Incompleto' && status !== 'Em análise')
                    }
                  />
                </div>
                <div className="col-md-3 mt-2">
                  <TextField
                    label="Complemento"
                    value={addressOpt.complemento}
                    onChange={(e) => {
                      startEditing();
                      setAddressOpt({
                        ...addressOpt,
                        complemento: e.target.value,
                      });
                    }}
                    disabled={
                      loading ||
                      (status !== 'Incompleto' && status !== 'Em análise')
                    }
                  />
                </div>
                <div className="col-md-3 mt-2">
                  <TextField
                    label="Bairro"
                    error={errorsCompany.addressOpt.bairro !== ''}
                    helperText={errorsCompany.addressOpt.bairro}
                    value={addressOpt.bairro}
                    onChange={(e) => {
                      startEditing();
                      setAddressOpt({
                        ...addressOpt,
                        bairro: e.target.value,
                      });
                      if (errorsCompany.addressOpt.bairro !== '') {
                        setErrorsCompany({
                          ...errorsCompany,
                          addressOpt: {
                            ...addressOpt,
                            bairro: '',
                          },
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-md-3 mt-2">
                  <TextField
                    label="Cidade"
                    error={errorsCompany.addressOpt.estado !== ''}
                    helperText={errorsCompany.addressOpt.estado}
                    value={addressOpt.cidade}
                    onChange={(e) => {
                      startEditing();
                      setAddressOpt({
                        ...addressOpt,
                        cidade: e.target.value,
                      });
                      if (errorsCompany.addressOpt.cidade !== '') {
                        setErrorsCompany({
                          ...errorsCompany,
                          addressOpt: {
                            ...addressOpt,
                            cidade: '',
                          },
                        });
                      }
                    }}
                    disabled={
                      loading ||
                      (status !== 'Incompleto' && status !== 'Em análise')
                    }
                  />
                </div>
                <div className="col-md-3 mt-2">
                  <StateSelect
                    label="Estado"
                    error={errorsCompany.addressOpt.estado !== ''}
                    helperText={errorsCompany.addressOpt.estado}
                    value={addressOpt.estado}
                    onChange={(e: any) => {
                      startEditing();
                      setAddressOpt({
                        ...addressOpt,
                        estado: e.target.value,
                      });
                      if (errorsCompany.addressOpt.estado !== '') {
                        setErrorsCompany({
                          ...errorsCompany,
                          addressOpt: {
                            ...addressOpt,
                            estado: '',
                          },
                        });
                      }
                    }}
                    required={false}
                  />
                </div>
              </div>
            </fieldset>
          )}
          <fieldset>
            <h4>Informações Adicionais</h4>
            <div className="row">
              <div className="col-md-3">
                <TextField
                  label="Número de Funcionários"
                  required
                  className="d-flex"
                  error={errorsCompany.numberOfColabs !== ''}
                  helperText={errorsCompany.numberOfColabs}
                  value={numberOfColabs}
                  onChange={(e) => {
                    startEditing();
                    setNumberOfColabs(normalizeNumber(e.target.value));
                  }}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                />
              </div>
              <div className="col-md-3">
                <TextField
                  label="Site ou Rede Social"
                  className="d-flex"
                  error={errorsCompany.website !== ''}
                  helperText={errorsCompany.website}
                  value={website}
                  onChange={(e) => {
                    startEditing();
                    setWebsite(e.target.value);
                    if (errorsCompany.website !== '') {
                      setErrorsCompany({
                        ...errorsCompany,
                        website: '',
                      });
                    }
                  }}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                />
              </div>
            </div>
          </fieldset>
          <fieldset>
            <FormControl component="fieldset" className="mt-2" required>
              <FormLabel component="legend">
                A sua empresa possui outras linhas de crédito ativas em outras
                instituições financeiras?
              </FormLabel>
              <RadioGroup
                value={anotherCompanies}
                onChange={(e) => setAnotherCompanies(e.target.value)}
              >
                <div className="d-flex">
                  <FormControlLabel
                    value="0"
                    control={
                      <Radio
                        style={{ color: '#3757a1' }}
                        disabled={
                          loading ||
                          (status !== 'Incompleto' && status !== 'Em análise')
                        }
                      />
                    }
                    label="Não"
                  />
                  <FormControlLabel
                    value="1"
                    control={
                      <Radio
                        style={{ color: '#3757a1' }}
                        disabled={
                          loading ||
                          (status !== 'Incompleto' && status !== 'Em análise')
                        }
                      />
                    }
                    label="Sim"
                  />
                </div>
              </RadioGroup>
            </FormControl>
            {anotherCompanies === '1' && (
              <div className="row">
                <div className="col-md-8 mt-2">
                  <h4>Por favor, liste-as</h4>
                  <TextField
                    label="Digite as instituições separados por vírgula"
                    required
                    error={errorsCompany.financialInstitutions !== ''}
                    helperText={errorsCompany.financialInstitutions}
                    className="d-flex mb-4"
                    value={financialInstitutions}
                    onChange={(e) => {
                      startEditing();
                      setFinancialInstitutions(e.target.value);
                      if (errorsCompany.financialInstitutions !== '') {
                        setErrorsCompany({
                          ...errorsCompany,
                          financialInstitutions: '',
                        });
                      }
                    }}
                    disabled={
                      loading ||
                      (status !== 'Incompleto' && status !== 'Em análise')
                    }
                  />
                </div>
              </div>
            )}
          </fieldset>
        </form>
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={
            loading || (status !== 'Incompleto' && status !== 'Em análise')
          }
        >
          Salvar
        </Button>
      </div>
      {status !== 'Incompleto' && status !== 'Em análise' && (
        <small className="d-block text-muted mt-4">
          Deseja atualizar os dados cadastrais? Entre em contato com o nosso
          atendimento
        </small>
      )}
    </Container>
  );
};

export default memo(Geral);
