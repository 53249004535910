import React from 'react';

import { FcMoneyTransfer } from 'react-icons/fc';
import { Button } from '@material-ui/core';
import { Container } from './styles';

import { banks as bankOptions } from '../BankSelect';

import { normalizeBrl } from '~/pages/main/utils/normalize';
import { useOperation } from '~/hooks/useOperation';

interface IProps {
  open: boolean;
  setOpen: () => void;
  onConfirm: () => void;
  total: string;
  bank?: Bank;
}

const ModalConfirm: React.FC<IProps> = ({
  open,
  setOpen,
  onConfirm,
  total,
  bank,
}) => {
  const { titles } = useOperation();

  return (
    <Container show={open} onHide={() => setOpen()} size="lg" centered>
      <Container.Body>
        <div>
          <div className="header">
            <img src="/logo192.png" alt="BFC Capital Partners" />
            <h4>Revisão da Operação</h4>
          </div>
          <hr />
          <div className="value">
            <div>
              <h5>Valor Bruto</h5>
              <h3>
                <strong>{total}</strong>
              </h3>
            </div>
            <FcMoneyTransfer size={56} />
          </div>
          <div className="bank">
            <h4>Conta Bancária Selecionada</h4>
            <table>
              <tbody>
                <tr>
                  <td>BANCO:</td>
                  <td>
                    {bank &&
                      (bankOptions.find((b) => {
                        if (b.slice(0, 3) === bank.nume_banc) {
                          return b;
                        }
                        return false;
                      }) ||
                        bank.nume_banc)}
                  </td>
                </tr>
                <tr>
                  <td>AGÊNCIA:</td>
                  <td>
                    {bank
                      ? bank.nume_agen +
                        (bank.digi_agen ? `-${bank.digi_agen}` : '')
                      : ''}
                  </td>
                </tr>
                <tr>
                  <td>CONTA CORRENTE:</td>
                  <td>
                    {bank?.nume_cont}-{bank?.digi_cont}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="titles">
            <h4>Títulos</h4>
            <table>
              <tbody>
                {titles.map((title, index) => (
                  <tr key={String(index)}>
                    <td>{title.saca_id}</td>
                    <td>{title.nume_doct}</td>
                    <td>{normalizeBrl(title.valo_titu)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-end" style={{ padding: '15px' }}>
          <Button
            type="button"
            color="primary"
            variant="contained"
            onClick={() => {
              setOpen();
              onConfirm();
            }}
          >
            Enviar Operação
          </Button>
          <Button
            type="button"
            color="primary"
            variant="outlined"
            className="ml-2"
            onClick={setOpen}
          >
            Voltar
          </Button>
        </div>
      </Container.Body>
    </Container>
  );
};

export default ModalConfirm;
