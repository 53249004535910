import { takeLatest, call, all, put, select } from 'redux-saga/effects';

import { ISwitchCompanyAction } from './types';
import { persistCompany } from './actions';

import { RootState } from '~/store/useSelector';
import { factaApi } from '~/services/facta';

export function* switchCompanySuccess({ payload }: ISwitchCompanyAction): any {
  // // eslint-disable-line
  try {
    const response = yield call(factaApi.get, '/me');
    const { token } = yield select((state: RootState) => state.auth);
    console.log(response.data.permissions);
    yield put(
      persistCompany({
        company: { ...response.data.company },
        token,
        permissions: [],
      })
    );
  } catch (err) {
    console.log('error => ', err); // eslint-disable-line
  }
  return true;
}

export default all([takeLatest('@user/SWITCH_COMPANY', switchCompanySuccess)]);
