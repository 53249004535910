import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { ArrowForwardIos, ExpandMore } from '@material-ui/icons';
import { Table, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { isBefore } from 'date-fns';
import { Container, NoEcnpjWarning } from './styles';

import {
  normalizeCpfCnpj,
  cnpjIsValid,
  cpfIsValid,
  normalizeCurrency,
} from '../../utils';

import { useAuth } from '~/hooks/useAuth';
import { ecnpjApi } from '~/services/ecnpjApi';

const ConsultProtest: React.FC = () => {
  const [documentProtest, setDocumentProtest] = useState('');
  const [documentError, setDocumentError] = useState('');
  const [consultResult, setConsultResult] = useState<any>({});
  const [error, setError] = useState('');
  const [info, setInfo] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasEcnpj, setHasEcnpj] = useState(false);
  const [sampleCnpj, setSampleCnpj] = useState<string>('');

  const {
    currentCompany: { clieId: id, cnpj },
    signOut,
  } = useAuth();

  useEffect(() => {
    async function loadEcnpjs() {
      setLoading(true);
      try {
        setSampleCnpj(cnpj);
        const { data: ecnpjs } = await ecnpjApi.get(`/?cnpj=${cnpj}`);
        if (
          ecnpjs.filter(
            (e: any) => !isBefore(new Date(e.expires_in), new Date())
          ).length > 0
        ) {
          setHasEcnpj(true);
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          signOut();
          toast.error('Sua sessão expirou, entre novamente');
        } else if (err.response && err.response.status === 404) {
          setHasEcnpj(false);
        } else if (err.response && err.response.status === 403) {
          toast.error('Você não está autorizado a acessar este recurso');
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
          );
        } else {
          toast.error('Ocorreu um erro, tente novamente mais tarde');
        }
      }
      setLoading(false);
    }

    loadEcnpjs();
  }, [id, cnpj]); // eslint-disable-line

  function verifyIfIsValid(value: string) {
    setDocumentError('');
    if (value.length === 14) {
      if (!cpfIsValid(value)) {
        setDocumentError('CPF inválido');
      }
    } else if (value.length === 18) {
      if (!cnpjIsValid(value)) {
        setDocumentError('CNPJ inválido');
      }
    }
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    setError('');
    setInfo('');
    setLoading(true);
    try {
      const formattedDocument = documentProtest.replace(/[^\d]/g, '');

      if (!hasEcnpj) {
        if (sampleCnpj !== formattedDocument) {
          setError(
            'Você precisa ter um e-CNPJ vinculado a esta empresa para poder fazer esta consulta'
          );
          return;
        }
      }

      const { data: consult } = await axios.post(
        `${process.env.REACT_APP_GATEWAY_OVH}/protests`,
        {
          cnpj: formattedDocument,
        }
      );
      let total = 0;
      const consultRes = JSON.parse(consult.json);
      if (consultRes.qtdTitulos === 0) {
        setInfo('Não constam protestos nos cartórios participantes');
        return false;
      }
      consultRes.cartorio.length > 0 &&
        consultRes.cartorio.forEach((c) => {
          c.dadosCartorio.length > 0 &&
            c.dadosCartorio.forEach((d) => {
              d.titulos.length > 0 &&
                d.titulos.forEach((t) => {
                  total += Number(t.valorProtestado.replace(/[^\d]/g, ''));
                });
            });
        });
      setConsultResult({
        ...JSON.parse(consult.json),
        total: normalizeCurrency(total),
      });
    } catch (err) {
      setError('Ocorreu um erro, tente novamente mais tarde');
    } finally {
      setLoading(false);
    }
  }

  function copyToClipboard(cnpj: string) {
    const textarea = document.createElement('input');
    textarea.setAttribute('value', cnpj);
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }

  return (
    <Container>
      <div className="header_component d-flex align-items-center justify-content-between">
        <h4>
          {loading && <Spinner size="sm" animation="border" />} Consulta de
          Protestos
        </h4>
        {Object.keys(consultResult).length > 0 && !loading && (
          <Button
            type="button"
            color="primary"
            variant="outlined"
            disabled={loading}
            onClick={() => {
              setConsultResult({});
              setDocumentProtest('');
            }}
          >
            Voltar
          </Button>
        )}
      </div>
      {Object.keys(consultResult).length === 0 && !loading && (
        <NoEcnpjWarning className="mt-3">
          <img src="/assets/images/avatar-protest.png" alt="Avatar Protest" />
          <div id="text-no-ecnpj">
            <div className="detached">
              {!hasEcnpj ? (
                <>
                  <img src="/assets/images/hand.png" alt="Hand" />
                  <p>
                    Parece que você ainda não enviou nenhum{' '}
                    <strong>e-CNPJ</strong> para nossa plataforma.
                    <br />
                    <strong>
                      Envie agora e obtenha acesso a serviços exclusivos!
                    </strong>
                  </p>
                </>
              ) : (
                <>
                  <img src="/assets/images/magnifier.png" alt="Search" />
                  <p>
                    Disponibilizamos o serviço de
                    <br />
                    consulta detalhada de protesto
                    <span>
                      Consulta meramente informativa. Para comprovação das
                      informações, procurar cartórios relacionados.
                    </span>
                  </p>
                </>
              )}
            </div>
            {!hasEcnpj && (
              <>
                <br />
                <p>Os seguintes CPF/CNPJ estão disponíveis para consulta:</p>
                <ul>
                  <li>
                    {normalizeCpfCnpj(sampleCnpj)}
                    <button
                      type="button"
                      className="ml-2"
                      onClick={() => copyToClipboard(sampleCnpj)}
                    >
                      <img
                        src="/assets/images/clipboard.png"
                        alt="Clipboard"
                        style={{ width: '16px' }}
                      />
                    </button>
                  </li>
                </ul>
              </>
            )}
            {error && <div className="alert alert-danger">{error}</div>}
            {info && <div className="alert alert-info">{info}</div>}
            {documentError !== '' && (
              <span className="text-danger">{documentError}</span>
            )}
            <form
              className="align-items-center content_component d-flex"
              onSubmit={handleSubmit}
            >
              <div className="input_base mb-3">
                <input
                  type="text"
                  placeholder="Digite o CNPJ ou CPF"
                  value={documentProtest}
                  onChange={(e) => {
                    if (error !== '') {
                      setError('');
                    }
                    verifyIfIsValid(normalizeCpfCnpj(e.target.value));
                    setDocumentProtest(normalizeCpfCnpj(e.target.value));
                  }}
                  disabled={loading}
                />
                <button
                  type="submit"
                  disabled={
                    (documentProtest.length > 14
                      ? documentProtest.length < 18
                      : documentProtest.length < 14) ||
                    documentError !== '' ||
                    loading
                  }
                >
                  {loading ? (
                    <CircularProgress
                      color="primary"
                      size="14px"
                      className="mr-3"
                    />
                  ) : (
                    <ArrowForwardIos style={{ fontSize: '12px' }} />
                  )}
                </button>
              </div>
              {loading && (
                <small className="ml-4 mb-4">Aguarde alguns segundos...</small>
              )}
            </form>
          </div>
        </NoEcnpjWarning>
      )}
      {Object.keys(consultResult).length > 0 && !loading && (
        <NoEcnpjWarning className="mt-3">
          <img src="/assets/images/avatar-protest.png" alt="Avatar Protest" />
          <div id="text-no-ecnpj">
            <div className="detached">
              <img src="/assets/images/magnifier.png" alt="Search" />
              <p style={{ fontSize: '20px' }}>
                <strong>Resultado CNPJ/CPF: </strong>
                {normalizeCpfCnpj(documentProtest)}
              </p>
            </div>
            <div className="content_component d-flex flex-column align-items-start">
              <h5>{consultResult.qtdTitulos} Título(s) protestado(s)</h5>
              <h2>Total de R$ {consultResult.total}</h2>
              {loading && (
                <h5 className="text-center mb-4">
                  <CircularProgress
                    color="primary"
                    size="24px"
                    className="mr-3"
                  />
                  Aguarde, isso pode demorar um pouco...
                </h5>
              )}
            </div>
          </div>
        </NoEcnpjWarning>
      )}
      <div style={{ width: '100%', padding: '0px 25px' }}>
        {Object.keys(consultResult).length > 0 &&
          consultResult.qtdTitulos > 0 &&
          consultResult.cartorio.length > 0 &&
          consultResult.cartorio.map((cartorio, cIndex) => (
            <Accordion key={cIndex} className="mb-2 mt-2">
              <AccordionSummary
                className="d-flex justify-content-between align-items-center"
                expandIcon={<ExpandMore />}
              >
                <h5>
                  UF:
                  {cartorio.uf}
                </h5>
              </AccordionSummary>
              <AccordionDetails className="d-flex flex-column">
                {cartorio.dadosCartorio.length > 0 &&
                  cartorio.dadosCartorio.map((dadosCartorio, dIndex) => (
                    <div key={dIndex}>
                      <Table responsive="lg">
                        <thead style={{ background: '#3757a1', color: '#fff' }}>
                          <tr>
                            <th>{dadosCartorio.nomeCartorio}</th>
                            <th>{dadosCartorio.telefone}</th>
                            <th> {dadosCartorio.qtdTitulos} Título(s)</th>
                          </tr>
                        </thead>
                      </Table>
                      <Table
                        responsive="lg"
                        hover
                        style={{
                          marginTop: '-15px',
                          marginBottom: '30px',
                          width: '100%',
                        }}
                      >
                        <thead className="bg-light">
                          <tr>
                            <th>Data Protesto</th>
                            <th>Data Vencimento</th>
                            <th>Valor Protestado</th>
                            <th>Apresentante</th>
                            <th>Empresa</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dadosCartorio.titulos.length > 0 &&
                            dadosCartorio.titulos.map((titulo, tIndex) => (
                              <tr key={tIndex}>
                                <td style={{ verticalAlign: 'middle' }}>
                                  {titulo.dataProtesto}
                                </td>
                                <td style={{ verticalAlign: 'middle' }}>
                                  {titulo.dataVencimento}
                                </td>
                                <td style={{ verticalAlign: 'middle' }}>
                                  R$
                                  <span className="ml-1">
                                    {titulo.valorProtestado}
                                  </span>
                                </td>
                                <td style={{ verticalAlign: 'middle' }}>
                                  {titulo.nomeApresentante}
                                </td>
                                <td style={{ verticalAlign: 'middle' }}>
                                  {titulo.nomeCedente}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}
      </div>
    </Container>
  );
};

export default ConsultProtest;
