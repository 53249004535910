import React, { useState } from 'react';

import { Receipt } from '@material-ui/icons';
import { Container } from './styles';

import FileUpload from './FileUpload';

interface IProps {
  open: boolean;
  setOpen: () => void;
}

const ModalXml: React.FC<IProps> = ({ open, setOpen }) => {
  const [files, setFiles] = useState<File[]>([]);

  function resetForm() {
    setOpen();
    setFiles([]);
  }

  function handleRemove(index: number) {
    setFiles(files.filter((f, i) => i !== index));
  }

  return (
    <Container
      show={open}
      size="xl"
      onHide={resetForm}
      centered
      backdrop="static"
    >
      <Container.Body>
        <label htmlFor="xml">
          Selecionar Arquivos
          <input
            id="xml"
            type="file"
            onChange={(e) => {
              if (e && e.target && e.target.files) {
                setFiles([...files, ...Array.from(e.target.files)]);
              }
            }}
            multiple
            style={{ display: 'none' }}
          />
        </label>
        <div id="list-files">
          {files.map((file, index) => (
            <FileUpload
              key={index}
              file={file}
              handleRemove={(e) => handleRemove(e)}
              index={index}
            />
          ))}
          {files.length === 0 && (
            <div id="no_file">
              <Receipt />
              <h4>Nenhum arquivo selecionado</h4>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-end mt-2">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm"
            onClick={resetForm}
          >
            Fechar
          </button>
        </div>
      </Container.Body>
    </Container>
  );
};

export default ModalXml;
