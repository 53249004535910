import React, { createContext, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authApi } from '~/services/auth';
import { factaApi } from '~/services/facta';

interface Permission {
  clie_cada_deta_id: string;
  clie_id: number;
  descr: string;
  ocor_data: string;
  ocor_oper_id: string;
}
interface User {
  id: string;
  name: string;
  email: string;
}
interface Company {
  clieId: number;
  id: string;
  cnpj: string;
  name: string;
  limit: number;
  edit_data_titu: number;
  edit_nume_doc: number;
  edit_valo_titu: number;
  is_operation: boolean;
}

interface AuthContext {
  token: string | null;
  signed: boolean;
  loading: boolean;
  user: User;
  updateName: (value: string) => void;
  permissions: Permission[];
  switchCompany: (company: Company, token: string) => void;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => void;
  currentCompany: Company;
  companies: Company[];
}

const Auth = createContext<AuthContext>({} as AuthContext);
export const AuthProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState<string | null>(() => {
    const dataToken = localStorage.getItem('@:token_bfc_digital-app');

    if (dataToken) {
      axios.defaults.headers.Authorization = `Bearer ${dataToken}`;
      authApi.defaults.headers.Authorization = `Bearer ${dataToken}`;
      factaApi.defaults.headers.Authorization = `Bearer ${dataToken}`;

      return dataToken;
    }

    return null;
  });
  const [signed, setSigned] = useState(() => {
    const i = localStorage.getItem('@:signed_bfc_digital-app');
    if (i) {
      return true;
    }
    return false;
  });
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [user, setUser] = useState(() => {
    const u = localStorage.getItem('@:user_data');
    if (u) {
      return JSON.parse(u);
    }

    return {} as User;
  });

  const [currentCompany, setCurrentCompany] = useState(() => {
    const c = localStorage.getItem('@:current_company');
    if (c) {
      return JSON.parse(c);
    }
    return {
      name: '  ',
    } as Company;
  });
  const [companies, setCompanies] = useState<Company[]>([]);

  function signOut() {
    setSigned(false);
    setToken(null);
    setLoading(false);
    setPermissions([]);
    localStorage.removeItem('@:token_bfc_digital-app');
    localStorage.removeItem('@:signed_bfc_digital-app');
    localStorage.removeItem('@:current_company');
    localStorage.removeItem('@:user_data');
  }

  function switchCompany(company: Company, t: string): void {
    setToken(t);
    localStorage.setItem('@:token_bfc_digital-app', t);
    localStorage.setItem('@:current_company', JSON.stringify(company));
    authApi.defaults.headers.Authorization = `Bearer ${t}`;
    factaApi.defaults.headers.Authorization = `Bearer ${t}`;
    axios.defaults.headers.Authorization = `Bearer ${t}`;
    setCurrentCompany(company);
  }

  async function signIn(email: string, password: string): Promise<void> {
    setLoading(true);
    try {
      const { data } = await authApi.post(`/sessions`, {
        email,
        password,
      });

      setToken(data.token);

      localStorage.setItem('@:token_bfc_digital-app', data.token);

      setCurrentCompany({
        ...data.current_company[0],
        clieId: data.current_company[0]?.clie_id,
      });
      localStorage.setItem(
        '@:current_company',
        JSON.stringify({
          ...data.current_company[0],
          clieId: data.current_company[0]?.clie_id,
        })
      );
      setCompanies(data.user.companies);

      setUser(data.user);
      localStorage.setItem('@:user_data', JSON.stringify(data.user));

      axios.defaults.headers.Authorization = `Bearer ${data.token}`;
      authApi.defaults.headers.Authorization = `Bearer ${data.token}`;
      factaApi.defaults.headers.Authorization = `Bearer ${data.token}`;

      localStorage.setItem('@:signed_bfc_digital-app', 'true');
      setSigned(true);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        toast.error('Dados de Login incorretos');
      } else if (err.response && err.response.status === 400) {
        toast.error('Email ou senha inválidos');
      } else {
        toast.error('Ocorreu um erro, tente novamente');
      }
    }
    setLoading(false);
  }

  function updateName(v: string) {
    setUser({ ...user, name: v });
  }

  return (
    <Auth.Provider
      value={{
        token,
        signed,
        loading,
        signIn,
        signOut,
        permissions,
        user,
        updateName,
        currentCompany,
        companies,
        switchCompany,
      }}
    >
      {children}
    </Auth.Provider>
  );
};
export const useAuth = (): AuthContext => {
  const context = useContext(Auth);
  if (!context) {
    throw new Error('The hook useAuth must be used within an AuthProvider');
  }
  return context;
};
