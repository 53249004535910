import styled, { keyframes } from 'styled-components';

const fadeInput = keyframes`
  0% {
    transform:translateX(-30px);
    opacity:0;
  }100%{
    transform:translateX(0);
    opacity:1;
  }
`;

const fadeTop = keyframes`
0% {
    transform:translateY(-30px);
    opacity:0;
  }100%{
    transform:translateY(0);
    opacity:1;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  div#wrap-login {
    display: flex;
    flex: 1;
    width: 100%;
    div.logo-section {
      display: flex;
      flex: 0.5;
      background: none;
      justify-content: center;
      align-items: center;
      position: relative;
      img {
        z-index: 20;
        max-width: 200px;
        max-height: 150px;
        width: auto;
        height: auto;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 200%;
        height: 100%;
        background: linear-gradient(180deg, #3757a1 0%, #06205d 100%);
        border-bottom-right-radius: 50%;
      }
      &::before {
        content: '';
        position: absolute;
        top: 80px;
        right: 0;
        width: 200%;
        height: 100%;
        background: rgb(55 87 161 / 50%);
        border-bottom-right-radius: 50%;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1.5;
      h4 {
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 20px;
        color: #3757a1;
        font-weight: 700;
        animation: ${fadeTop} 300ms linear;
        animation-fill-mode: backwards;
        animation-delay: 150ms;
      }
      p.descript {
        text-align: center;
        color: #999;
        animation: ${fadeTop} 300ms linear;
        animation-fill-mode: backwards;
        animation-delay: 250ms;
      }
      div.form-container {
        width: 100%;
        max-width: 400px;
        div.button-wrap {
          display: grid;
          margin-top: 30px;
          grid-template-columns: 1fr 1fr;
          grid-gap: 10px;
          button {
            width: 100%;
            animation: ${fadeTop} 300ms linear;
            animation-fill-mode: backwards;
            animation-delay: 600ms;
          }
          a {
            color: #3757a1;
            text-align: center;
            display: block;
            padding: 10px;
            border: 1px solid #3757a1;
            border-radius: 4px;
            text-transform: uppercase;
            font-size: 14px;
            text-decoration: none !important;
            animation: ${fadeTop} 300ms linear;
            animation-fill-mode: backwards;
            animation-delay: 750ms;
          }
        }
        div.MuiTextField-root {
          width: 100%;
          margin-top: 10px;
          animation: ${fadeInput} 500ms linear;
          animation-fill-mode: backwards;
        }
        a.forgot-pass {
          margin-top: 20px;
          color: #3757a1;
          text-align: center;
          display: block;
          padding: 10px;
          border-radius: 4px;
          font-size: 14px;
          text-decoration: none !important;
          animation: ${fadeTop} 300ms linear;
          animation-fill-mode: backwards;
          animation-delay: 1050ms;
        }
      }
    }
  }
  @media screen and (max-width: 1000px) {
    & {
      overflow: unset;
      div#wrap-login {
        flex-wrap: wrap;
        div.logo-section {
          flex: unset;
          width: 100%;
          max-height: 100px;
          justify-content: flex-start;
          padding: 15px;
          img {
            height: 100%;
          }
          &::before {
            top: 30px;
          }
        }
        form {
          justify-content: flex-start;
          div.form-container {
            flex: unset;
            padding: 20px 0;
            width: 100%;
          }
        }
      }
    }
  }
`;
