import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  Button as PlusButton,
  Avatar,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  ExpandMore,
  ExitToApp,
  HighlightOff,
  PermIdentity,
  Lock,
} from '@material-ui/icons';

import axios from 'axios';
import { toast } from 'react-toastify';
import { ICompany } from '~/store/modules/auth/types';
import useSelector from '~/store/useSelector';
import { useOperation } from '~/hooks/useOperation';
import { Container, Selection, Button } from './styles';
import { normalizeCnpj } from '../../Register/utils';
import { useAuth } from '~/hooks/useAuth';

interface IProps {
  toggleTheme: (label: string) => void;
}

const Header: React.FC<IProps> = ({ toggleTheme }) => {
  const history = useHistory();
  const { resetOperation } = useOperation();
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [open, setOpen] = useState(false);
  const [newCompany, setNewCompany] = useState({
    active: false,
    value: '',
    error: '',
  });
  const [loading, setLoading] = useState(false);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const {
    user: { name: fullname, email },
    currentCompany: { name: companyName, cnpj },
    signOut,
    switchCompany,
  } = useAuth();
  const { isEditing } = useSelector((state) => state.register);

  const handleSwitchCompany = async (company: any) => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_FACTA_AUTH}/switch`,
        {
          currentClieId: Number(company.clieId),
        }
      );
      switchCompany(company, data.token);
      resetOperation();
      setOpen(false);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        signOut();
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
    setLoading(false);
  };

  async function handleAddCompany(): Promise<any> {
    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_FACTA_AUTH}/companies`, {
        clieId: newCompany.value.replace(/[^\d]/g, ''),
      });
      setNewCompany({
        active: false,
        value: '',
        error: '',
      });
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_FACTA_AUTH}/me`
      );
      if (data.redirect) {
        window.location.href = 'https://cli.bfcdigital.com.br/auth/login';
        return setLoading(false);
      }
      const companiesArr: ICompany[] = [];
      data.companies.forEach((company) => {
        companiesArr.push({
          ...company,
          clieId: company.clie_id,
          companyName: company.name || company.cnpj,
        });
      });
      setCompanies(companiesArr);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        signOut();
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 404) {
        toast.error('Empresa não encontrada');
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.message);
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
    return setLoading(false);
  }

  const subSocialReason = (value: string) => {
    return `${value.slice(0, 20)}${value.length > 20 ? '...' : ''}`;
  };

  useEffect(() => {
    async function loadCompanies() {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_FACTA_AUTH}/me`
      );
      if (data.redirect) {
        window.location.href = 'https://cli.bfcdigital.com.br/auth/login';
        return;
      }
      const companiesArr: ICompany[] = [];
      data.companies.forEach((company) => {
        companiesArr.push({
          ...company,
          clieId: company.clie_id,
          companyName: company.name || company.cnpj,
        });
      });
      setCompanies(companiesArr);
    }
    loadCompanies();
  }, []);

  const name = fullname?.split(' ');
  let inicials = '';
  if (name.length === 1) {
    inicials = name[0].slice(0, 2).toUpperCase();
  } else {
    inicials = name
      ? name.length > 0
        ? name[0].slice(0, 1) + name[1].slice(0, 1)
        : name[0].slice(0, 2)
      : '-';
  }

  // const theme = localStorage.getItem('theme_active');

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   event.target.checked ? toggleTheme('light') : toggleTheme('dark');
  // };

  return (
    <Container ref={wrapperRef}>
      <div className="container">
        <div className="d-flex align-items-center">
          <Link to="/dashboard">
            <img
              src="/assets/images/logo-branca.png"
              alt="BFC Capital Partners LTDA"
            />
          </Link>
          <h5 className="ml-4 mb-0">Antecipação de Recebíveis</h5>
        </div>
        <div id="wrap-user-company">
          <div id="section_company-selection">
            {isEditing && (
              <Tooltip className="lock" title="Edição ativa" placement="left">
                <Lock />
              </Tooltip>
            )}
            <button
              type="button"
              className="d-flex"
              onClick={() => setOpen(!open)}
              disabled={isEditing}
            >
              <span>
                {companyName && subSocialReason(companyName)} -{' '}
                {normalizeCnpj(cnpj)}
              </span>
              <Avatar variant="circle">{inicials}</Avatar>
              <ExpandMore className={`icon-arrow ${open ? 'opened' : ''}`} />
            </button>
          </div>
        </div>
        <Selection
          className={
            open && !isEditing
              ? 'section_company-open shadow'
              : 'section_company-close'
          }
        >
          <div>
            <p className="text-center company-text">
              <strong>
                {companyName}
                <br />
                {normalizeCnpj(cnpj)}
              </strong>
            </p>
          </div>
          <hr />
          <div className="mb-4" style={{ width: '100%' }}>
            {newCompany.active && (
              <div>
                <div className="d-flex align-items-center mb-2">
                  <TextField
                    label="Código do cliente"
                    value={newCompany.value}
                    onChange={(e) =>
                      setNewCompany({
                        ...newCompany,
                        value: e.target.value,
                      })
                    }
                    style={{ width: '100%' }}
                    error={newCompany.error !== ''}
                    helperText={newCompany.error}
                  />
                  <button
                    type="button"
                    className="ml-2"
                    onClick={() =>
                      setNewCompany({
                        active: false,
                        value: '',
                        error: '',
                      })
                    }
                  >
                    <HighlightOff />
                  </button>
                </div>
                <PlusButton
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{
                    width: '100%',
                    marginTop: '10px',
                  }}
                  onClick={handleAddCompany}
                  disabled={loading}
                >
                  Adicionar
                </PlusButton>
              </div>
            )}
            {email === 'ti@bfcdigital.com.br' && (
              <div className="mt-2" style={{ width: '100%' }}>
                {!newCompany.active && (
                  <PlusButton
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ width: '100%' }}
                    onClick={() =>
                      setNewCompany({
                        active: true,
                        value: '',
                        error: '',
                      })
                    }
                  >
                    Adicionar empresa
                  </PlusButton>
                )}
              </div>
            )}
          </div>
          <div id="others-companies">
            {companies && companies.length > 1 && (
              <>
                {companies.map((company) => {
                  if (company.cnpj !== cnpj) {
                    return (
                      <Button
                        type="button"
                        key={company.id}
                        onClick={() => {
                          setOpen(false);
                          handleSwitchCompany(company);
                        }}
                        disabled={company.cnpj === cnpj || loading}
                      >
                        <h4>
                          {company.companyName
                            ? subSocialReason(company.companyName)
                            : normalizeCnpj(company.cnpj)}
                        </h4>
                        <p>{normalizeCnpj(company.cnpj)}</p>
                      </Button>
                    );
                  }
                  return '';
                })}
              </>
            )}
          </div>
          {/* <FormControlLabel control={<Switch color="primary" checked={theme !== 'dark'} onChange={handleChange}/>} label="Tema escuro" /> */}
          <div
            className="d-flex justify-content-between align-items-center pt-3"
            style={{ width: '100%' }}
          >
            <button
              type="button"
              className="logout"
              onClick={() => {
                setOpen(false);
                history.push('/minha-conta');
              }}
            >
              <PermIdentity /> Perfil
            </button>
            <button type="button" className="logout" onClick={() => signOut()}>
              <ExitToApp /> Sair
            </button>
          </div>
        </Selection>
      </div>
    </Container>
  );
};

export default Header;
