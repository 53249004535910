import styled from 'styled-components';

import { Modal } from 'react-bootstrap';

export const Container = styled(Modal)`
  div.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 100%;
      max-width: 70px;
    }
    h4 {
      color: #444;
      margin: 0px;
      padding: 0px 15px;
      font-size: 20px;
    }
  }
  .actions {
    margin-bottom: 10px;
    background: #f2f2f2;
    padding: 10px;
    h4 {
      color: #1a2c51;
      font-weight: 900;
      font-size: 14px;
      margin-bottom: 0px;
    }
    a {
      font-size: 12px;
      background: #2a4aa0;
      color: #fff;
      padding: 4px 8px;
      border-radius: 2px;
      transition: 300ms;
      text-decoration: none;
      &:hover {
        background: #1f3779;
      }
    }
    a + a {
      margin-left: 6px;
    }
  }
  strong {
    display: block;
    color: #1a2c51;
    font-size: 12px;
    padding: 0px 10px;
  }
  table {
    background: #f2f2f2;
    color: #1a2c51;
    width: 100%;
    border: 4px solid #f2f2f2;
    thead {
      th {
        font-size: 12px;
        padding: 6px;
        &:last-child {
          text-align: right;
        }
      }
    }
    tbody {
      td {
        background: #fff;
        font-size: 10px;
        padding: 6px;
        &:last-child {
          text-align: right;
        }
      }
    }
  }
`;
