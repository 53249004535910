import { cpfIsValid, normalizeCpf } from '../Service/utils';

export const normalizeCurrency = (value: number): string => {
  const stringOfNumber = String(value);
  if (stringOfNumber.length === 2) {
    return `0,${stringOfNumber[0] || 0}${stringOfNumber[1] || 0}`;
  }
  if (stringOfNumber.length === 1) {
    return `0,0${stringOfNumber[0] || 0}`;
  }
  const cents =
    stringOfNumber[stringOfNumber.length - 2] +
    stringOfNumber[stringOfNumber.length - 1];
  const reals = stringOfNumber.slice(0, stringOfNumber.length - 2);
  let pointValue = '';

  const arrayNumber = reals.split('');
  arrayNumber.reverse();
  arrayNumber.forEach((v, index) => {
    if ((index + 1) % 3 === 0) {
      if (index === arrayNumber.length - 1) {
        pointValue = v + pointValue;
      } else {
        pointValue = `.${v}${pointValue}`;
      }
    } else {
      pointValue = v + pointValue;
    }
  });
  return `${pointValue},${cents}`;
};

export const normalizeBrl = (value?: number): string => {
  if (!value) {
    return '';
  }

  return new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    style: 'currency',
  }).format(value);
};

export const normalizeAccBank = (value: string): string => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/\D/g, '');

  if (onlyNums.length === 1) {
    return onlyNums;
  }

  return `${onlyNums.slice(0, onlyNums.length - 1)}-${onlyNums.slice(
    onlyNums.length - 1,
    onlyNums.length
  )}`;
};

export const normalizeNumber = (value: string): string => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/\D/g, '');

  return onlyNums;
};

export const normalizeCpfCnpj = (value: string): string => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (
    onlyNums.slice(0, 3) === '000' &&
    cpfIsValid(onlyNums.slice(3))
  ) {
    return normalizeCpf(onlyNums.slice(3));
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}`;
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}`;
  }
  if (onlyNums.length <= 11) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}-${onlyNums.slice(9, 11)}`;
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8
    )}/${onlyNums.slice(8, 12)}`;
  }
  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
    5,
    8
  )}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
};

export const normalizeDate = (value: string): string => {
  if (!value) {
    return value;
  }

  const formated = value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})\d+?$/, '$1');

  return formated;
};

export const normalizeKey = (value: string): string => {
  if (!value) {
    return value;
  }

  return `${value.slice(0, 4)} ${value.slice(4, 8)} ${value.slice(
    8,
    12
  )} ${value.slice(12, 16)} ${value.slice(16, 20)} ${value.slice(
    20,
    24
  )} ${value.slice(24, 28)} ${value.slice(32, 36)} ${value.slice(
    36,
    40
  )} ${value.slice(40, 44)}`;
};

export const normalizePaymentType = (value: string): string => {
  switch (value) {
    case '1':
      return '1 - Pagamento em Dinheiro';
    case '01':
      return '1 - Pagamento em Dinheiro';
    case '2':
      return '2 - Cheque';
    case '02':
      return '2 - Cheque';
    case '3':
      return '3 - Cartão de Crédito';
    case '03':
      return '3 - Cartão de Crédito';
    case '4':
      return '4 - Débito';
    case '04':
      return '4 - Débito';
    case '5':
      return '5 - Crédito Loja';
    case '05':
      return '5 - Crédito Loja';
    case '10':
      return '10 - Vale Alimentação';
    case '11':
      return '11 - Vale Refeição';
    case '12':
      return '12 - Vale Presente';
    case '13':
      return '13 - Vale Combustível';
    case '15':
      return '15 - Boleto Bancário';
    case '90':
      return '90 - Sem Pagamento';
    case '99':
      return '99 - Outros';
    default:
      return '';
  }
};

export function payerIsComplete(item: Title, validateAddress?: boolean): boolean {
  if (
    !item.nome ||
    item.nome === '' ||
    !item.e_mail ||
    item.e_mail === ''
  ) {
    return false;
  }
  if(validateAddress !== false) {
    if (
      !item.ende ||
      item.ende === '' ||
      !item.cep_id ||
      item.cep_id === '' ||
      !item.bair ||
      item.bair === '' ||
      !item.cida ||
      item.cida === '' ||
      !item.uf ||
      item.uf === ''
    ) {
      return false;
    }
  }
  return true;
}
