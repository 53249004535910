import React, { useState, useEffect } from 'react';

import axios from 'axios';

import {
  Menu,
  MenuItem,
  LinearProgress,
  Tooltip,
  TextField,
  Button,
} from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos, ExpandMore } from '@material-ui/icons';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { Table, Row, Col } from 'react-bootstrap';

import { format, addMinutes } from 'date-fns';
import ModalDetail from './components/ModalDetail/index';

import { Container } from './styles';

import { normalizeBrl } from '../utils/normalize';

import { ISending } from './sending';

interface ISendingDetail {
  active: boolean;
  operation?: ISending;
}
const Operations: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [operations, setOperations] = useState<ISending[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [filters, setFilters] = useState({
    endDate: '',
    startDate: '',
  });

  const [operationDetail, setOperationDetail] = useState<ISendingDetail>({
    active: false,
  });

  async function loadData(reset = false) {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_FACTA}/sendings`,
        {
          params: !reset && filters,
        }
      );
      setOperations(data);
    } catch (err) {
      //
    }
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []); //eslint-disable-line

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <div id="operations_table_wrap">
        <div className="d-flex justify-content-between align-items-center">
          <h4>Remessas</h4>
          <div>
            <Button
              color="primary"
              variant="contained"
              className="mr-2"
              type="button"
              disabled={loading}
              onClick={() => {
                loadData();
              }}
            >
              <FaSearch className="mr-2" />
              Filtrar
            </Button>
            {Object.entries(filters).filter(([k, v], i) => !!v).length > 0 && (
              <Button
                color="default"
                variant="contained"
                type="button"
                onClick={(e) => {
                  setFilters({
                    startDate: '',
                    endDate: '',
                  });

                  loadData(true);
                }}
              >
                <FaTimes className="mr-2" />
                Limpar filtros
              </Button>
            )}
          </div>
        </div>
        <div className="filters mb-4">
          <Row>
            <Col sm={3}>
              <div className="input-group">
                <TextField
                  type="date"
                  label="De"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filters.startDate}
                  onChange={(e) =>
                    setFilters({ ...filters, startDate: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col sm={3}>
              <div className="input-group">
                <TextField
                  label="até"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filters.endDate}
                  onChange={(e) =>
                    setFilters({ ...filters, endDate: e.target.value })
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
        {loading && <LinearProgress />}
        <ModalDetail
          open={operationDetail.active}
          setOpen={() => setOperationDetail({ active: false })}
          sending={operationDetail.operation}
        />
        <Table responsive hover>
          <thead>
            <tr>
              <th className="text-center">Sequencial</th>
              <th className="text-center">Data do Envio</th>
              <th className="text-center">Qtd de Títulos</th>
              <th>Vlr. Líquido</th>
              <th>Nr. Banco</th>
              <th>Nr. Agência</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {operations.length > 0 &&
              operations.map((operation, index) => {
                return (
                  <tr
                    key={Number(index)}
                    onClick={() =>
                      setOperationDetail({
                        active: true,
                        operation,
                      })
                    }
                  >
                    <td className="text-center">{operation.sequ_reme}</td>
                    <td className="text-center">
                      {operation.data_reme
                        ? format(
                            addMinutes(
                              new Date(operation.data_reme),
                              new Date(operation.data_reme).getTimezoneOffset()
                            ),
                            'dd/MM/yyyy'
                          )
                        : '-'}
                    </td>
                    <td className="text-center">
                      {operation.qtde_titu ?? '-'}
                    </td>
                    <td>
                      {operation.valo_liqui
                        ? `${normalizeBrl(operation.valo_liqui)}`
                        : `${normalizeBrl(0)}`}
                    </td>
                    <td>{operation.nume_banc ?? '-'}</td>
                    <td>{operation.nume_cont ?? '-'}</td>
                    {/* <td>{operation.operationStatus.description}</td> */}
                    <td className="text-right">
                      <button type="button">
                        <Tooltip title="Detalhamento">
                          <ArrowForwardIos />
                        </Tooltip>
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <div className="d-flex justify-content-end align-items-center footer_component">
          <div>
            Exibir:
            <button
              type="button"
              aria-controls="simple-menu"
              className="ml-4"
              aria-haspopup="true"
              style={{ color: '#444' }}
              onClick={handleClickMenu}
            >
              {perPage} <ExpandMore color="primary" />
            </button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setPerPage(25);
                  setPage(1);
                  handleClose();
                }}
              >
                25
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setPerPage(50);
                  setPage(1);
                  handleClose();
                }}
              >
                50
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setPerPage(100);
                  setPage(1);
                  handleClose();
                }}
              >
                100
              </MenuItem>
            </Menu>
            <span className="mr-4 ml-4">
              {page * perPage - perPage + 1}-{page * perPage}
            </span>
            <button
              type="button"
              disabled={page === 1 || loading}
              onClick={() => setPage(page - 1)}
            >
              <ArrowBackIos style={{ fontSize: '12px' }} />
            </button>
            <button
              type="button"
              onClick={() => setPage(page + 1)}
              disabled={operations.length < perPage || loading}
            >
              <ArrowForwardIos style={{ fontSize: '12px' }} />
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Operations;
