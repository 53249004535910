import React, { useEffect, useState } from 'react';

import { QuestionAnswer, Mail, Phone } from '@material-ui/icons';
import { Table } from 'react-bootstrap';
import { format, addMinutes } from 'date-fns';
import axios from 'axios';
import Lottie from 'react-lottie';
import animationData from '~/animations/searching_files.json';
import { Container } from './styles';

import { ISending } from '../../sending';

import { normalizeCpfCnpj, normalizeBrl } from '../../../utils/normalize';

interface IProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  sending?: ISending;
}

const ModalDetail: React.FC<IProps> = ({ open, setOpen, sending }) => {
  const [loading, setLoading] = useState(false);
  const [operation, setOperation] = useState<ISending | null>(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const { data } = await axios.get<ISending>(
          `${process.env.REACT_APP_API_FACTA}/sendings/${sending?.fili_id}/${sending?.sequ_reme}`
        );
        setOperation(data);
      } catch (err) {
        //
      }
      setLoading(false);
    }
    if (sending) {
      loadData();
    }
  }, [sending]);

  return (
    <Container show={open} onHide={() => setOpen(false)} centered size="xl">
      <Container.Header closeButton className="bg-white" />
      {loading && (
        <Container.Body className="bg-white">
          <Lottie options={defaultOptions} height={300} width={300} />
        </Container.Body>
      )}
      {!loading && operation && (
        <Container.Body
          style={{ background: 'none', padding: '0px', margin: '0px' }}
        >
          <div
            className="d-flex flex-wrap justify-content-between align-items-center bg-white"
            id="header_summary"
          >
            <div>
              <h4>Remessa: {sending?.sequ_reme}</h4>
            </div>
            <div>
              {operation.valo_liqui && (
                <h5>
                  <strong>Vlr. Líquido:</strong>{' '}
                  {normalizeBrl(operation.valo_liqui)}
                </h5>
              )}
            </div>
          </div>
          <div id="body_content" className="bg-white">
            <div>
              <h5>Títulos ({sending?.qtde_titu})</h5>
              <div className="mt-3">
                <Table hover responsive="lg">
                  <thead>
                    <tr>
                      <th>Sacado</th>
                      <th>Data vencimento</th>
                      <th>Valor</th>
                      <th>Documento</th>
                    </tr>
                  </thead>
                  {operation.titles?.map((title) => {
                    return (
                      <tr key={title.titu_id}>
                        <td>
                          {title.nome}
                          <strong style={{ display: 'block' }}>
                            {normalizeCpfCnpj(title.saca_id)}
                          </strong>
                        </td>
                        <td>
                          {title.data_titu
                            ? format(
                                addMinutes(
                                  new Date(title.data_titu),
                                  new Date(title.data_titu).getTimezoneOffset()
                                ),
                                'dd/MM/yyyy'
                              )
                            : '-'}
                        </td>
                        <td>{normalizeBrl(Math.round(title.valo_titu))}</td>
                        <td>{title.nume_doct}</td>
                      </tr>
                    );
                  })}
                </Table>
              </div>
            </div>
          </div>
          <div id="footer_content">
            <div className="d-flex align-items-center justify-content-center">
              <QuestionAnswer className="mr-4" />
              <div id="text_footer">
                <h3>Está com dúvidas?</h3>
                <p>
                  Entre em contato através do chat ou contate-nos em um dos
                  meios abaixo.
                </p>
                <div className="d-flex flex-wrap">
                  <span>
                    <Mail /> contato@grupobfc.com.br
                  </span>
                  <span className="ml-4">
                    <Phone /> 4007-2614
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Container.Body>
      )}
    </Container>
  );
};

export default ModalDetail;
