import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Container } from './styles';

const ModalPrivacyPolicy: React.FC = () => {
  const [active, seActive] = useState(false);

  useEffect(() => {
    const viewPrivacyPolicy = localStorage.getItem('viewPrivacyPolicy');
    if (!viewPrivacyPolicy) {
      seActive(true);
    }
  }, []);

  const history = useHistory();

  function onClose() {
    localStorage.setItem('viewPrivacyPolicy', '1');
    seActive(false);
  }

  function onCloseLink() {
    localStorage.setItem('viewPrivacyPolicy', '1');
    seActive(false);
    history.push('/politica-de-privacidade');
  }

  return (
    <>
      {active && (
        <Container>
          <p>
            Utilizamos cookies para oferecer melhor experiência, melhorar o
            desempenho, analisar como você interage em nosso site e personalizar
            conteúdo. <br /> Para mais detalhes confira nosso{' '}
            <button
              type="button"
              className="link-button"
              onClick={() => onCloseLink()}
            >
              Termos de Uso e Política de Privacidade
            </button>
            .
          </p>
          <button className="btn" type="button" onClick={() => onClose()}>
            Fechar
          </button>
        </Container>
      )}
    </>
  );
};

export default ModalPrivacyPolicy;
