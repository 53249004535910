import React from 'react';

import { FaFilePdf, FaCode } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { format } from 'date-fns';
import { Container } from './styles';

import INfe from '../../IJson';

import {
  normalizeCpfCnpj,
  normalizeKey,
  normalizePaymentType,
} from '../../../utils/normalize';

interface IProps {
  open: boolean;
  setOpen: () => void;
  nfe?: INfe;
}

const ModalNote: React.FC<IProps> = ({ open, setOpen, nfe }) => {
  return (
    <Container show={open} onHide={setOpen} size="lg" centered>
      <Container.Body>
        <div className="header">
          <img src="/logo192.png" alt="BFC Capital Partners" />
          <h4>Nota Emitida Contra</h4>
        </div>
        <hr />
        <div className="d-flex justify-content-between align-items-center actions">
          <h4>NF-e</h4>
          <div>
            <a
              href={`https://gateway-dfe.grupobfc.com.br/danfe/${nfe?.chNfe}`}
              target="blank"
            >
              <FaFilePdf color="#fff" /> DANFE
            </a>
            <a
              href={`https://gateway-dfe.grupobfc.com.br/xml/${nfe?.chNfe}`}
              target="blank"
            >
              <FaCode color="#fff" onClick={() => window.open()} /> XML
            </a>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Chave de Acesso</th>
              <th>Número</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{normalizeKey(nfe?.chNfe || '')}</td>
              <td>{nfe?.chNfe.slice(25, 34)}</td>
            </tr>
          </tbody>
        </table>
        <strong className="mt-2 mb-1">Emitente</strong>
        <table>
          <thead>
            <tr>
              <th>CNPJ</th>
              <th>Nome / Razão Social</th>
              <th>Inscrição Estadual</th>
              <th>UF</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {normalizeCpfCnpj(nfe?.json.nfeProc.NFe.infNFe.emit.CNPJ || '')}
              </td>
              <td>{nfe?.json.nfeProc.NFe.infNFe.emit.xNome}</td>
              <td>{nfe?.json.nfeProc.NFe.infNFe.emit.IE}</td>
              <td>{nfe?.json.nfeProc.NFe.infNFe.emit.enderEmit.UF}</td>
            </tr>
          </tbody>
        </table>
        <strong className="mt-2 mb-1">Eventos</strong>
        <table>
          <thead>
            <tr>
              <th>Evento da NF-e</th>
              <th>Protocolo</th>
              <th>Data Autorização</th>
              <th>Data Inclusão AN</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{nfe?.json.nfeProc.protNFe.infProt.xMotivo}</td>
              <td>{nfe?.json.nfeProc.protNFe.infProt.nProt}</td>
              <td>{nfe?.json.nfeProc.protNFe.infProt.dhRecbto}</td>
              <td>{nfe?.json.nfeProc.protNFe.infProt.dhRecbto}</td>
            </tr>
          </tbody>
        </table>
        <strong className="mt-2 mb-1">Parcelas</strong>
        <table>
          <thead>
            <tr>
              <th>Num.</th>
              <th>Valor</th>
              <th>Vencimento</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(nfe?.json.nfeProc.NFe.infNFe.cobr?.dup) ? (
              nfe?.json.nfeProc.NFe.infNFe.cobr?.dup.map((i, index) => (
                <tr key={String(index)}>
                  <td>{i.nDup}</td>
                  <td>R$ {i.vDup}</td>
                  <td>{format(new Date(i.dVenc), 'dd/MM/yyyy')}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>{nfe?.json.nfeProc.NFe.infNFe.cobr?.dup?.nDup}</td>
                <td>R$ {nfe?.json.nfeProc.NFe.infNFe.cobr?.dup?.vDup}</td>
                <td>
                  {nfe?.json.nfeProc.NFe.infNFe.cobr?.dup?.dVenc
                    ? format(
                        new Date(nfe.json.nfeProc.NFe.infNFe.cobr.dup.dVenc),
                        'dd/MM/yyyy'
                      )
                    : ''}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <strong className="mt-2 mb-1">Formas de Pagamento</strong>
        <table>
          <thead>
            <tr>
              <th>Forma de Pagamento</th>
              <th>Valor de Pagamento</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(nfe?.json.nfeProc.NFe.infNFe.pag.detPag) ? (
              nfe?.json.nfeProc.NFe.infNFe.pag.detPag.map((i, index) => (
                <tr key={String(index)}>
                  <td>{normalizePaymentType(i.tPag)}</td>
                  <td>R$ {i.vPag}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>
                  {normalizePaymentType(
                    nfe?.json.nfeProc.NFe.infNFe.pag.detPag.tPag || ''
                  )}
                </td>
                <td>R$ {nfe?.json.nfeProc.NFe.infNFe.pag.detPag.vPag}</td>
              </tr>
            )}
          </tbody>
        </table>
      </Container.Body>
      <Container.Footer className="d-flex justify-content-end">
        <Button
          type="button"
          variant="outline-secondary"
          size="sm"
          style={{ fontSize: '12px' }}
          onClick={setOpen}
        >
          VOLTAR
        </Button>
      </Container.Footer>
    </Container>
  );
};

export default ModalNote;
