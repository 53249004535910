import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import Header from './components/Header';
import Footer from './components/Footer';
import ModalPrivacyPolicy from './components/ModalPrivacyPolicy';
import SideMenu from './components/SideMenu';
import Confirmation from './components/Confirmation';

import Home from './Home';
import Register from './Register';
import Titles from './Titles';
import Fees from './Fees';
import Service from './Service';
import Billet from './Billet';
import Anticipation from './Anticipation';
import CteAnticipation from './CteAnticipation';
import CheckAnticipation from './CheckAnticipation';
import NfseAnticipation from './NfseAnticipation';
import Configuration from './Configuration';
import Profile from './Profile';
import Operations from './Operations';
import Sendings from './Sendings';
import Faq from './Faq';
import PrivacyPolicy from './PrivacyPolicy';

import { Container } from './styles';

import light from './light';
import dark from './dark';
import { OperationProvider } from '~/hooks/useOperation';

const AuthPage: React.FC = () => {
  const [theme, setTheme] = useState<string | null | undefined>(
    localStorage.getItem('theme_active')
  );

  const [sideOpen, setSideOpen] = useState(true);

  function toggleTheme(label: string) {
    setTheme(label);
    localStorage.setItem('theme_active', label);
  }

  return (
    <OperationProvider>
      <ThemeProvider theme={theme === 'dark' ? dark : light}>
        <Container>
          <Confirmation />
          <Header toggleTheme={(e) => toggleTheme(e)} />
          <div className="container" id="section_main">
            <div className="grid_main">
              <div
                id="side_menu_container"
                className={`${sideOpen ? 'opened' : ''}`}
                style={{ transition: '500ms' }}
              >
                <button
                  type="button"
                  id="bars_button"
                  onClick={() => setSideOpen(!sideOpen)}
                >
                  <i className={`bar ${sideOpen ? 'times' : ''}`} />
                  <i className={`bar ${sideOpen ? 'times' : ''}`} />
                  <i className={`bar ${sideOpen ? 'times' : ''}`} />
                </button>
                <SideMenu open={sideOpen} setOpen={(v) => setSideOpen(v)} />
              </div>
              <div style={{ transition: '500ms' }}>
                <div>
                  <Switch>
                    <Route path="/dashboard" exact component={Home} />
                    <Route
                      path="/cadastro/:current"
                      exact
                      component={Register}
                    />
                    <Route path="/tarifas/:current" exact component={Fees} />
                    <Route path="/titulos/:current" exact component={Titles} />
                    <Route path="/boletos" exact component={Billet} />
                    <Route
                      path="/servicos/:current"
                      exact
                      component={Service}
                    />
                    <Route path="/minha-conta" exact component={Profile} />
                    <Route path="/faq" exact component={Faq} />
                    <Route
                      path="/politica-de-privacidade"
                      exact
                      component={PrivacyPolicy}
                    />
                    <Route
                      path="/configuracoes/usuarios"
                      exact
                      component={Configuration}
                    />
                    <Route
                      path="/operacoes/antecipar-recebiveis/nfe"
                      exact
                      component={Anticipation}
                    />
                    <Route
                      path="/operacoes/antecipar-recebiveis/cte"
                      exact
                      component={CteAnticipation}
                    />
                    <Route
                      path="/operacoes/antecipar-recebiveis/cheque"
                      exact
                      component={CheckAnticipation}
                    />
                    <Route
                      path="/operacoes/antecipar-recebiveis/nfse"
                      exact
                      component={NfseAnticipation}
                    />
                    <Route
                      path="/operacoes/lista"
                      exact
                      component={Operations}
                    />
                    <Route
                      path="/operacoes/remessas"
                      exact
                      component={Sendings}
                    />
                    <Redirect to="/dashboard" />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          <ModalPrivacyPolicy />
        </Container>
      </ThemeProvider>
    </OperationProvider>
  );
};

export default AuthPage;
