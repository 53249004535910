import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  background: #fff;
  border: 2px solid #3757a1;
  width: calc(100% - 40px);
  padding: 10px 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin: 0;
  }
  button.link-button {
    color: #3757a1;
    font-weight: bold;
    padding-left: 0;
  }
  button.btn {
    background: #3757a1;
    color: #fff;
    margin-left: 50px;
  }
`;
