import React, { memo, useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import {
  Button,
  Avatar,
  TextField,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import axios from 'axios';
import { toast } from 'react-toastify';
import { editActive, editUnActive } from '~/store/modules/register/actions';

import { Container } from './styles';
import ModalConfirmation from './components/ModalConfirmation';

import EditMemberPF from './components/EditMemberPF';
import NewMemberPF from './components/NewMemberPF';
import NewMemberPJ from './components/NewMemberPJ';

import { normalizeCnpj } from '../../utils';
import { useAuth } from '~/hooks/useAuth';

interface INextItem {
  (): void;
}

interface IMember {
  id: string;
  type: string;
  name: string;
  document: string;
  docType: string;
}

interface IProps {
  handleNextItem: INextItem;
}

interface IMemberType {
  id: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

interface INewPJ {
  cnpj: string;
  error: string;
  type: string | undefined | unknown;
  active: boolean;
}

interface IEditPf {
  active: boolean;
  id: null | string;
}

interface IDelete {
  active: boolean;
  id: null | string;
  member: IMember;
}

const Member: React.FC<IProps> = ({ handleNextItem }) => {
  const [memberTypes, setMemberTypes] = useState<IMemberType[]>([]);
  const [editPf, setEditPf] = useState<IEditPf>({ active: false, id: null });
  const [loading, setLoading] = useState(false);
  const [newActive, setNewActive] = useState({ active: false, type: '' });
  const [members, setMembers] = useState<IMember[]>([]);
  const [newPJ, setNewPJ] = useState<INewPJ>({
    cnpj: '',
    error: '',
    type: '',
    active: false,
  });
  const [status, setStatus] = useState<
    | 'Incompleto'
    | 'Em análise'
    | 'Aguardando geração do contrato'
    | 'Aguardando assinatura do contrato'
    | 'Em operação'
  >('Em operação');

  const [idPJ, setIdPJ] = useState<string | null>(null);
  const [deleteMember, setDeleteMember] = useState<IDelete>({
    active: false,
    id: null,
    member: {
      id: '',
      type: '',
      name: '',
      document: '',
      docType: '',
    },
  });

  const {
    currentCompany: { cnpj, clieId: id },
    signOut,
  } = useAuth();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const resetForm = () => {
    setNewActive({ active: false, type: '' });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (newActive.active || editPf.active) {
      dispatch(editActive());
    } else {
      dispatch(editUnActive());
    }
  }, [newActive.active, editPf.active]); // eslint-disable-line

  const addMember = (member: IMember) => {
    setMembers([...members, member]);
  };

  const handleNewPJ = async (e: any) => {
    e.preventDefault();
    function cnpjIsValid(cnpj: any) {
      cnpj = cnpj.replace(/[^\d]+/g, '');

      if (cnpj === '') return false;

      if (cnpj.length !== 14) return false;

      // Elimina CNPJs invalidos conhecidos
      if (
        cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999'
      )
        return false;

      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      const digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado !== Number(digitos.charAt(0))) return false;

      tamanho += 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado !== Number(digitos.charAt(1))) return false;

      return true;
    }
    if (members.find((m) => m.document === newPJ.cnpj.replace(/[^\d]/g, ''))) {
      return toast.error('CNPJ já cadastrado como sócio');
    }
    if (cnpj.replace(/[^\d]/g, '') === newPJ.cnpj.replace(/[^\d]/g, '')) {
      return toast.error('CNPJ não pode ser o mesmo da empresa');
    }
    if (!cnpjIsValid(newPJ.cnpj.replace(/[^\d]/g, ''))) {
      return toast.error('CNPJ inválido');
    }
    try {
      const { data } = await axios.post(`/partners/juridical-persons`, {
        cnpj: newPJ.cnpj.replace(/[^\d]/g, ''),
        memberTypeId: newPJ.type,
      });
      const typeSlug = memberTypes.find((t) => t.id === newPJ.type);

      if (typeSlug) {
        addMember({
          id: data.id,
          name: data.name || newPJ.cnpj,
          document: newPJ.cnpj,
          docType: 'PJ',
          type: typeSlug.type,
        });
        setNewPJ({
          active: false,
          cnpj: '',
          error: '',
          type: '',
        });
      } else {
        setNewPJ({
          active: false,
          cnpj: '',
          error: '',
          type: '',
        });
      }
      setIdPJ(data.id);
      setNewActive({ active: true, type: 'PJ' });
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setNewPJ({
          ...newPJ,
          error: 'Empresa já cadastrada',
        });
      } else if (err.response && err.response.status === 500) {
        setNewPJ({
          ...newPJ,
          error:
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde',
        });
      } else {
        setNewPJ({
          ...newPJ,
          error: 'Ocorreu um erro, tente novamente mais tarde',
        });
      }
    }
  };

  useEffect(() => {
    async function loadMemberTypes() {
      try {
        const { data } = await axios.get(`/partners/member-types`);
        setMemberTypes(data.filter((type) => type.visible));
      } catch (err) {
        if (err.response && err.response.status === 401) {
          signOut();
          toast.error('Sua sessão expirou, entre novamente');
        } else if (err.response && err.response.status === 403) {
          toast.error('Você não está autorizado a acessar este recurso');
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
          );
        } else {
          toast.error('Ocorreu um erro, tente novamente mais tarde');
        }
      }
    }
    loadMemberTypes();
  }, []); // eslint-disable-line

  useEffect(() => {
    async function loadMembers() {
      setLoading(true);
      try {
        const { data } = await axios.get(`/companies/status/${id}`);
        setStatus(data.status);
      } catch (err) {}
      try {
        const { data: pf_member } = await axios.get(
          '/partners/physical-persons'
        );
        const { data: pj_member } = await axios.get(
          '/partners/juridical-persons'
        );
        const member_arr: IMember[] = [];
        pf_member.forEach((i: any) => {
          const { memberTypes } = i;

          memberTypes.forEach((t: any) => {
            if (t.visible) {
              member_arr.push({
                id: i.id,
                name: i.name,
                document: i.cpf,
                docType: 'PF',
                type: t.type,
              });
            }
          });
        });
        pj_member.forEach((i: any) => {
          const { memberTypes } = i;
          memberTypes.forEach((t: any) => {
            if (t.visible) {
              member_arr.push({
                id: i.id,
                name: i.companyName || normalizeCnpj(i.cnpj),
                document: i.cnpj,
                docType: 'PJ',
                type: t.type,
              });
            }
          });
        });
        setMembers(member_arr);
        if (urlParams.get('pendency') === '1') {
          const id = urlParams.get('id');
          const member = member_arr.find((member) => member.id === id);
          if (member) {
            if (member.docType === 'PJ') {
              setIdPJ(id);
              setNewActive({ active: true, type: 'PJ' });
            } else {
              setEditPf({
                active: true,
                id,
              });
            }
          }
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          signOut();
          toast.error('Sua sessão expirou, entre novamente');
        } else if (err.response && err.response.status === 403) {
          toast.error('Você não está autorizado a acessar este recurso');
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
          );
        } else {
          toast.error('Ocorreu um erro, tente novamente mais tarde');
        }
      }
      setLoading(false);
    }
    loadMembers();
  }, [id]); // eslint-disable-line

  async function handleDelete() {
    setLoading(true);
    try {
      if (deleteMember.member.docType === 'PF') {
        await axios.delete(`/partners/physical-persons/${deleteMember.id}`);
      } else {
        await axios.delete(`/partners/juridical-persons/${deleteMember.id}`);
      }
      setMembers(members.filter((m) => m.id !== deleteMember.id));
      setDeleteMember({
        active: false,
        id: null,
        member: {
          id: '',
          type: '',
          name: '',
          document: '',
          docType: '',
        },
      });
    } catch (err) {
      if (err.response && err.response.status === 401) {
        signOut();
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
    setLoading(false);
  }

  return (
    <Container>
      <div className="header_component d-flex">
        {newActive.active ? (
          <>
            <Avatar
              variant="circle"
              style={{ color: '#3757a1', background: '#f7f7f7' }}
            >
              {newActive.type === 'PJ' ? 'E' : 'N'}
            </Avatar>
            <div className="ml-4">
              <h4>{newActive.type === 'PJ' ? 'Empresa' : 'Novo'}</h4>
              <p>
                Sócio{' '}
                {newActive.type === 'PJ' ? 'Pessoa Jurídica' : 'Pessoa Física'}
              </p>
            </div>
          </>
        ) : (
          <h4>Sócios</h4>
        )}
      </div>
      <ModalConfirmation
        open={deleteMember.active}
        setOpen={() =>
          setDeleteMember({
            active: false,
            id: null,
            member: {
              id: '',
              type: '',
              name: '',
              document: '',
              docType: '',
            },
          })
        }
        loading={loading}
        member={deleteMember.member.name}
        onDelete={handleDelete}
      />
      {!newActive.active && !editPf.active ? (
        <div className="content_component">
          {loading && <LinearProgress />}
          <div className="content_component-members">
            {!loading &&
              members.length > 0 &&
              members.map((member, index) => {
                if (member.docType === 'PJ') {
                  return (
                    <div className="member-item" key={String(index)}>
                      <div className="member-item-header">{member.name}</div>
                      {(status === 'Incompleto' || status === 'Em análise') && (
                        <button
                          type="button"
                          className="button-close"
                          onClick={() =>
                            setDeleteMember({
                              active: true,
                              id: member.id,
                              member,
                            })
                          }
                        >
                          <Close />
                        </button>
                      )}
                      <div>
                        <p>
                          <strong>{member.type}</strong>
                        </p>
                        <p>
                          <strong>CNPJ:</strong>{' '}
                          {normalizeCnpj(member.document)}
                        </p>
                      </div>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ width: '100%', marginTop: 'auto' }}
                        onClick={() => {
                          setIdPJ(member.id);
                          setNewActive({ active: true, type: 'PJ' });
                        }}
                      >
                        {status === 'Incompleto' || status === 'Em análise'
                          ? 'Editar'
                          : 'Ver'}
                      </Button>
                    </div>
                  );
                }
                return (
                  <div className="member-item" key={index}>
                    <div className="member-item-header">{member.name}</div>
                    {(status === 'Incompleto' || status === 'Em análise') && (
                      <button
                        type="button"
                        className="button-close"
                        onClick={() =>
                          setDeleteMember({
                            active: true,
                            id: member.id,
                            member,
                          })
                        }
                      >
                        <Close />
                      </button>
                    )}
                    <div>
                      <p>
                        <strong>{member.type}</strong>
                      </p>
                      <p>
                        <strong>CPF:</strong> {member.document}
                      </p>
                    </div>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ width: '100%', marginTop: 'auto' }}
                      onClick={() =>
                        setEditPf({
                          active: true,
                          id: member.id,
                        })
                      }
                    >
                      {status === 'Incompleto' || status === 'Em análise'
                        ? 'Editar'
                        : 'Ver'}
                    </Button>
                  </div>
                );
              })}
            {!loading && (status === 'Incompleto' || status === 'Em análise') && (
              <div className="member-item detached">
                {newPJ.active ? (
                  <form onSubmit={handleNewPJ} className="pt-2">
                    <TextField
                      value={newPJ.cnpj}
                      label="CNPJ"
                      onChange={(e) => {
                        setNewPJ({
                          ...newPJ,
                          cnpj: normalizeCnpj(e.target.value),
                        });
                      }}
                      error={newPJ.error !== ''}
                      helperText={newPJ.error}
                      className="mb-3"
                      style={{ width: '100%' }}
                    />
                    <FormControl style={{ width: '100%', maxWidth: '100%' }}>
                      <InputLabel>Tipo</InputLabel>
                      <Select
                        style={{ width: '100%', maxWidth: '100%' }}
                        value={newPJ.type}
                        onChange={(e) =>
                          setNewPJ({ ...newPJ, type: e.target.value })
                        }
                      >
                        {memberTypes.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="d-flex mt-4 mb-3">
                      <Button
                        type="submit"
                        color="primary"
                        disabled={newPJ.type === '' || newPJ.cnpj === ''}
                        variant="contained"
                        style={{ color: '#fff' }}
                      >
                        Salvar
                      </Button>
                      <Button
                        color="primary"
                        variant="outlined"
                        className="ml-2"
                        onClick={() => {
                          dispatch(editUnActive());
                          setNewPJ({
                            active: false,
                            type: '',
                            cnpj: '',
                            error: '',
                          });
                        }}
                      >
                        Voltar
                      </Button>
                    </div>
                  </form>
                ) : (
                  <>
                    <Button
                      onClick={() => setNewActive({ active: true, type: 'PF' })}
                      style={{ width: '100%' }}
                    >
                      Adicionar Sócio Pessoa Física
                    </Button>
                    <Button
                      onClick={() =>
                        setNewPJ({
                          active: true,
                          cnpj: '',
                          type: '',
                          error: '',
                        })
                      }
                      style={{ width: '100%' }}
                    >
                      Adicionar Sócio Pessoa Jurídica
                    </Button>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          {newActive.type === 'PF' && (
            <NewMemberPF
              resetForm={() => resetForm()}
              addMember={(e) => addMember(e)}
            />
          )}
          {newActive.type === 'PJ' && (
            <NewMemberPJ
              resetForm={() => resetForm()}
              id={idPJ}
              editMember={() => {}}
              status={status}
            />
          )}
          {editPf.active && editPf.id && (
            <EditMemberPF
              resetForm={() =>
                setEditPf({
                  active: false,
                  id: null,
                })
              }
              status={status}
              id={editPf.id}
              editMember={() => {}}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default memo(Member);
