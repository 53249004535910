import React from 'react';

import { useDispatch } from 'react-redux';
import { Button, Tooltip } from '@material-ui/core';
import { CheckCircleOutline, ErrorOutline } from '@material-ui/icons';
import { editActive } from '../../../../../../store/modules/register/actions';

interface itemDocumentsInt {
  id: string;
  label: string;
  status: string;
  file: string;
  rejectReasons: string[];
}
interface IItem {
  id: string;
  name: string;
  doc: string;
  type: 'company' | 'pf' | 'pj';
  documents: itemDocumentsInt[];
}
interface IDocActive {
  active: boolean;
  item: IItem;
}
interface IDocumentActive {
  ({ active, item }: IDocActive): void;
}
interface IProps {
  item: IItem;
  setDocumentActive: IDocumentActive;
}

const DocItemList: React.FC<IProps> = ({ item, setDocumentActive }) => {
  const dispatch = useDispatch();
  return (
    <div>
      <h4 className={item.type === 'company' ? 'company' : 'member'}>
        {item.name}
        {item.type === 'company' && <small>CNPJ: {item.doc}</small>}
        {item.type === 'pf' && <small>CPF: {item.doc}</small>}
        {item.type === 'pj' && <small>CNPJ: {item.doc}</small>}
      </h4>
      <table className="table">
        <tbody>
          <tr>
            <td>Documento</td>
            <td className="text-right">Status</td>
          </tr>
          {item.documents.length > 0 &&
            item.documents.map((doc, ind) => (
              <tr key={String(ind)}>
                <td>{doc.label}</td>
                <td>
                  <small className="d-block text-right">
                    {doc.status === 'UPLOADED' && (
                      <Tooltip title="Em análise">
                        <img
                          src="/assets/images/icon-em-analise.png"
                          style={{
                            width: '20px',
                            height: 'auto',
                          }}
                          alt=""
                        />
                      </Tooltip>
                    )}
                    {doc.status === 'EMPTY' && (
                      <Tooltip title="Não enviado">
                        <img
                          src="/assets/images/icon-a-enviar.png"
                          style={{
                            width: '20px',
                            height: 'auto',
                          }}
                          alt=""
                        />
                      </Tooltip>
                    )}
                    {doc.status === 'REJECTED' && (
                      <Tooltip title="Documento inválido">
                        <ErrorOutline
                          style={{
                            color: '#E16A53',
                            fontSize: '20px',
                          }}
                        />
                      </Tooltip>
                    )}
                    {doc.status === 'APPROVED' && (
                      <Tooltip title="Documento aprovado">
                        <CheckCircleOutline
                          style={{
                            color: '#30DC75',
                            fontSize: '20px',
                          }}
                        />
                      </Tooltip>
                    )}
                    {doc.status === 'EXEMPT' && (
                      <Tooltip title="Isento">
                        <img
                          src="/assets/images/icon-em-analise.png"
                          style={{
                            color: 'orange',
                            width: '20px',
                            height: 'auto',
                          }}
                          alt=""
                        />
                      </Tooltip>
                    )}
                  </small>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {item.documents.find(
        (d) => d.status !== 'APPROVED' && d.status !== 'EXEMPT'
      ) && (
        <Button
          variant="outlined"
          color={
            item.documents.find((d) => d.status === 'REJECTED')
              ? 'secondary'
              : 'primary'
          }
          style={{ width: '100%', marginTop: 'auto' }}
          onClick={() => {
            setDocumentActive({ active: true, item });
            dispatch(editActive());
          }}
        >
          {item.documents.find((d) => d.status === 'REJECTED')
            ? 'RESOLVER PENDÊNCIA(S)'
            : 'ENVIAR DOCUMENTO(S)'}
        </Button>
      )}
    </div>
  );
};

export default DocItemList;
