import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ResponsivePie } from '@nivo/pie';
import { format, addMinutes, addDays } from 'date-fns';
import { CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Lottie from 'react-lottie';
import { toast } from 'react-toastify';
import { isBefore } from 'date-fns/esm';

import animationData from '~/animations/dashboard_animation.json';

import { Container } from './styles';
import { normalizeCpfCnpj, normalizeBrl } from '../utils/normalize';
import { editUnActive } from '~/store/modules/register/actions';
import { ITitle } from '../Titles/components/ITitle';
import { IOperation } from '../Operations/operation';
import { useAuth } from '~/hooks/useAuth';

const Home: React.FC = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [filterTitle, setFilterTitle] = useState('Todos');
  const [titles, setTitles] = useState<ITitle[]>([]);
  const [operations, setOperations] = useState<IOperation[]>([]);
  const {
    currentCompany: { clieId },
    token,
    signOut,
  } = useAuth();

  useEffect(() => {
    async function loadDashboard() {
      setLoading(true);
      try {
        axios.defaults.headers.Authorization = `Bearer ${token}`;

        if (typeof clieId === 'number') {
          const { data: dataTitles } = await axios.get(
            `${process.env.REACT_APP_API_FACTA}/titles?situation=dashboard&all=1`
          );
          setTitles(dataTitles.data);

          const { data } = await axios.get(
            `${process.env.REACT_APP_API_FACTA}/operations?perpage=16&page=1`
          );
          setOperations(data.data);
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          signOut();
          toast.error('Sua sessão expirou, entre novamente');
        } else if (err.response && err.response.status === 403) {
          toast.error('Você não está autorizado a acessar este recurso');
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
          );
        } else {
          toast.error('Ocorreu um erro, tente novamente mais tarde');
        }
      }
      setLoading(false);
    }
    loadDashboard();
  }, [token, clieId]); // eslint-disable-line

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    dispatch(editUnActive());
  }, []); // eslint-disable-line

  return (
    <Container>
      {!loading ? (
        operations.length === 0 ? (
          <>
            <div className="row">
              <div className="col-12">
                <div className="widget-about">
                  <div className="widget-about__box_text">
                    <p>
                      Uma plataforma para você <br /> antecipar seus recebíveis.
                    </p>
                    <strong>Venda a prazo, receba à vista!</strong>
                  </div>
                  <div className="widget-about__box_illustrator">
                    <div className="actor">
                      <img src="/assets/images/actor.png" alt="" />
                    </div>
                    <div className="bigPlant">
                      <img src="/assets/images/big-plant.png" alt="" />
                    </div>
                    <div className="smallPlant">
                      <img src="/assets/images/small-plant.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-8">
                <div className="widget-anticipation-sale">
                  <div className="widget-anticipation-sale__box_text">
                    <p>
                      Vamos
                      <br /> começar a{' '}
                      <strong>
                        adiantar os <br /> recebíveis?
                      </strong>
                    </p>
                    <a href="/operacoes/novo">quero antecipar</a>
                  </div>
                  <div className="widget-anticipation-sale__box_illustrator">
                    <img src="/assets/images/antecipe.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="row">
            <div className="col-sm-12 col-lg-8">
              <div className="row">
                <div className="col-sm-12 col-lg-6">
                  <div className="widget-services">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <h4>Consulta de Empresa</h4>
                          <p>
                            Consultas cadastrais de CNPJ junto à Receita
                            Federal.
                          </p>
                          <a className="btn" href="/servicos/empresa">
                            Consultar
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6">
                  <div className="widget-services">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <h4>Consulta de Protesto</h4>
                          <p>
                            Consultas de restrições cadastrais junto à central
                            de protesto.
                          </p>
                          <a className="btn" href="/servicos/protesto">
                            Consultar
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="widget-titles">
                    <div className="row">
                      <div className="col-12">
                        <div className="widget-titles__header">
                          <h4>Títulos em Aberto</h4>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4" style={{ height: '450px' }}>
                        <ResponsivePie
                          data={[
                            {
                              id: 'A vencer',
                              label: 'A vencer',
                              value: titles.filter(
                                (title) =>
                                  !isBefore(
                                    addMinutes(
                                      new Date(title.data_depo),
                                      new Date(
                                        title.data_depo
                                      ).getTimezoneOffset()
                                    ),
                                    new Date()
                                  )
                              ).length,
                              color: 'hsl(24, 70%, 50%)',
                            },
                            {
                              id: 'Vencidos',
                              label: 'Vencidos',
                              value: titles.filter((title) =>
                                isBefore(
                                  addDays(
                                    addMinutes(
                                      new Date(title.data_depo),
                                      new Date(
                                        title.data_depo
                                      ).getTimezoneOffset()
                                    ),
                                    1
                                  ),
                                  new Date()
                                )
                              ).length,
                              color: 'hsl(138, 70%, 50%)',
                            },
                          ]}
                          startAngle={180}
                          margin={{ top: 40, right: 0, bottom: 40, left: 80 }}
                          innerRadius={0.5}
                          padAngle={0.7}
                          cornerRadius={3}
                          colors={{ scheme: 'nivo' }}
                          borderWidth={1}
                          borderColor={{
                            from: 'color',
                            modifiers: [['darker', 0.2]],
                          }}
                          radialLabelsSkipAngle={10}
                          radialLabelsTextXOffset={0}
                          radialLabelsTextColor="#333333"
                          radialLabelsLinkOffset={0}
                          radialLabelsLinkDiagonalLength={0}
                          radialLabelsLinkHorizontalLength={24}
                          radialLabelsLinkStrokeWidth={1}
                          radialLabelsLinkColor={{ from: 'color' }}
                          slicesLabelsSkipAngle={10}
                          slicesLabelsTextColor="#333333"
                          animate
                          motionStiffness={90}
                          motionDamping={15}
                          defs={[
                            {
                              id: 'dots',
                              type: 'patternDots',
                              background: 'inherit',
                              color: 'rgba(255, 255, 255, 0.3)',
                              size: 4,
                              padding: 1,
                              stagger: true,
                            },
                            {
                              id: 'lines',
                              type: 'patternLines',
                              background: 'inherit',
                              color: 'rgba(255, 255, 255, 0.3)',
                              rotation: -45,
                              lineWidth: 6,
                              spacing: 10,
                            },
                          ]}
                          fill={[
                            {
                              match: {
                                id: 'ruby',
                              },
                              id: 'dots',
                            },
                            {
                              match: {
                                id: 'c',
                              },
                              id: 'dots',
                            },
                            {
                              match: {
                                id: 'go',
                              },
                              id: 'dots',
                            },
                            {
                              match: {
                                id: 'python',
                              },
                              id: 'dots',
                            },
                          ]}
                        />
                      </div>
                      <div className="col-8" style={{ height: '450px' }}>
                        <div className="widget-titles__box_body">
                          <ul>
                            <li>
                              <button
                                type="button"
                                className={`btn btn-sm ${
                                  filterTitle === 'Todos' && 'active'
                                }`}
                                onClick={() => setFilterTitle('Todos')}
                              >
                                Todos
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                className={`btn btn-sm ${
                                  filterTitle === 'A vencer' && 'active'
                                }`}
                                onClick={() => setFilterTitle('A vencer')}
                              >
                                A vencer
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                className={`btn btn-sm ${
                                  filterTitle === 'Vencidos' && 'active'
                                }`}
                                onClick={() => setFilterTitle('Vencidos')}
                              >
                                Vencidos
                              </button>
                            </li>
                          </ul>
                          <div>
                            <table>
                              <thead>
                                <tr>
                                  <th className="text-left">Sacado</th>
                                  <th className="text-center">Valor</th>
                                  <th className="text-center">Vencimento</th>
                                  <th className="text-center">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {titles
                                  .filter((title) => {
                                    if (filterTitle === 'A vencer') {
                                      if (
                                        !isBefore(
                                          addMinutes(
                                            new Date(title.data_depo),
                                            new Date(
                                              title.data_depo
                                            ).getTimezoneOffset()
                                          ),
                                          new Date()
                                        )
                                      ) {
                                        return true;
                                      }
                                      return false;
                                    }
                                    if (filterTitle === 'Vencidos') {
                                      if (
                                        isBefore(
                                          addDays(
                                            addMinutes(
                                              new Date(title.data_depo),
                                              new Date(
                                                title.data_depo
                                              ).getTimezoneOffset()
                                            ),
                                            1
                                          ),
                                          new Date()
                                        )
                                      ) {
                                        return true;
                                      }
                                      return false;
                                    }
                                    return true;
                                  })
                                  .map((title, index) => {
                                    // eslint-disable-line
                                    return (
                                      <tr key={String(index)}>
                                        <td className="text-left">
                                          {title.nome}
                                          <span>
                                            {normalizeCpfCnpj(title.saca_id)}
                                          </span>
                                        </td>
                                        <td
                                          className="text-center"
                                          style={{ whiteSpace: 'nowrap' }}
                                        >
                                          {normalizeBrl(title.valo_titu)}
                                        </td>
                                        <td className="text-center">
                                          {format(
                                            addMinutes(
                                              new Date(title.data_depo),
                                              new Date(
                                                title.data_depo
                                              ).getTimezoneOffset()
                                            ),
                                            'dd/MM/yyyy'
                                          )}
                                        </td>
                                        <td
                                          className="text-center"
                                          style={{ whiteSpace: 'nowrap' }}
                                        >
                                          {isBefore(
                                            addMinutes(
                                              new Date(title.data_depo),
                                              new Date(
                                                title.data_depo
                                              ).getTimezoneOffset()
                                            ),
                                            new Date()
                                          ) ? (
                                            <small className="ematraso">
                                              Vencido
                                            </small>
                                          ) : (
                                            <small className="avencer">
                                              A vencer
                                            </small>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4">
              <div className="row">
                <div className="col-12">
                  <div className="widget-calendar">
                    <div className="widget-calendar__box">
                      <div className="widget-calendar__box_header">
                        <h4>Últimas Operações</h4>
                        <a className="btn" href="/operacoes/lista">
                          Ver todas
                        </a>
                      </div>
                      <div className="widget-calendar__box_body">
                        <table className="table table-striped">
                          <tbody>
                            {operations.length > 0 &&
                              operations.map((operation) => (
                                <tr key={operation.bord_id}>
                                  <td className="pl-2">{operation.bord_id}</td>
                                  <td>
                                    {normalizeBrl(operation.valo_digi || 0)}
                                  </td>
                                  <td className="text-center">
                                    {operation.data_bord
                                      ? format(
                                          addMinutes(
                                            new Date(operation.data_bord),
                                            new Date(
                                              operation.data_bord
                                            ).getTimezoneOffset()
                                          ),
                                          'dd/MM/yyyy'
                                        )
                                      : '-'}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="loading">
          <Lottie options={defaultOptions} height={400} width={400} />
          <div className="d-flex">
            <CircularProgress />
            <h2 className="ml-2">
              Estamos Montando seu Painel. Aguarde um instante...
            </h2>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Home;
