import React, { memo, useState, useEffect } from 'react';

import {
  LinearProgress,
  TextField,
  Menu,
  MenuItem,
  Button,
  Tooltip,
} from '@material-ui/core';

import { ArrowBackIos, ArrowForwardIos, ExpandMore } from '@material-ui/icons';
import {
  FaPrint,
  FaSearch,
  FaTimes,
  FaBarcode,
  FaTable,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
} from 'react-icons/fa';

import { format, addMinutes } from 'date-fns';

import { Table, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { normalizeCpfCnpj, normalizeCurrency } from '../../utils';

import { ITitle } from '../ITitle';
import { normalizeBrl } from '~/pages/main/utils/normalize';
import { useAuth } from '~/hooks/useAuth';

interface INextItem {
  (): void;
}

interface IProps {
  handleNextItem: INextItem;
}

const Open: React.FC<IProps> = () => {
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [lastPage, setLastPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [totalValue, setTotalValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filters, setFilters] = useState({
    dataTituStart: '',
    dataTituEnd: '',
    sacaId: '',
    numeDoct: '',
    valoTitu: '',
  });

  const [titles, setTitles] = useState<ITitle[]>([]);
  const {
    currentCompany: { clieId: id },
    signOut,
  } = useAuth();

  async function loadTitles(refresh?: boolean) {
    const query: string[] = [];
    if (!refresh) {
      if (filters.dataTituStart) {
        query.push(`dataTituStart=${filters.dataTituStart}`);
      }
      if (filters.dataTituEnd) {
        query.push(`dataTituEnd=${filters.dataTituEnd}`);
      }
      if (filters.sacaId) {
        query.push(`sacaId=${filters.sacaId.replace(/[^\d]/g, '')}`);
      }
      if (filters.numeDoct) {
        query.push(`numeDoct=${filters.numeDoct}`);
      }
      if (filters.valoTitu) {
        query.push(
          `valoTitu=${Number(filters.valoTitu.replace(/[^\d]/g, '')) / 100}`
        );
      }
    }
    try {
      const { data: dataTitlesTotal } = await axios.get(
        `${
          process.env.REACT_APP_API_FACTA
        }/total/titles?situation=open&${query.map((i) => i).join('&')}`
      );
      setTotalValue(dataTitlesTotal.total);
    } catch (error) {
      console.log(error.message); // eslint-disable-line
    }

    if (page) {
      query.push(`page=${page}`);
    }
    if (perPage) {
      query.push(`perpage=${perPage}`);
    }
    setLoading(true);
    try {
      const { data: dataTitles } = await axios.get(
        `${process.env.REACT_APP_API_FACTA}/titles?situation=open&${query
          .map((i) => i)
          .join('&')}`
      );
      setTitles(dataTitles.data);
      setLastPage(dataTitles.meta.last_page);
      setTotal(dataTitles.meta.total);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        signOut();
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
    setLoading(false);
  }

  async function handlePrint() {
    const query: string[] = [];

    if (filters.dataTituStart) {
      query.push(`dataTituStart=${filters.dataTituStart}`);
    }
    if (filters.dataTituEnd) {
      query.push(`dataTituEnd=${filters.dataTituEnd}`);
    }
    if (filters.sacaId) {
      query.push(`sacaId=${filters.sacaId.replace(/[^\d]/g, '')}`);
    }
    if (filters.numeDoct) {
      query.push(`numeDoct=${filters.numeDoct}`);
    }
    if (filters.valoTitu) {
      query.push(
        `valoTitu=${Number(filters.valoTitu.replace(/[^\d]/g, '')) / 100}`
      );
    }

    query.push(`clieId=${id}`);

    window.open(
      `${process.env.REACT_APP_API_FACTA}/print/titles/open?${query
        .map((i) => i)
        .join('&')}`,
      '_blank'
    );
  }

  async function handleCSV() {
    const query: string[] = [];

    query.push(`clieId=${id}`);
    query.push('situation=open');

    if (filters.dataTituStart) {
      query.push(`dataTituStart=${filters.dataTituStart}`);
    }
    if (filters.dataTituEnd) {
      query.push(`dataTituEnd=${filters.dataTituEnd}`);
    }
    if (filters.sacaId) {
      query.push(`sacaId=${filters.sacaId.replace(/[^\d]/g, '')}`);
    }
    if (filters.numeDoct) {
      query.push(`numeDoct=${filters.numeDoct}`);
    }
    if (filters.valoTitu) {
      query.push(
        `valoTitu=${Number(filters.valoTitu.replace(/[^\d]/g, '')) / 100}`
      );
    }
    query.push(`order=ASC`);

    window.open(
      `${process.env.REACT_APP_API_FACTA}/csv?${query.map((i) => i).join('&')}`,
      '_blank'
    );
  }

  useEffect(() => {
    loadTitles();
  }, [id, page, perPage]); // eslint-disable-line

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div id="titles_table_wrap">
      <div className="align-items-center d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <h4 style={{ margin: '0' }}>Títulos a vencer</h4>
          {totalValue > 0 && (
            <span
              style={{
                background: '#3757a1',
                padding: '4px 8px',
                marginLeft: '16px',
                borderRadius: '4px',
                color: '#fff',
                fontSize: '12px',
                letterSpacing: '0.025em',
              }}
            >
              Total: {normalizeBrl(totalValue)}
            </span>
          )}
        </div>
        <div>
          <Button
            color="default"
            variant="contained"
            className="mr-2"
            type="button"
            disabled={titles.length === 0}
            onClick={() => handlePrint()}
          >
            <FaPrint className="mr-2" />
            Imprimir
          </Button>
          <Button
            color="default"
            variant="contained"
            className="mr-2"
            type="button"
            disabled={titles.length === 0}
            onClick={() => handleCSV()}
          >
            <FaTable className="mr-2" />
            Exportar em Excel
          </Button>
          <Button
            color="primary"
            variant="contained"
            className="mr-2"
            type="button"
            disabled={loading}
            onClick={() => loadTitles()}
          >
            <FaSearch className="mr-2" />
            Filtrar
          </Button>
          {Object.entries(filters).filter(([k, v], i) =>
            k === 'status' ? false : !!v
          ).length > 0 && (
            <Button
              color="default"
              variant="contained"
              type="button"
              onClick={() => {
                setFilters({
                  dataTituStart: '',
                  dataTituEnd: '',
                  sacaId: '',
                  numeDoct: '',
                  valoTitu: '',
                });
                loadTitles(true);
              }}
            >
              <FaTimes className="mr-2" />
              Limpar filtros
            </Button>
          )}
        </div>
      </div>
      <div className="content_component">
        <div className="filters">
          <Row className="mb-2">
            <Col sm={3}>
              <div className="input-group">
                <TextField
                  id="date"
                  type="date"
                  label="De"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filters.dataTituStart}
                  onChange={(e) =>
                    setFilters({ ...filters, dataTituStart: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col sm={3}>
              <div className="input-group">
                <TextField
                  id="date"
                  label="até"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filters.dataTituEnd}
                  onChange={(e) =>
                    setFilters({ ...filters, dataTituEnd: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col sm={2}>
              <div className="input-group">
                <TextField
                  label="CPF/CNPJ Sacado"
                  value={filters.sacaId}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      sacaId: normalizeCpfCnpj(e.target.value),
                    })
                  }
                />
              </div>
            </Col>
            <Col sm={2}>
              <div className="input-group">
                <TextField
                  label="Documento"
                  value={filters.numeDoct}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      numeDoct: e.target.value,
                    })
                  }
                />
              </div>
            </Col>
            <Col sm={2}>
              <div className="input-group">
                <TextField
                  label="Valor"
                  value={filters.valoTitu}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      valoTitu:
                        Number(e.target.value.replace(/[^\d]/g, '')) === 0
                          ? ''
                          : normalizeCurrency(
                              Number(e.target.value.replace(/[^\d]/g, ''))
                            ),
                    })
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
        {loading && <LinearProgress />}
        <Table responsive hover>
          <thead>
            <tr style={{ cursor: 'pointer' }}>
              <th>Título</th>
              <th>Borderô</th>
              <th>Documento</th>
              <th>Vencimento</th>
              <th>Sacado</th>
              <th>Valor</th>
              <th>Situação</th>
              <th>Tipo</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {titles.map((t) => (
              <tr key={t.titu_id}>
                <td>{t.titu_id}</td>
                <td>{t.bord_id}</td>
                <td>{t.nume_doct}</td>
                <td>
                  {format(
                    addMinutes(
                      new Date(t.data_titu),
                      new Date(t.data_titu).getTimezoneOffset()
                    ),
                    'dd/MM/yyyy'
                  )}
                </td>
                <td className="text-left">
                  {t.nome} - {normalizeCpfCnpj(t.saca_id)}
                </td>
                <td className="text-right">{normalizeBrl(t.valo_titu)}</td>
                <td>{t.fili_id > 100 ? 'Cobrança simples' : 'Cobrança'}</td>
                <td>{t.tipo_titu}</td>
                <td>
                  {t.bole &&
                    t.situ_cobr_id !== 'CART' &&
                    t.situ_cobr_id !== 'PROT' &&
                    t.situ_cobr_id !== 'JURI' && (
                      <Tooltip title="Imprimir Boleto">
                        <a
                          className="text-dark"
                          href={`${process.env.REACT_APP_API_FACTA}/boletos?titles=${t.titu_id}`}
                          target="blank"
                          style={{
                            padding: '2px',
                            border: '1px solid #444',
                            borderRadius: '2px',
                          }}
                        >
                          <FaBarcode />
                        </a>
                      </Tooltip>
                    )}
                  {t.situ_cobr_id === 'CART' && <strong>Cartório</strong>}
                  {t.situ_cobr_id === 'PROT' && <strong>Protesto</strong>}
                  {t.situ_cobr_id === 'JURI' && <strong>Jurídico</strong>}
                  {!t.bole &&
                    t.situ_cobr_id !== 'CART' &&
                    t.situ_cobr_id !== 'PROT' &&
                    t.situ_cobr_id !== 'JURI' &&
                    '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="d-flex justify-content-between align-items-center p-2 flex-wrap footer_component">
          <div className="d-flex">
            <small>Total de Registros: {total}</small>
          </div>
          <div>
            Exibir:
            <button
              type="button"
              aria-controls="simple-menu"
              className="ml-4"
              aria-haspopup="true"
              style={{ color: '#444' }}
              onClick={handleClickMenu}
            >
              {perPage} <ExpandMore color="primary" />
            </button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setPage(1);
                  setPerPage(25);
                  setPage(1);
                  handleClose();
                }}
              >
                25
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setPage(1);
                  setPerPage(50);
                  setPage(1);
                  handleClose();
                }}
              >
                50
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setPage(1);
                  setPerPage(100);
                  setPage(1);
                  handleClose();
                }}
              >
                100
              </MenuItem>
            </Menu>
            <button
              type="button"
              disabled={page === 1 || loading}
              onClick={() => setPage(1)}
            >
              <FaAngleDoubleLeft style={{ fontSize: '12px' }} />
            </button>
            <button
              type="button"
              disabled={page === 1 || loading}
              onClick={() => setPage(page - 1)}
            >
              <ArrowBackIos style={{ fontSize: '12px' }} />
            </button>
            <span>
              {page} de {lastPage}
            </span>
            <button
              type="button"
              onClick={() => setPage(page + 1)}
              disabled={lastPage <= page || loading}
            >
              <ArrowForwardIos style={{ fontSize: '12px' }} />
            </button>
            <button
              type="button"
              disabled={page === lastPage || loading}
              onClick={() => setPage(lastPage)}
            >
              <FaAngleDoubleRight style={{ fontSize: '12px' }} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Open);
