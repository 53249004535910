import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import register from './register/reducer';
import anticipation from './anticipation/reducer';
import checkAnticipation from './checkAnticipation/reducer';
import nfseAnticipation from './nfseAnticipation/reducer';

export default combineReducers({
  auth,
  user,
  register,
  anticipation,
  checkAnticipation,
  nfseAnticipation,
});
