import React, { useState, useEffect } from 'react';
import { Checkbox, Button, LinearProgress, TextField } from '@material-ui/core';
import { Description, Warning, Edit, Delete } from '@material-ui/icons';
import { Table, Row, Col } from 'react-bootstrap';
import { format, addHours } from 'date-fns';

import { useDispatch } from 'react-redux';

import Lottie from 'react-lottie';

import { toast } from 'react-toastify';
import animationData from '~/animations/box_empty.json';

import ModalXml from './ModalXml';
import ModalDelete from './ModalDelete';
import ModalDeleteAll from './ModalDeleteAll';
import {
  normalizeCurrency,
  normalizeCpfCnpj,
  normalizeNumber,
} from '../utils/normalize';
import { factaApi } from '~/services/facta';
import { normalizeBrl, payerIsComplete } from '../../utils/normalize';
import { useOperation } from '~/hooks/useOperation';
import ModalEditPayer from './ModalEditPayer';
import ModalEditDuplicate from './ModalEditDuplicate';
import { useAuth } from '~/hooks/useAuth';
import { authApi } from '~/services/auth';

interface IEditDuplicate {
  id: number | null;
  dataTitu: string | null;
  valoTitu: number | null;
  numeDoct: string | null;
  open: boolean;
}

const TableOne: React.FC = () => {
  const { titles: selected, handleTitle, resetOperation } = useOperation();

  const dispatch = useDispatch();
  const [confirmDelete, setConfirmDelete] = useState({
    open: false,
    id: 0,
  });
  const [confirmDeleteAll, setConfirmDeleteAll] = useState({
    open: false,
  });
  const [isSearch, setIsSearch] = useState(false);
  const [titles, setTitles] = useState<Title[]>([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    dataTituStart: '',
    dataTituEnd: '',
    valoTituStart: '',
    valoTituEnd: '',
    chave: '',
    sacaId: '',
  });
  const [updatePayer, setUpdatePayer] = useState({
    open: false,
    title: {} as Title,
  });
  const [editDuplicate, setEditDuplicate] = useState<IEditDuplicate>({
    id: null,
    dataTitu: null,
    valoTitu: null,
    numeDoct: null,
    open: false,
  });
  const [xmlOpen, setXmlOpen] = useState(false);

  const {
    currentCompany: {
      clieId: id,
      edit_data_titu,
      edit_nume_doc,
      edit_valo_titu,
    },
  } = useAuth();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  async function loadData() {
    setLoading(true);
    try {
      const query: string[] = [];
      query.push('type=N');
      if (filters.dataTituStart !== '') {
        query.push(`dataTituStart=${filters.dataTituStart}`);
      }
      if (filters.dataTituEnd !== '') {
        query.push(`dataTituEnd=${filters.dataTituEnd}`);
      }
      if (filters.valoTituStart !== '') {
        query.push(
          `valoTituStart=${parseFloat(
            filters.valoTituStart.split('.').join('').split(',').join('.')
          )}`
        );
      }
      if (filters.valoTituEnd !== '') {
        query.push(
          `valoTituEnd=${parseFloat(
            filters.valoTituEnd.split('.').join('').split(',').join('.')
          )}`
        );
      }
      if (filters.chave !== '') {
        query.push(`chave=${filters.chave}`);
      }
      if (filters.sacaId !== '') {
        query.push(`sacaId=${filters.sacaId.replace(/[^\d]/g, '')}`);
      }
      const { data } = await factaApi.get(
        `/duplicates?${query.map((q) => q).join('&')}`
      );
      setTitles(data);
    } catch (error) {} // eslint-disable-line
    setLoading(false);
  }

  async function handleDelete() {
    setLoading(true);
    try {
      await authApi.delete(`/duplicatas/${confirmDelete.id}`);
      handleTitle(selected.filter((t) => t.id !== confirmDelete.id));
      setTitles(titles.filter((t) => t.id !== confirmDelete.id));
      setConfirmDelete({
        open: false,
        id: 0,
      });
      toast.info('Título excluído');
    } catch (err) {
      if (err.response?.status === 400) {
        toast.error(err.response?.data?.message);
      }
    }
    setLoading(false);
  }

  async function handleDeleteAll() {
    setLoading(true);
    for (let i = 0; i < titles.length; i += 1) {
      const title = titles[i];
      try {
        await authApi.delete(`/duplicatas/${title.id}`);
        handleTitle(selected.filter((t) => t.id !== title.id));
      } catch (err) {} // eslint-disable-line
    }
    setConfirmDeleteAll({
      open: false,
    });
    loadData();
    toast.info('Títulos excluídos');
    setLoading(false);
  }

  useEffect(() => {
    resetOperation();
  }, []); // eslint-disable-line

  function handleAll() {
    handleTitle(titles.filter((t) => payerIsComplete(t)));
  }

  useEffect(() => {
    loadData();
  }, [dispatch, id]); // eslint-disable-line

  return (
    <div id="anticipation_table_wrap">
      <div className="d-flex justify-content-between align-items-center">
        <h4>Antecipar Recebíveis - NF-e</h4>
        <div>
          <Button
            type="button"
            onClick={() => setXmlOpen(true)}
            color="primary"
            variant="contained"
            className="mr-2 text-white"
          >
            Enviar XML's
          </Button>
          <Button
            type="button"
            onClick={() => {
              if (isSearch) {
                loadData();
              } else {
                setIsSearch(true);
              }
            }}
            disabled={loading}
            color="primary"
            variant="outlined"
          >
            {isSearch ? 'Filtrar' : 'Exibir filtro'}
          </Button>
          {!isSearch && titles.length > 0 && (
            <Button
              type="button"
              onClick={() => {
                setConfirmDeleteAll({
                  open: true,
                });
              }}
              disabled={loading}
              className="ml-2"
              color="primary"
              variant="outlined"
            >
              Excluir todos
            </Button>
          )}
        </div>
      </div>
      {isSearch && (
        <Row className="mb-3 mt-0 filters-wrap">
          <Col lg={6} className="mb-2 mt-0">
            <small className="d-block mb-2">Data de Vencimento</small>
            <div className="d-flex align-items-center">
              <TextField
                type="date"
                value={filters.dataTituStart}
                onChange={(e) =>
                  setFilters({ ...filters, dataTituStart: e.target.value })
                }
              />
              <small className="d-block ml-2 mr-2">até</small>
              <TextField
                type="date"
                value={filters.dataTituEnd}
                onChange={(e) =>
                  setFilters({ ...filters, dataTituEnd: e.target.value })
                }
              />
            </div>
          </Col>
          <Col lg={6} className="mb-2 mt-0">
            <small className="d-block mb-2">Valor</small>
            <div className="d-flex align-items-center">
              <TextField
                placeholder="0,00"
                value={filters.valoTituStart}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    valoTituStart: normalizeCurrency(
                      Number(e.target.value.replace(/[^\d]/g, ''))
                    ),
                  })
                }
              />
              <small className="d-block ml-2 mr-2">até</small>
              <TextField
                placeholder="0,00"
                value={filters.valoTituEnd}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    valoTituEnd: normalizeCurrency(
                      Number(e.target.value.replace(/[^\d]/g, ''))
                    ),
                  })
                }
              />
            </div>
          </Col>
          <Col lg={6} className="mb-2 mt-0 d-flex align-items-end">
            <TextField
              label="Chave"
              value={filters.chave}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  chave: normalizeNumber(e.target.value),
                })
              }
            />
          </Col>
          <Col lg={6} className="mb-2 mt-0 d-flex align-items-end">
            <TextField
              label="Cliente (CNPJ/CPF)"
              value={filters.sacaId}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  sacaId: normalizeCpfCnpj(e.target.value),
                })
              }
            />
          </Col>
        </Row>
      )}
      <ModalEditDuplicate
        open={editDuplicate.open}
        id={Number(editDuplicate.id)}
        dataTitu={
          editDuplicate.dataTitu ? String(editDuplicate.dataTitu) : null
        }
        valoTitu={editDuplicate.valoTitu ? editDuplicate.valoTitu : null}
        numeDoct={
          editDuplicate.numeDoct ? String(editDuplicate.numeDoct) : null
        }
        editDataTitu={edit_data_titu}
        editNumeDoc={edit_nume_doc}
        editValoTitu={edit_valo_titu}
        onLoad={() => loadData()}
        setOpen={() =>
          setEditDuplicate({
            id: null,
            dataTitu: null,
            valoTitu: null,
            numeDoct: null,
            open: false,
          })
        }
      />
      <ModalDelete
        open={confirmDelete.open}
        setOpen={() =>
          setConfirmDelete({
            id: 0,
            open: false,
          })
        }
        onConfirm={() => handleDelete()}
      />
      <ModalDeleteAll
        open={confirmDeleteAll.open}
        setOpen={() =>
          setConfirmDeleteAll({
            open: false,
          })
        }
        onConfirm={() => handleDeleteAll()}
      />
      <ModalEditPayer
        open={updatePayer.open}
        onAdd={() => {
          setUpdatePayer({
            open: false,
            title: {} as Title,
          });
          loadData();
        }}
        setOpen={() =>
          setUpdatePayer({
            open: false,
            title: {} as Title,
          })
        }
        note={updatePayer.title}
      />
      <ModalXml
        open={xmlOpen}
        setOpen={() => {
          sessionStorage.setItem('xml_uploaded', '[]');
          setXmlOpen(false);
          loadData();
        }}
      />
      {loading && <LinearProgress />}
      <Table responsive hover>
        <thead>
          <tr>
            <th className="text-left">Cliente</th>
            <th>Chave</th>
            <th>Documento</th>
            <th>Vencimento</th>
            <th>Valor</th>
            <th />
            <th>
              <Checkbox
                checked={selected.length === titles.length}
                onClick={handleAll}
                color="primary"
                disabled={loading}
              />
            </th>
            <th />
            {(edit_data_titu === 1 ||
              edit_nume_doc === 1 ||
              edit_valo_titu === 1) && <th />}
          </tr>
        </thead>
        <tbody>
          {titles.map((n) => {
            const checkedV = selected.find(
              (i) => `${i.chave}${i.nume_doct}` === `${n.chave}${n.nume_doct}`
            );
            return (
              <tr
                key={`${n.chave}${n.nume_doct}`}
                className={checkedV ? 'selected' : ''}
              >
                <td className="text-left">
                  <strong>{n.nome}</strong>
                  <br />
                  <small>{normalizeCpfCnpj(n.saca_id || '')}</small>
                </td>
                <td>{n.chave}</td>
                <td>{n.nume_doct}</td>
                <td>
                  {n.data_titu
                    ? format(addHours(new Date(n.data_titu), 3), 'dd/MM/yyyy')
                    : ''}
                </td>
                <td>{normalizeBrl(n.valo_titu)}</td>
                <td style={{ width: '10px' }}>
                  {!payerIsComplete(n) && (
                    <button
                      type="button"
                      onClick={() =>
                        setUpdatePayer({
                          open: true,
                          title: n,
                        })
                      }
                    >
                      <Warning className="text-warning" />
                    </button>
                  )}
                </td>
                <td
                  style={{ width: '10px' }}
                  onClick={() => {
                    if (!payerIsComplete(n)) {
                      setUpdatePayer({
                        open: true,
                        title: n,
                      });
                    } else if (!loading) {
                      handleTitle(n);
                    }
                  }}
                >
                  <Checkbox checked={!!checkedV} color="primary" />
                </td>
                {(edit_data_titu === 1 ||
                  edit_nume_doc === 1 ||
                  edit_valo_titu === 1) && (
                  <td style={{ width: '20px' }}>
                    <button
                      type="button"
                      onClick={() => {
                        setEditDuplicate({
                          id: Number(n.id),
                          valoTitu: n.valo_titu ? n.valo_titu : null,
                          dataTitu: String(n.data_titu),
                          numeDoct: String(n.nume_doct),
                          open: true,
                        });
                      }}
                    >
                      <Edit
                        style={{
                          fontSize: '16px',
                          color: '#444',
                        }}
                      />
                    </button>
                  </td>
                )}
                <td style={{ width: '20px' }}>
                  <button
                    type="button"
                    onClick={() =>
                      setConfirmDelete({
                        open: true,
                        id: n.id || 0,
                      })
                    }
                  >
                    <Delete className="text-danger" />
                  </button>
                </td>
              </tr>
            );
          })}
          {!loading && titles.length === 0 && (
            <tr style={{ height: '350px' }}>
              <td className="text-center" colSpan={9}>
                <Lottie options={defaultOptions} height={160} width={160} />
                <h4>Nenhuma nota encontrada.</h4>
                <h5>
                  Faça upload de seus XML's ou Digite suas notas para listarmos
                  seus recebíveis disponíveis
                </h5>
                <div className="d-flex justify-content-center mt-3">
                  <Button
                    type="button"
                    startIcon={<Description />}
                    onClick={() => setXmlOpen(true)}
                    color="primary"
                    variant="contained"
                    className="text-white"
                  >
                    Enviar XML's
                  </Button>
                  {/* <Button
                    type="button"
                    startIcon={<Keyboard />}
                    onClick={() => setDigitOpen(true)}
                    color="primary"
                    className="ml-2 text-white"
                    variant="contained"
                  >
                    Digitar Nota
                  </Button> */}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableOne;
