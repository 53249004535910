import React, { memo, useState, useEffect } from 'react';

import {
  LinearProgress,
  TextField,
  Menu,
  MenuItem,
  Button,
} from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos, ExpandMore } from '@material-ui/icons';
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaPrint,
  FaSearch,
  FaTimes,
} from 'react-icons/fa';

import { format, addMinutes } from 'date-fns';

import { useDispatch } from 'react-redux';

import axios from 'axios';
import { toast } from 'react-toastify';

import { Table, Row, Col } from 'react-bootstrap';
import { normalizeCpfCnpj, normalizeCurrency } from '../../utils';
import { signOut } from '~/store/modules/auth/actions';
import IFeePaid from '../../IFeePaid';
import { normalizeBrl } from '~/pages/main/utils/normalize';
import { useAuth } from '~/hooks/useAuth';

interface INextItem {
  (): void;
}

interface IProps {
  handleNextItem: INextItem;
}

const Settled: React.FC<IProps> = ({ handleNextItem }) => {
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [lastPage, setLastPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [filters, setFilters] = useState({
    dataVencStart: '',
    dataVencEnd: '',
    dataPagoStart: '',
    dataPagoEnd: '',
    sacaId: '',
    numeDoct: '',
    valoPago: '',
  });
  const [fees, setFees] = useState<IFeePaid[]>([]);

  const dispatch = useDispatch();

  const {
    currentCompany: { clieId: id },
  } = useAuth();

  async function loadTitles(refresh?: boolean) {
    const query: string[] = [];
    if (!refresh) {
      if (filters.dataVencStart) {
        query.push(`dataVencStart=${filters.dataVencStart}`);
      }
      if (filters.dataVencEnd) {
        query.push(`dataVencEnd=${filters.dataVencEnd}`);
      }
      if (filters.dataPagoStart) {
        query.push(`dataPagoStart=${filters.dataPagoStart}`);
      }
      if (filters.dataPagoEnd) {
        query.push(`dataPagoEnd=${filters.dataPagoEnd}`);
      }
      if (filters.sacaId) {
        query.push(`sacaId=${filters.sacaId.replace(/[^\d]/g, '')}`);
      }
      if (filters.numeDoct) {
        query.push(`numeDoct=${filters.numeDoct}`);
      }
      if (filters.valoPago) {
        query.push(
          `valoPago=${Number(filters.valoPago.replace(/[^\d]/g, '')) / 100}`
        );
      }
    }
    if (page) {
      query.push(`page=${page}`);
    }
    if (perPage) {
      query.push(`perpage=${perPage}`);
    }
    setLoading(true);
    try {
      const { data: dataFees } = await axios.get(
        `${process.env.REACT_APP_API_FACTA}/fees/paid?${query
          .map((i) => i)
          .join('&')}`
      );
      setFees(dataFees.data);
      setLastPage(dataFees.meta.last_page);
      setTotal(dataFees.meta.total);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(signOut());
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    loadTitles();
  }, [id, page, perPage]); // eslint-disable-line

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handlePrint() {
    const query: string[] = [];

    if (filters.dataVencStart) {
      query.push(`dataVencStart=${filters.dataVencStart}`);
    }
    if (filters.dataVencEnd) {
      query.push(`dataVencEnd=${filters.dataVencEnd}`);
    }
    if (filters.dataPagoStart) {
      query.push(`dataPagoStart=${filters.dataPagoStart}`);
    }
    if (filters.dataPagoEnd) {
      query.push(`dataPagoEnd=${filters.dataPagoEnd}`);
    }
    if (filters.sacaId) {
      query.push(`sacaId=${filters.sacaId.replace(/[^\d]/g, '')}`);
    }
    if (filters.numeDoct) {
      query.push(`numeDoct=${filters.numeDoct}`);
    }
    if (filters.valoPago) {
      query.push(
        `valoPago=${Number(filters.valoPago.replace(/[^\d]/g, '')) / 100}`
      );
    }
    query.push(`clieId=${id}`);

    window.open(
      `${process.env.REACT_APP_API_FACTA}/print/fees/paid?${query
        .map((i) => i)
        .join('&')}`,
      '_blank'
    );
  }

  return (
    <div id="titles_table_wrap">
      <div className="align-items-center d-flex justify-content-between">
        <div>
          <h4 className="m-0">Tarifas Liquidadas</h4>
        </div>
        <div>
          <Button
            color="default"
            variant="contained"
            className="mr-2"
            type="button"
            disabled={fees.length === 0}
            onClick={() => handlePrint()}
          >
            <FaPrint className="mr-2" />
            Imprimir
          </Button>
          <Button
            color="primary"
            variant="contained"
            className="mr-2"
            type="button"
            disabled={loading}
            onClick={() => loadTitles()}
          >
            <FaSearch className="mr-2" />
            Filtrar
          </Button>
          {Object.entries(filters).filter(([k, v], i) => !!v).length > 0 && (
            <Button
              color="default"
              variant="contained"
              type="button"
              onClick={() => {
                setFilters({
                  dataVencStart: '',
                  dataVencEnd: '',
                  dataPagoStart: '',
                  dataPagoEnd: '',
                  sacaId: '',
                  numeDoct: '',
                  valoPago: '',
                });
                loadTitles(true);
              }}
            >
              <FaTimes className="mr-2" />
              Limpar filtros
            </Button>
          )}
        </div>
      </div>
      <div className="content_component">
        <div className="filters">
          <Row className="mb-2">
            <Col sm={3} className="mb-2">
              <div className="input-group">
                <TextField
                  id="date"
                  type="date"
                  label="De (Data de vencimento)"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filters.dataVencStart}
                  onChange={(e) =>
                    setFilters({ ...filters, dataVencStart: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col sm={3} className="mb-2">
              <div className="input-group">
                <TextField
                  id="date"
                  label="Até (Data de vencimento)"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filters.dataVencEnd}
                  onChange={(e) =>
                    setFilters({ ...filters, dataVencEnd: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col sm={3} className="mb-2">
              <div className="input-group">
                <TextField
                  id="date"
                  type="date"
                  label="De (Data de liquidação)"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filters.dataPagoStart}
                  onChange={(e) =>
                    setFilters({ ...filters, dataPagoStart: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col sm={3} className="mb-2">
              <div className="input-group">
                <TextField
                  id="date"
                  label="Até (Data de liquidação)"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filters.dataPagoEnd}
                  onChange={(e) =>
                    setFilters({ ...filters, dataPagoEnd: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col sm={6}>
              <div className="input-group">
                <TextField
                  label="CPF/CNPJ Sacado"
                  value={filters.sacaId}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      sacaId: normalizeCpfCnpj(e.target.value),
                    })
                  }
                />
              </div>
            </Col>
            <Col sm={3}>
              <div className="input-group">
                <TextField
                  label="Documento"
                  value={filters.numeDoct}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      numeDoct: e.target.value,
                    })
                  }
                />
              </div>
            </Col>
            <Col sm={3}>
              <div className="input-group">
                <TextField
                  label="Valor"
                  value={filters.valoPago}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      valoPago:
                        Number(e.target.value.replace(/[^\d]/g, '')) === 0
                          ? ''
                          : normalizeCurrency(
                              Number(e.target.value.replace(/[^\d]/g, ''))
                            ),
                    })
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
        {loading && <LinearProgress />}
        <Table responsive hover>
          <thead>
            <tr>
              <th>Nr. Documento</th>
              <th>Vencimento</th>
              <th>Sacado</th>
              <th>Nome</th>
              <th>Vlr. Título</th>
              <th>Tipo</th>
              <th>Dt. Liquidação</th>
            </tr>
          </thead>
          <tbody>
            {fees.map((fee, index) => (
              <tr key={String(index)}>
                <td>{fee.nume_doct}</td>
                <td>
                  {format(
                    addMinutes(
                      new Date(fee.data_titu),
                      new Date(fee.data_titu).getTimezoneOffset()
                    ),
                    'dd/MM/yyyy'
                  )}
                </td>
                <td>{normalizeCpfCnpj(fee.saca_id)}</td>
                <td>{fee.nome}</td>
                <td>{normalizeBrl(fee.valo_pago)}</td>
                <td>{fee.tipo_titu}</td>
                <td>
                  {format(
                    addMinutes(
                      new Date(fee.data_paga),
                      new Date(fee.data_paga).getTimezoneOffset()
                    ),
                    'dd/MM/yyyy'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="d-flex justify-content-between align-items-center p-2 flex-wrap footer_component">
          <div className="d-flex">
            <small>Total de Registros: {total}</small>
          </div>
          <div>
            Exibir:
            <button
              type="button"
              aria-controls="simple-menu"
              className="ml-4"
              aria-haspopup="true"
              style={{ color: '#444' }}
              onClick={handleClickMenu}
            >
              {perPage} <ExpandMore color="primary" />
            </button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setPage(1);
                  setPerPage(25);
                  setPage(1);
                  handleClose();
                }}
              >
                25
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setPage(1);
                  setPerPage(50);
                  setPage(1);
                  handleClose();
                }}
              >
                50
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setPage(1);
                  setPerPage(100);
                  setPage(1);
                  handleClose();
                }}
              >
                100
              </MenuItem>
            </Menu>
            <button
              type="button"
              disabled={page === 1 || loading}
              onClick={() => setPage(1)}
            >
              <FaAngleDoubleLeft style={{ fontSize: '12px' }} />
            </button>
            <button
              type="button"
              disabled={page === 1 || loading}
              onClick={() => setPage(page - 1)}
            >
              <ArrowBackIos style={{ fontSize: '12px' }} />
            </button>
            <span>
              {page} de {lastPage}
            </span>
            <button
              type="button"
              onClick={() => setPage(page + 1)}
              disabled={lastPage <= page || loading}
            >
              <ArrowForwardIos style={{ fontSize: '12px' }} />
            </button>
            <button
              type="button"
              disabled={page === lastPage || loading}
              onClick={() => setPage(lastPage)}
            >
              <FaAngleDoubleRight style={{ fontSize: '12px' }} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Settled);
