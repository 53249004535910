import React, { useState, useEffect } from 'react';

import { Avatar, LinearProgress } from '@material-ui/core';
import { VpnKey, Close } from '@material-ui/icons';
import { format, isPast } from 'date-fns';
import { toast } from 'react-toastify';
import { Container } from './styles';

import AddEcnpj from './components/AddEcnpj';

import { normalizeCnpj } from '../../utils';

import ModalConfirmation from './ModalConfirmation';
import { useAuth } from '~/hooks/useAuth';
import { ecnpjApi } from '~/services/ecnpjApi';

interface INextItem {
  (): void;
}

interface IEcnpj {
  id: number;
  e_cnpj_id: number;
  name: string;
  ext_name: string;
  cnpj: string;
  password: string;
  key: string;
  url: string;
  expires_in: string;
  content_type: string;
}

interface IDeleteEcnpj {
  active: boolean;
  id: null | number;
}

interface IProps {
  handleNextItem: INextItem;
}

const ECnpj: React.FC<IProps> = () => {
  const [loading, setLoading] = useState(false);

  const [ecnpjs, setEcnpjs] = useState<IEcnpj[]>([]);

  const {
    currentCompany: { clieId: id, cnpj },
    signOut,
  } = useAuth();

  const [deleteEcnpj, setDeleteEcnpj] = useState<IDeleteEcnpj>({
    active: false,
    id: null,
  });

  useEffect(() => {
    async function loadEcnpjs() {
      setLoading(true);
      try {
        const { data } = await ecnpjApi.get(`/?cnpj=${cnpj}`);
        setEcnpjs(data);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          signOut();
          toast.error('Sua sessão expirou, entre novamente');
        } else if (err.response && err.response.status === 403) {
          toast.error('Você não está autorizado a acessar este recurso');
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
          );
        } else {
          toast.error('Ocorreu um erro, tente novamente mais tarde');
        }
      }
      setLoading(false);
    }
    loadEcnpjs();
  }, [id, cnpj]); // eslint-disable-line

  async function handleDelete() {
    setLoading(true);
    try {
      await ecnpjApi.delete(`/${deleteEcnpj.id}`);
      setEcnpjs(ecnpjs.filter((e) => e.id !== deleteEcnpj.id));
      setDeleteEcnpj({
        active: false,
        id: null,
      });
    } catch (err) {
      if (err.response && err.response.status === 401) {
        signOut();
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      } else if (err.response && err.response.status === 404) {
        toast.error('ECnpj não encontrado, atualize a tela');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
    setLoading(false);
  }

  return (
    <Container>
      <ModalConfirmation
        open={deleteEcnpj.active}
        setOpen={() => {
          setDeleteEcnpj({
            active: false,
            id: null,
          });
        }}
        loading={loading}
        onDelete={handleDelete}
      />
      <div className="header_component d-flex">
        <h4>e-CNPJ</h4>
      </div>
      <div className="content_component">
        {loading && <LinearProgress className="mb-4" />}
        <p>
          Faça o upload do seu e-CNPJ para analisarmos as últimas
          <strong> Notas Fiscais </strong>
          emitidas e realizar uma análise da
          <strong> Saúde Financeira</strong> da empresa.
        </p>
        <AddEcnpj
          onAdd={(data) => setEcnpjs([...ecnpjs, data])}
          onRefresh={(data) => setEcnpjs(data)}
        />
        {ecnpjs.length > 0 && (
          <div id="ecnpj-list">
            <h6 className="text-center mt-4">e-CNPJ's instalados</h6>
            <div id="grid_ecnpj">
              {ecnpjs.map((e, index) => (
                <div
                  key={e.id}
                  className="d-flex justify-content-between align-items-center list-item"
                >
                  <button
                    type="button"
                    className="button-close"
                    onClick={() => {
                      setDeleteEcnpj({
                        active: true,
                        id: e.id,
                      });
                    }}
                  >
                    <Close />
                  </button>
                  <div className="d-flex align-items-center">
                    <Avatar className="mr-2">
                      <VpnKey />
                    </Avatar>
                    <h5>e-CNPJ #{index + 1}</h5>
                  </div>
                  <div className="text-right">
                    <span className="d-block">{normalizeCnpj(e.cnpj)}</span>
                    {isPast(new Date(e.expires_in)) ? (
                      <span className="d-block text-danger">
                        Expirado desde:{' '}
                        {format(new Date(e.expires_in), 'dd/MM/yyyy')}
                      </span>
                    ) : (
                      <span className="d-block">
                        Expira em:{' '}
                        {format(new Date(e.expires_in), 'dd/MM/yyyy')}
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default ECnpj;
