import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Container } from './styles';

const Faq: React.FC = () => {
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel: string) => {
    setExpanded(expanded !== panel ? panel : '');
  };

  return (
    <Container>
      <div className="row">
        <div className="col-md-12">
          <div className="header_component d-flex">
            <h4>Perguntas frequentes</h4>
          </div>
          <div className="content_component">
            <div className="content_component-faq">
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={() => handleChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>
                    COMO COMEÇAR A UTILIZAR A PLATAFORMA DA BFC DIGITAL?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ul>
                      <li>Primeiro você registra a sua conta;</li>
                      <li>Em seguida, você cadastra a sua empresa;</li>
                      <li>
                        Após isso, você receberá as condições da sua
                        antecipação.
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={() => handleChange('panel2')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography>COMO ABRIR UMA CONTA NA BFC DIGITAL?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      É rápido e fácil. Basta inserir seu e-mail, telefone, CNPJ
                      e definir uma senha.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel3'}
                onChange={() => handleChange('panel3')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography>
                    A PLATAFORMA DA BFC DIGITAL É GRATUITA?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      Sim, o uso da plataforma é 100% gratuito e todas as etapas
                      das operações são transparentes. Apenas as antecipações
                      realizadas geram custo mas esse valor calculado é mostrado
                      a você para a autorização final.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel4'}
                onChange={() => handleChange('panel4')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography>
                    QUAIS VENDAS POSSO ANTECIPAR NA BFC DIGITAL?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      Os recebíveis de notas fiscais cujo pagamento é realizado
                      por boleto com prazo de vencimento entre 5 e 120 dias.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel5'}
                onChange={() => handleChange('panel5')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                >
                  <Typography>
                    O QUE OCORRE SE MEU CLIENTE NÃO PAGAR?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      Entramos em contato com você para solucionarmos
                      conjuntamente.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel6'}
                onChange={() => handleChange('panel6')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6bh-content"
                  id="panel6bh-header"
                >
                  <Typography>
                    QUAL O PRAZO PARA O DINHEIRO ENTRAR NA MINHA CONTA?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      Após assinar o e-mail de confirmação da operação, você
                      receberá o dinheiro na sua conta em até 15 minutos. Esse
                      tempo é válido para operações enviadas antes das 16h em
                      dias úteis.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel7'}
                onChange={() => handleChange('panel7')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel7bh-content"
                  id="panel7bh-header"
                >
                  <Typography>
                    SE EU ENVIAR UMA SOLICITAÇÃO NO FIM DE SEMANA?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      Seu dinheiro será depositado na primeira hora útil após a
                      solicitação.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel8'}
                onChange={() => handleChange('panel8')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel8bh-content"
                  id="panel8bh-header"
                >
                  <Typography>MEU CADASTRO FOI APROVADO! E AGORA?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ul>
                      <li>
                        Faça upload de notas por meio de arquivos .XML na
                        plataforma;
                      </li>
                      <li>
                        Simule a operação e selecione os recebíveis que deseja
                        antecipar
                      </li>
                      <li>
                        Escolha a conta bancária que será utilizada para receber
                        o dinheiro;
                      </li>
                      <li>
                        Confirme a operação pela plataforma e pelo e-mail de
                        confirmação
                      </li>
                      <li>Pronto, aguarde seu dinheiro na conta escolhida.</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel9'}
                onChange={() => handleChange('panel9')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel9bh-content"
                  id="panel9bh-header"
                >
                  <Typography>
                    QUAL É O VALOR MENSAL MÍNIMO E MÁXIMO PARA ANTECIPAR?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      Não existe um valor mínimo, você o escolhe de acordo com a
                      sua necessidade. Porém, um valor máximo é definido para
                      cada cliente a partir dos dados recebidos.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel10'}
                onChange={() => handleChange('panel10')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel10bh-content"
                  id="panel10bh-header"
                >
                  <Typography>QUAL TAXA É COBRADA PELA BFC DIGITAL?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      Apenas a de operação, definida após a fase de análise do
                      cadastro. Caso a solicitação seja aprovada, mantemos a
                      competitividade da nossa taxa, frente ao mercado.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel11'}
                onChange={() => handleChange('panel11')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel11bh-content"
                  id="panel11bh-header"
                >
                  <Typography>
                    POSSO OPERAR IMEDIATAMENTE APÓS CRIAR A MINHA CONTA?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      Analisaremos a sua empresa para liberar capital de giro
                      com base em seus recebíveis. Você enviará suas notas
                      fiscais (arquivo .xml) para calcularmos taxas e limites.
                      Concluída a análise, você receberá um aviso de que poderá
                      antecipar.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel12'}
                onChange={() => handleChange('panel12')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel12bh-content"
                  id="panel12bh-header"
                >
                  <Typography>QUAIS DOCUMENTOS SÃO SOLICITADOS?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ul>
                      <li>
                        Relação de Faturamento (dos últimos 12 meses e assinada
                        pelo contador);
                      </li>
                      <li>
                        Comprovante de Endereço (da empresa e dos sócios);
                      </li>
                      <li>
                        Contrato Social (com a sua eventual última alteração);
                      </li>
                      <li>
                        Identidade e CPF dos sócios (e cônjuges, se houver);
                      </li>
                      <li>Comprovante da Empresa (Inscrição Estadual).</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel13'}
                onChange={() => handleChange('panel13')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel13bh-content"
                  id="panel13bh-header"
                >
                  <Typography>COMO ENVIAR MEUS DOCUMENTOS?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ul>
                      <li>
                        Em CADASTRO no Menu esquerdo você encontra a aba
                        DOCUMENTOS;
                      </li>
                      <li>Faça upload dos documentos e clique em CARREGAR.</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel14'}
                onChange={() => handleChange('panel14')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel14bh-content"
                  id="panel14bh-header"
                >
                  <Typography>COMO ENVIAR MEUS RECEBÍVEIS?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ul>
                      <li>
                        Na aba ANTECIPAÇÃO você encontra o botão CARREGAR Nfe
                        (.xml);
                      </li>
                      <li>
                        Você verá a quantidade de notas válidas e o total de
                        recebíveis carregados;
                      </li>
                      <li>
                        Após o upload, suas contas a receber serão analisadas;
                      </li>
                      <li>
                        Depois de aprovado, você pode iniciar as operações.
                      </li>
                    </ul>
                    <p>
                      PS.: Se não for aprovado automaticamente, envie uma
                      solicitação de aprovação por chat, e-mail ou telefone para
                      o suporte. ;D
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel15'}
                onChange={() => handleChange('panel15')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel15bh-content"
                  id="panel15bh-header"
                >
                  <Typography>
                    QUALQUER RECEBÍVEL PODE SER ANTECIPADO?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>Os recebíveis aparecem em três diferentes status:</p>
                    <ul>
                      <li>
                        EM ANÁLISE indica que o recebível ainda não está
                        autorizado;
                      </li>
                      <li>
                        REJEITADO indica que o recebível não poderá ser
                        antecipado;
                      </li>
                      <li>
                        DISPONÍVEL indica que o recebível já está pronto para
                        ser antecipado.
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel16'}
                onChange={() => handleChange('panel16')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel16bh-content"
                  id="panel16bh-header"
                >
                  <Typography>
                    QUAIS SÃO OS CRITÉRIOS PARA DESCONTAR AS NOTAS?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ul>
                      <li>Estar em formato .xml;</li>
                      <li>Ter prazo de vencimento entre 5 e 120 dias;</li>
                      <li>Não ter sido inserida no sistema anteriormente.</li>
                    </ul>
                    <p>
                      PS.: Em nossa plataforma, você pode conferir na aba NOTAS
                      SEM RECEBÍVEIS as NFs sem vencimento, com uma observação.
                      Quando as notas não forem plausíveis de desconto, elas
                      aparecerão na aba NOTAS INVÁLIDAS. ;D
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel17'}
                onChange={() => handleChange('panel17')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel17bh-content"
                  id="panel17bh-header"
                >
                  <Typography>
                    COMO É CALCULADA A TAXA DE CADA OPERAÇÃO?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      Temos um sistema moderno que identifica automaticamente os
                      dados de cada recebível. Dessa forma, podemos deixar as
                      taxas ainda mais competitivas para os nossos clientes.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel18'}
                onChange={() => handleChange('panel18')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel18bh-content"
                  id="panel18bh-header"
                >
                  <Typography>COMO ANTECIPO MEUS RECEBÍVEIS?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ul>
                      <li>
                        Selecione os recebíveis e os adicione ao carrinho.
                        Clique em CONTINUAR;
                      </li>
                      <li>
                        Escolha os dados da sua conta ou adicione uma nova e
                        clique em CONTINUAR;
                      </li>
                      <li>Assine digitalmente o e-mail que você receberá;</li>
                      <li>Pronto! Você receberá dinheiro na sua conta.</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel19'}
                onChange={() => handleChange('panel19')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel19bh-content"
                  id="panel19bh-header"
                >
                  <Typography>POSSO ACRESCENTAR MAIS CONTAS?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      Sim, você pode adicionar e selecionar novas contas para
                      operar.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel20'}
                onChange={() => handleChange('panel20')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel20bh-content"
                  id="panel20bh-header"
                >
                  <Typography>COMO EMITIR NOVOS BOLETOS?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      Em “Boletos” na plataforma, você poderá atualizar e gerar
                      seus boletos.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel21'}
                onChange={() => handleChange('panel21')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel21bh-content"
                  id="panel21bh-header"
                >
                  <Typography>
                    O QUE OCORRE SE O SACADO NÃO PAGAR O BOLETO?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      Entraremos em contato com você para solucionarmos
                      conjuntamente.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel22'}
                onChange={() => handleChange('panel22')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel22bh-content"
                  id="panel22bh-header"
                >
                  <Typography>
                    COMO CANCELAR UMA NOTA DE UM RECEBÍVEL DESCONTADO?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>Entre em contato conosco através do chat.</p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Faq;
