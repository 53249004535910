import { format, addMinutes } from 'date-fns';
import { IOperation } from '../operation';
import { normalizeBrl, normalizeCpfCnpj } from '../../utils/normalize';

export default function printBordero(operation: IOperation): void {
  const myWindow = window.open('', 'PRINT');

  const cost =
    operation.valo_juro +
    operation.valo_ad_valorem +
    operation.valo_iof +
    operation.valo_cpmf +
    operation.valo_doc +
    operation.valo_tari_cobr +
    operation.valo_tari_post +
    operation.valo_iss +
    operation.valo_tari_digi +
    -operation.valo_desc_taxa;

  const rebuy = operation.valo_desc_tari + operation.valo_desc_titu;

  if (myWindow) {
    let html = '';

    html += `
    <html lang="pt-BR">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>${operation.bord_id}</title>
      </head>
      <style>
        * {
          font-family: sans-serif;
          padding:0;
          margin:0;
        }
        .blue {
          color: #1f2d5b;
        }
        .d-flex {
          display: flex;
        }
        .text-center {
          display: block;
          text-align: center;
        }
        table tbody tr td{
          padding: 5px;
          font-size: 12px;
        }
        table tbody tr:nth-child(odd) {
          background: #eee;
        }
        table thead tr th {
          padding: 10px 5px;
        }
      </style>
      <body style="display: flex; flex-direction: column; justify-content: space-between; min-height: 100vh;">
        <div style="width: 100%;">
        <header>
          <img src="https://app.bfcdigital.com.br/assets/images/logo_email.png" style="display: block; margin:0px auto; width: 65px;"/>
        </header>
        <section style="margin-top: 20px;">
          <div style="width: 100%; background: #eee; padding: 10px 0px;">
            <h2 class="blue text-center">
              Relatório Borderô ${operation.bord_id}
            </h2>
          </div>
          <strong class="text-center" style="margin: 8px 0px; color: #444;">
            Data de Impressão: ${format(new Date(), 'dd/MM/yyyy - HH:mm')}
          </strong>
          <hr />
        </section>
        <section style="margin-top: 16px;">
          <p style="margin-bottom: 8px;">
            Valor de face dos títulos:
            <strong>
              ${normalizeBrl(operation.valo_face || 0)}
            </strong>
          </p>
          <p style="margin-bottom: 8px;">
            Custo/Deságio:
            <strong>
            ${normalizeBrl(parseFloat(cost.toFixed(2)))}
            </strong>
          </p>
          <p style="margin-bottom: 8px;">
            Valor líquido parcial:
            <strong>
            ${normalizeBrl(
              parseFloat((operation.valo_face - cost).toFixed(2)) || 0
            )}
            </strong>
            </p>`;

    if (rebuy > 0) {
      html += `<p style="margin-bottom: 8px;">
                Recompra:
                <strong>${normalizeBrl(
                  parseFloat(rebuy.toFixed(2)) || 0
                )}</strong>
              </p>`;
    }

    if (operation.valo_debi_cred > 0) {
      html += `<p style="margin-bottom: 8px;">
              Pendências Financeiras (+/-):
              <strong>
              ${normalizeBrl(operation.valo_debi_cred || 0)}
              </strong>
            </p>`;
    }
    html += `<p>
            Valor Liquido Final/PAGO:
            <strong>
            ${normalizeBrl(operation.valo_pago_tota || 0)}
            </strong>
          </p>
          <br />
          <br />
          <h4 style="margin-bottom: 10px;">Títulos</h4>
          <hr />
          <table style="width: 100%; border-collapse: collapse;">
            <thead>
              <tr>
                <th style="text-align: left; font-size: 0.8rem;">ID</th>
                <th style="text-align: left; font-size: 0.8rem;">Sacado</th>
                <th style="text-align: left; font-size: 0.8rem;">Documento</th>
                <th style="text-align: left; font-size: 0.8rem;">Vencimento</th>
                <th style="text-align: left; font-size: 0.8rem;">%VF</th>
                <th style="text-align: right; font-size: 0.8rem;">Valor</th>
              </tr>
            </thead>
            <tbody>
              ${operation?.titles
                ?.map((title) => {
                  return `
                  <tr>
                    <td font-size: 0.8rem;>
                      ${title.titu_id}
                    </td>
                    <td font-size: 0.8rem;>
                      ${title.nome}<br />
                      ${normalizeCpfCnpj(title.saca_id)}
                    </td>
                    <td font-size: 0.8rem;>${title.nume_doct}</td>
                    <td font-size: 0.8rem;>
                    ${
                      title.data_titu
                        ? format(
                            addMinutes(
                              new Date(title.data_titu),
                              new Date(title.data_titu).getTimezoneOffset()
                            ),
                            'dd/MM/yyyy'
                          )
                        : '-'
                    }
                    </td>
                    <td style="text-align: right; font-size: 0.8rem;">
                    ${normalizeBrl(
                      parseFloat(
                        (
                          (title.valo_titu_orig / operation.valo_face) *
                          100
                        ).toFixed(2)
                      ) || 0
                    )}
                    </td>
                    <td style="text-align: right; font-size: 0.8rem;">
                    ${normalizeBrl(title.valo_titu_orig || 0)}
                    </td>
                  </tr>
                `;
                })
                .join('')}
                <tr>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>${operation.titles?.length}</td>
                  <td>${normalizeBrl(operation.valo_face || 0)}</td>
                </tr>
            </tbody>
          </table>
        </section>
      </div>
        <footer style="margin-top: 16px; page-break-before:always">
          <div style="display:flex; justify-content:space-between; text-align: left; width: 100%;">
            <div>
            <h5 class="blue">
              BFC Capital Partners
            </h5>
            <p class="blue" style="font-size: 10px;">
              Empresarial Alexandre de Castro e Silva,
              Av. Domingos Ferreira<br /> 2589 - Sala 1001, 10° Andar -
              CEP: 51.020-031 - Boa Viagem, Recife - PE
            </p>
            </div>
            <div class="blue">
              <strong style="display: block; text-align: right;">+55 (81) 4007-2614</strong>
              <strong style="display: block; text-align: right;">bfc@grupobfc.com.br</strong>
            </div>
          </div>
        </footer>
      </body>
      </html>
  `;

    myWindow.document.write(html);
    myWindow.document.close();
    myWindow.focus();
    setTimeout(() => {
      myWindow.print();
      myWindow.close();
    }, 1000);
  }
}
