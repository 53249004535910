import React, { useState, useEffect } from 'react';

import { Form, Row, Col } from 'react-bootstrap';

import { toast } from 'react-toastify';

import { useDispatch } from 'react-redux';

import { isBefore } from 'date-fns';

import { Alert } from '@material-ui/lab';
import { Button, CircularProgress } from '@material-ui/core';
import { factaApi } from '~/services/facta';
import consultCep from '../../../../../services/consultCep';
import {
  normalizeCpfCnpj,
  normalizeCurrency,
  normalizeAccBank,
  normalizeDate,
  normalizeNumber,
  normalizeCep,
  normalizeTelephone,
  normalizeCmc7,
  cnpjIsValid,
  cpfIsValid,
} from '../../utils/normalize';
import { cmc7IsValid } from '../../utils/validation';
import { signOut } from '../../../../../store/modules/auth/actions';
import { Container } from './styles';

interface IProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onAdd: () => void;
}

const ModalTapNote: React.FC<IProps> = ({ open, setOpen, onAdd }) => {
  const [values, setValues] = useState<any>({});
  const [payerExists, setPayerExists] = useState(true);

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const dispatch = useDispatch();

  function resetForm() {
    setValues({});
    setPayerExists(true);
    setOpen(false);
    setError('');
    setSuccess(false);
  }

  const states = [
    { uf: 'AC', desc: 'Acre' },
    { uf: 'AL', desc: 'Alagoas' },
    { uf: 'AP', desc: 'Amapá' },
    { uf: 'AM', desc: 'Amazonas' },
    { uf: 'BA', desc: 'Bahia' },
    { uf: 'CE', desc: 'Ceará' },
    { uf: 'DF', desc: 'Distrito Federal' },
    { uf: 'ES', desc: 'Espírito Santo' },
    { uf: 'GO', desc: 'Goiás' },
    { uf: 'MA', desc: 'Maranhão' },
    { uf: 'MT', desc: 'Mato Grosso' },
    { uf: 'MS', desc: 'Mato Grosso do Sul' },
    { uf: 'MG', desc: 'Minas Gerais' },
    { uf: 'PA', desc: 'Pará' },
    { uf: 'PB', desc: 'Paraíba' },
    { uf: 'PR', desc: 'Paraná' },
    { uf: 'PE', desc: 'Pernambuco' },
    { uf: 'PI', desc: 'Piauí' },
    { uf: 'RJ', desc: 'Rio de Janeiro' },
    { uf: 'RN', desc: 'Rio Grande do Norte' },
    { uf: 'RS', desc: 'Rio Grande do Sul' },
    { uf: 'RO', desc: 'Rondônia' },
    { uf: 'RR', desc: 'Roraima' },
    { uf: 'SC', desc: 'Santa Catarina' },
    { uf: 'SP', desc: 'São Paulo' },
    { uf: 'SE', desc: 'Sergipe' },
    { uf: 'TO', desc: 'Tocantis' },
  ];

  function handleNormalize(name: string, value: string) {
    switch (name) {
      case 'saca_id':
        return normalizeCpfCnpj(value);
      case 'total':
        return normalizeCurrency(Number(value.replace(/[^\d]/g, '')));
      case 'valo_titu':
        return normalizeCurrency(Number(value.replace(/[^\d]/g, '')));
      case 'data_titu':
        return normalizeDate(value);
      case 'emissionDate':
        return normalizeDate(value);
      case 'documentNumber':
        return normalizeAccBank(value);
      case 'serie':
        return normalizeNumber(value);
      case 'cmc7':
        return normalizeCmc7(value);
      case 'cep_id':
        return normalizeCep(value);
      case 'fone':
        return normalizeTelephone(value);
      default:
        return value;
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setError('');
    setSuccess(false);
    const { name, value } = e.target;
    setValues({ ...values, [name]: handleNormalize(name, value) });
  }

  async function handleVerifyPayer() {
    setValues({
      nome: '',
      iden: '',
      cep_id: '',
      ende: '',
      nume: '',
      comp: '',
      bair: '',
      cida: '',
      uf: '',
      fone: '',
      e_mail: '',
    });
    try {
      const { data } = await factaApi.get(
        `payers/${values.saca_id.replace(/[^\d]/g, '').padStart(14, '0')}`
      );
      setValues({
        ...values,
        nome: data.nome,
        saca_id: values.saca_id,
        iden: data.iden,
        cep_id: data.cep_id,
        ende: data.ende,
        nume: data.nume,
        comp: data.comp,
        bair: data.bair,
        cida: data.cida,
        uf: data.uf,
        fone: data.fone,
        e_mail: data.e_mail,
      });
      setPayerExists(true);
    } catch (err) {
      setValues({
        ...values,
        saca_id: values.saca_id,
        nome: '',
        iden: '',
        cep_id: '',
        ende: '',
        nume: '',
        comp: '',
        bair: '',
        cida: '',
        uf: '',
        fone: '',
        e_mail: '',
      });
      setPayerExists(false);
    }
  }

  async function handleCepAddress() {
    const consult = await consultCep(values.cep_id.replace('-', ''));
    if (consult) {
      setValues({
        ...values,
        cep_id: values.cep_id.split('-').join(''),
        ende: consult.logradouro,
        bair: consult.bairro,
        cida: consult.cidade,
        uf: consult.estado,
      });
    }
    document.getElementsByName('nume')[0].focus();
  }

  function parseToDate(value: string) {
    const splitedDate = value.split('/');
    return new Date(`${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`);
  }

  async function handleSubmit(e): Promise<any> {
    e.preventDefault();
    if (
      !values.data_titu ||
      values.data_titu.length < 10 ||
      !isBefore(new Date(), parseToDate(values.data_titu))
    ) {
      return setError('Data do cheque inválida');
    }
    if (!cmc7IsValid(values.cmc7)) {
      return setError('CMC7 Inválido');
    }
    let cmc7OnlyNums = '';
    if (values.cmc7) {
      cmc7OnlyNums = normalizeNumber(values.cmc7);
    }
    if (normalizeNumber(values.saca_id).length === 11) {
      if (!cpfIsValid(normalizeNumber(values.saca_id))) {
        return setError('CPF Inválido');
      }
    } else if (normalizeNumber(values.saca_id).length === 14) {
      if (!cnpjIsValid(normalizeNumber(values.saca_id))) {
        return setError('CNPJ Inválido');
      }
    }
    setLoading(true);
    try {
      const body = {
        nume_doct: values.cmc7 && cmc7OnlyNums.slice(11, 17),
        data_titu: parseToDate(values.data_titu),
        valo_titu: Number(values.valo_titu.replace(/[^\d]/g, '')) / 100,
        cmc7: cmc7OnlyNums,
        nume_banc: values.cmc7 && cmc7OnlyNums.slice(0, 3),
        nume_cont: values.cmc7 && cmc7OnlyNums.slice(19, 30),
        nume_agen: values.cmc7 && cmc7OnlyNums.slice(3, 7),
        nome: values.nome,
        saca_id: values.saca_id.replace(/[^\d]/g, ''),
        iden: values.iden,
        cep_id: values.cep_id,
        ende: values.ende,
        nume: values.nume,
        comp: values.comp,
        bair: values.bair,
        cida: values.cida,
        uf: values.uf,
        fone: values.fone ? values.fone.replace(/[^\d]/g, '') : '',
        e_mail: values.e_mail,
      };
      await factaApi.post(`checks`, body);
      const emptyValues: any = {};
      Object.keys(values).forEach((i) => {
        emptyValues[i] = '';
      });
      setValues(emptyValues);
      setPayerExists(true);
      setSuccess(true);
      onAdd();
      setRefreshing(true);
    } catch (err) {
      if (err.response && err.response.status === 403) {
        setError(err.response.data.message);
      } else if (err.response && err.response.status === 401) {
        toast.error('Sessão expirada, entre novamente');
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        setError(
          'Ocorreu um erro em nossos servidores, contate a equipe técnica'
        );
      } else {
        setError(err.response.data.message);
      }
    }
    return setLoading(false);
  }

  useEffect(() => {
    if (refreshing) {
      setTimeout(() => {
        setRefreshing(false);
      }, 500);
    }
  }, [refreshing]);

  return (
    <Container size="lg" show={open} onHide={resetForm} centered>
      {!refreshing && (
        <Form onSubmit={handleSubmit}>
          <Container.Body>
            {error !== '' && <Alert severity="error">{error}</Alert>}
            {success && (
              <Alert severity="success">Cheque adicionado com sucesso</Alert>
            )}
            <small>Dados do Sacado</small>
            <Row>
              <Col lg={4}>
                <Form.Group>
                  <Form.Label>CNPJ/CPF</Form.Label>
                  <Form.Control
                    name="saca_id"
                    value={values.saca_id}
                    onChange={handleChange}
                    disabled={loading}
                    onBlur={handleVerifyPayer}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={8}>
                <Form.Group>
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    name="nome"
                    value={values.nome}
                    onChange={handleChange}
                    disabled={payerExists || loading}
                    required
                  />
                </Form.Group>
              </Col>
              {!payerExists && (
                <>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>RG/CGF</Form.Label>
                      <Form.Control
                        name="iden"
                        value={values.iden}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>CEP</Form.Label>
                      <Form.Control
                        name="cep_id"
                        value={values.cep_id}
                        onChange={handleChange}
                        disabled={loading}
                        onBlur={handleCepAddress}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label>Endereço</Form.Label>
                      <Form.Control
                        name="ende"
                        value={values.ende}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Form.Group>
                      <Form.Label>Número</Form.Label>
                      <Form.Control
                        name="nume"
                        value={values.nume}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Form.Group>
                      <Form.Label>Complemento</Form.Label>
                      <Form.Control
                        name="comp"
                        value={values.comp}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>Bairro</Form.Label>
                      <Form.Control
                        name="bair"
                        value={values.bair}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        name="cida"
                        value={values.cida}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Form.Group>
                      <Form.Label>UF</Form.Label>
                      <Form.Control
                        name="uf"
                        as="select"
                        value={values.uf}
                        onChange={handleChange}
                        disabled={loading}
                      >
                        <option />
                        {states.map((s) => (
                          <option key={s.uf} value={s.uf}>
                            {s.desc}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label>Telefone</Form.Label>
                      <Form.Control
                        name="fone"
                        value={values.fone}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={8}>
                    <Form.Group>
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        type="email"
                        name="e_mail"
                        value={values.e_mail}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>
            <br />
            <small>Dados do Cheque</small>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label>CMC7</Form.Label>
                  <Form.Control
                    name="cmc7"
                    value={values.cmc7}
                    onChange={handleChange}
                    disabled={loading}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Vencimento</Form.Label>
                  <Form.Control
                    name="data_titu"
                    value={values.data_titu}
                    onChange={handleChange}
                    disabled={loading}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Valor Total</Form.Label>
                  <Form.Control
                    name="valo_titu"
                    value={values.valo_titu}
                    onChange={handleChange}
                    disabled={loading}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Banco</Form.Label>
                  <Form.Control
                    name="bank"
                    value={values.cmc7 && values.cmc7.slice(1, 4)}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Documento</Form.Label>
                  <Form.Control
                    name="doc_check"
                    value={values.cmc7 && values.cmc7.slice(13, 19)}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Agência</Form.Label>
                  <Form.Control
                    name="agency"
                    value={values.cmc7 && values.cmc7.slice(4, 8)}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Conta</Form.Label>
                  <Form.Control
                    name="account"
                    value={values.cmc7 && values.cmc7.slice(21, 32)}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container.Body>
          <Container.Footer>
            <div className="d-flex justify-content-between">
              {loading ? <CircularProgress /> : <span />}
              <div>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={loading}
                >
                  Enviar
                </Button>
                <Button
                  type="button"
                  color="primary"
                  variant="outlined"
                  disabled={loading}
                  onClick={resetForm}
                  className="ml-2"
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </Container.Footer>
        </Form>
      )}
    </Container>
  );
};

export default ModalTapNote;
