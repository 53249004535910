import axios from 'axios';

const api = axios.create({
  baseURL: `https://viacep.com.br/ws`,
});

interface IReturn {
  estado: string;
  logradouro: string;
  bairro: string;
  cidade: string;
  numero: string;
  complemento: string;
}

export default async function consultCep(cep: string): Promise<IReturn> {
  const { data } = await api.get(`/${cep.replace(/\D/g, '')}/json`);
  if (!data.erro) {
    const { uf: estado, logradouro, bairro, localidade: cidade } = data;
    return {
      estado,
      logradouro,
      bairro,
      cidade,
      numero: '',
      complemento: '',
    };
  }
  return {
    estado: '',
    logradouro: '',
    bairro: '',
    cidade: '',
    numero: '',
    complemento: '',
  };
}
