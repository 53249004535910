import React from 'react';

import { useHistory } from 'react-router-dom';
import { Container } from './styles';

import Geral from './components/Geral';
import ECnpj from './components/ECnpj';
import Bank from './components/Bank';
import Member from './components/Member';
import Documents from './components/Documents';
import Contract from './components/Contract';

const Register: React.FC = ({ match }: any) => {
  const current = String(match.params.current);

  const history = useHistory();

  const handleNextItem = (actual: string) => {
    history.push(`/cadastro/${actual}`);
  };

  return (
    <Container>
      <div className="row">
        <div className="col-md-12">
          {current === 'informacao-geral' && (
            <Geral handleNextItem={() => handleNextItem('informacao-geral')} />
          )}
          {current === 'ecnpj' && (
            <ECnpj handleNextItem={() => handleNextItem('ecnpj')} />
          )}
          {current === 'contas-bancarias' && (
            <Bank handleNextItem={() => handleNextItem('contas-bancarias')} />
          )}
          {current === 'socios' && (
            <Member handleNextItem={() => handleNextItem('socios')} />
          )}
          {current === 'documentos' && (
            <Documents handleNextItem={() => handleNextItem('documentos')} />
          )}
          {current === 'contrato' && (
            <Contract handleNextItem={() => handleNextItem('contrato')} />
          )}
        </div>
      </div>
    </Container>
  );
};

export default Register;
