import styled from 'styled-components';

import { darken, lighten } from 'polished';

export const Container = styled.div`
  padding: 15px 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  div.header_component {
    border-bottom: 1px solid #eee;
    width: 100%;
  }
  div.content_component {
    width: 100%;
    #contract_list {
      margin-top: 26px;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 26px;
      > div {
        padding: 20px;
        border-bottom: 4px solid ${(props) => props.theme.colors.primary};
        border-radius: 4px;
        background: ${(props) => darken(0.03, props.theme.colors.background)};
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        h4 {
          font-weight: 800;
          color: ${(props) => lighten(0.1, props.theme.colors.text)};
        }
        div.controls {
          margin-top: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          a {
            background: ${(props) => props.theme.colors.primary};
            padding: 10px 15px;
            color: ${(props) => props.theme.colors.background};
            border-radius: 4px;
            display: block;
            width: 100%;
            max-width: 200px;
            text-align: center;
            svg {
              margin-right: 6px;
            }
          }
          div.status {
            svg {
              margin-right: 8px;
            }
          }
        }
      }
    }
    > .incomplete-registration {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 180px 0 150px;
    }
    > .complete-registration {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 26px;
      > div {
        position: relative;
        padding: 25px;
        background: ${(props) => darken(0.04, props.theme.colors.background)};
        border: 2px dashed ${(props) => props.theme.colors.primary};
        overflow: hidden;
        div.status {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          top: 50px;
          left: 25px;
          width: calc(100% - 50px);
          height: calc(100% - 120px);
          text-align: center;
          transition: 300ms;
          &:hover {
            background: rgba(255, 255, 255, 0.7);
            svg {
              opacity: 0.2;
            }
            span {
              opacity: 1;
            }
          }
          svg {
            transition: 300ms;
            font-size: 90px;
          }
          span {
            position: absolute;
            transition: 300ms;
            font-size: 32px;
            opacity: 0;
          }
        }
        h4 {
          text-align: center;
          font-size: 14px;
          color: ${(props) => props.theme.colors.text};
          text-transform: uppercase;
        }
        div.controls {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
          div {
            color: #666;
          }
          a {
            display: flex;
            align-items: center;
            text-decoration: none !important;
            padding: 5px 10px;
            border-radius: 4px;
            border: 1px solid ${(props) => props.theme.colors.primary};
            color: ${(props) => props.theme.colors.primary};
            transition: 300ms;
            &:hover {
              background: ${(props) => props.theme.colors.primary};
              color: #fff;
            }
            svg {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
`;
