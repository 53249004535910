import styled from 'styled-components';

import { Modal } from 'react-bootstrap';
import { darken } from 'polished';

export const Container = styled(Modal)`
  .modal-content {
    background: none !important;
    box-shadow: none !important;
    border: none !important;
  }
  #header_summary {
    padding: 20px 35px;
    div + div {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
    }
    div {
      h5 {
        color: ${(props) => darken(0.04, props.theme.colors.text)};
        text-align: right;
        padding: 10px;
        padding-left: 20px;
        margin: 0px;
        font-size: 16px;
      }
      h5 + h5 {
        border-top: 2px solid #c9c9c9;
        color: ${(props) => props.theme.colors.primary};
      }
      .status_operation {
        padding: 10px;
        padding-left: 15px;
        border-left: 4px solid
          ${(props) => darken(0.06, props.theme.colors.primary)};
      }
      button {
        margin-top: 10px;
        border: 1px solid #c9c9c9;
        padding: 5px 15px;
        text-transform: uppercase;
        color: #444;
        font-size: 14px;
      }
    }
  }
  #body_content {
    padding: 35px;
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 25px;
    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr;
    }
    table.table {
      margin-bottom: none !important;
      * {
        font-size: 12px;
      }
    }
    div#history {
      padding: 20px;
      border-radius: 4px;
      background: ${(props) => darken(0.03, props.theme.colors.background)};
      border: 1px solid
        ${(props) => darken(0.08, props.theme.colors.background)};
      height: min-content;
    }
  }
  #footer_content {
    padding: 45px 35px;
    margin-top: 16px;
    background: #1e3c84;
    color: ${(props) => props.theme.colors.background};
    svg.mr-4 {
      font-size: 100px;
    }
    #text_footer {
      width: 100%;
      max-width: 700px;
    }
    border-radius: 6px;
  }
`;
