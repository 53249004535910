import React, { useEffect, useMemo, useState } from 'react';

import { MonetizationOnOutlined, CloseOutlined } from '@material-ui/icons';
import {
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  InputLabel,
  Select,
  LinearProgress,
} from '@material-ui/core';

import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { banks as bankOptions } from './BankSelect';

import { signOut } from '~/store/modules/auth/actions';

import ModalConclued from './ModalConclued';
import ModalConfirm from './ModalConfirm';
import { useOperation } from '~/hooks/useOperation';
import { normalizeBrl } from '../../utils/normalize';
import { factaApi } from '~/services/facta';

const BoxFloat: React.FC = () => {
  const dispatch = useDispatch();
  const { titles, handleCheck, bankSelected, selectBank } = useOperation();
  const [openConclued, setOpenConclued] = useState(false);
  const [expand, setExpand] = useState<boolean>(false);
  const [confirm, setConfirm] = useState(false);

  const [banks, setBanks] = useState<Bank[]>([]);
  const [loading, setLoading] = useState(false);

  const total = useMemo(() => {
    let amountTotal = 0;
    titles.forEach((n) => {
      amountTotal += Number(n.valo_titu || 0);
    });
    return amountTotal;
  }, [titles]);

  useEffect(() => {
    async function loadBanks() {
      try {
        const { data } = await factaApi.get('/banks');
        const banks_d: Bank[] = []; // eslint-disable-line
        data.forEach((i: any) => {
          banks_d.push(i);
        });
        setBanks(banks_d);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          dispatch(signOut());
          toast.error('Sua sessão expirou, entre novamente');
        } else if (err.response && err.response.status === 403) {
          toast.error('Você não está autorizado a acessar este recurso');
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
          );
        } else {
          toast.error('Ocorreu um erro, tente novamente mais tarde');
        }
      }
    }
    loadBanks();
  }, [dispatch]);

  useEffect(() => {
    if (titles.length === 0) {
      setExpand(false);
    }
  }, [titles, dispatch]); // eslint-disable-line

  async function handleSubmitConfirm() {
    setLoading(true);
    try {
      const { data: dataBank } = await factaApi.get(`banks/${bankSelected}`);

      const body = {
        nume_agen: dataBank.nume_agen,
        nume_banc: dataBank.nume_banc,
        nume_cont: dataBank.nume_cont,
        titles: titles.map((t) => {
          return {
            id: t.id,
            cmc7: t.cmc7,
            nume_agen: t.nume_agen,
            nume_banc: t.nume_banc,
            nume_cont: t.nume_cont,
            saca_id: t.saca_id,
            nume_doct: t.nume_doct,
            tipo_titu: 'C',
            valo_titu: t.valo_titu,
            data_titu: t.data_titu ? t.data_titu.slice(0, 10) : '',
          };
        }),
      };
      await factaApi.post(`sendings`, body);
      // dispatch(resetAnticipation);
      setOpenConclued(true);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(signOut());
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 400) {
        if (err.response.data && err.response.data.message) {
          toast.warn(err.response.message, { autoClose: false });
        } else {
          toast.error(err.response.message);
        }
        // loadOperation();
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error(err.response.data.message);
      }
    }
    setLoading(false);
  }

  return (
    <div id="antecipate_box" className={titles.length > 0 ? 'open' : ''}>
      <ModalConfirm
        total={normalizeBrl(total)}
        bank={banks.find((b) => b.nume_cont === bankSelected)}
        onConfirm={() => {
          handleSubmitConfirm();
        }}
        open={confirm}
        setOpen={() => setConfirm(false)}
      />
      <ModalConclued open={openConclued} setOpen={(e) => setOpenConclued(e)} />
      <div className="antecipate_box__container">
        <div>
          <h3> {titles.length} Cheque (s) Selecionado(s)</h3>
          <span
            style={{
              fontSize: '14px',
            }}
          >
            Total parcial: {normalizeBrl(total)}
          </span>
        </div>
        <div>
          <Button
            type="button"
            endIcon={<MonetizationOnOutlined />}
            color="primary"
            variant="outlined"
            onClick={() => {
              setExpand(!expand);
            }}
          >
            {expand ? 'Voltar' : 'Antecipar'}
          </Button>
        </div>
      </div>
      {expand && (
        <div
          className={`container ${expand ? 'opened' : 'closed'}`}
          id="expanded"
        >
          {loading && <LinearProgress />}
          <div id="grid_detail">
            <div>
              <p>Cheques a antecipar</p>
              <div className="list-items">
                {titles.length > 0 &&
                  titles.map((title, index) => (
                    <div className="item" key={String(index)}>
                      <div>
                        <p>{title.nome}</p>
                        <h4>{normalizeBrl(title.valo_titu)}</h4>
                        <div className="d-flex justify-content-between align-items-center">
                          <span>{title.cmc7}</span>
                          <button
                            type="button"
                            onClick={() => handleCheck(title)}
                            disabled={loading}
                          >
                            <CloseOutlined />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div>
              <FormLabel component="legend">Contas Bancárias:</FormLabel>
              {banks.length === 0 ? (
                <h4>Nenhum banco cadastrado</h4>
              ) : (
                <FormControl className="mb-2" component="fieldset">
                  <FormControl>
                    <InputLabel id="demo-simple-select-helper-label">
                      Selecionar Conta Bancária
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={bankSelected}
                      onChange={(e) =>
                        selectBank((e.target.value as string) || '')
                      }
                    >
                      {banks.map((bank) => (
                        <MenuItem key={bank.nume_cont} value={bank.nume_cont}>
                          <span>
                            Banco:{' '}
                            {bankOptions.find((b) => {
                              if (b.slice(0, 3) === bank.nume_banc) {
                                return b;
                              }
                              return false;
                            }) || bank.nume_banc}
                            <br />
                            Ag: {bank.nume_agen}
                            {bank.digi_agen && `-${bank.digi_agen}`}
                            <br />
                            Cc: {bank.nume_cont}-{bank.digi_cont}
                          </span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormControl>
              )}
              <div id="total_antecipated" className="mt-5">
                <p
                  style={{
                    color: '#585858',
                    fontSize: '14px',
                  }}
                >
                  Resumo da Operação:
                </p>
                <div>
                  <span>Total</span>
                  <strong>{normalizeBrl(total)}</strong>
                </div>
              </div>
              <hr />
              <Button
                type="button"
                className="mt-4"
                disabled={
                  bankSelected === '' ||
                  !bankSelected ||
                  titles.length === 0 ||
                  loading
                }
                color="primary"
                variant="contained"
                onClick={() => setConfirm(true)}
                style={{ width: '100%' }}
              >
                Enviar Operação
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BoxFloat;
