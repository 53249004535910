import React, { memo, useState, useEffect } from 'react';

import {
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  Button,
  LinearProgress,
} from '@material-ui/core';

import { PlusOneRounded, Close } from '@material-ui/icons';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import { toast } from 'react-toastify';
import consultCep from '../../../../../../services/consultCep';
import { signOut } from '../../../../../../store/modules/auth/actions';
import StateSelect from './StateSelect';
import { validateMemberPJ } from '../utils/memberPJValidation';

import {
  normalizeCnpj,
  normalizeTelephone,
  normalizeCep,
} from '../../../utils';

interface IErrorMemberPJ {
  companyName: string;
  fantasyName: string;
  cnpj: string;
  inscState: string;
  inscStateValue: string;
  address: {
    logradouro: string;
    numero: string;
    complemento: string;
    cep: string;
    estado: string;
    cidade: string;
    bairro: string;
  };
  telephones: number[];
}

interface IMember {
  id: string;
  docType: string;
  type: string;
  name: string;
  document: string;
}

interface IFunc {
  (data: IMember): void;
}

interface propsInt {
  resetForm: any;
  id: string | null;
  editMember: IFunc;
  status:
    | 'Incompleto'
    | 'Em análise'
    | 'Aguardando geração do contrato'
    | 'Aguardando assinatura do contrato'
    | 'Em operação';
}

interface IMemberType {
  id: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

const NewMemberPJ: React.FC<propsInt> = ({
  resetForm,
  id,
  editMember,
  status,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [memberTypes, setMemberTypes] = useState<IMemberType[]>([]);
  const [memberType, setMemberType] = useState<string | undefined | unknown>(
    ''
  );

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const history = useHistory();

  const [companyName, setCompanyName] = useState('');
  const [fantasyName, setFantasyName] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [telephones, setTelephones] = useState(['']);
  const [inscState, setInscState] = useState('isent');
  const [inscStateValue, setInscStateValue] = useState('');
  const [address, setAddress] = useState({
    logradouro: '',
    numero: '',
    complemento: '',
    cep: '',
    estado: '',
    cidade: '',
    bairro: '',
  });

  const [errorsPJ, setErrorsPJ] = useState<IErrorMemberPJ>({
    companyName: '',
    fantasyName: '',
    cnpj: '',
    inscState: '',
    inscStateValue: '',
    address: {
      logradouro: '',
      numero: '',
      complemento: '',
      cep: '',
      estado: '',
      cidade: '',
      bairro: '',
    },
    telephones: [],
  });

  const resetInfos = () => {
    setCnpj('');
    setFantasyName('');
    setTelephones(['']);
    setInscState('isent');
    setInscStateValue('');
    setAddress({
      logradouro: '',
      numero: '',
      complemento: '',
      cep: '',
      estado: '',
      cidade: '',
      bairro: '',
    });
    setErrorsPJ({
      companyName: '',
      fantasyName: '',
      cnpj: '',
      inscState: '',
      inscStateValue: '',
      address: {
        logradouro: '',
        numero: '',
        complemento: '',
        cep: '',
        estado: '',
        cidade: '',
        bairro: '',
      },
      telephones: [],
    });
    resetForm();
  };

  useEffect(() => {
    async function loadMemberTypes() {
      try {
        const { data } = await axios.get(`/partners/member-types`);
        setMemberTypes(data.filter((type) => type.visible));
      } catch (err) {
        if (err.response && err.response.status === 401) {
          dispatch(signOut());
          toast.error('Sua sessão expirou, entre novamente');
        } else if (err.response && err.response.status === 403) {
          toast.error('Você não está autorizado a acessar este recurso');
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
          );
        } else {
          toast.error('Ocorreu um erro, tente novamente mais tarde');
        }
      }
    }
    loadMemberTypes();
  }, [dispatch]);

  async function handleSubmit() {
    const { count_errors, errors } = validateMemberPJ({
      companyName,
      fantasyName,
      cnpj,
      inscState,
      inscStateValue,
      address: {
        logradouro: address.logradouro,
        numero: address.numero,
        complemento: address.complemento,
        cep: address.cep,
        estado: address.estado,
        cidade: address.cidade,
        bairro: address.bairro,
      },
    });
    const telephones_error: number[] = [];
    telephones.forEach((p, ind) => p.length < 14 && telephones_error.push(ind));

    if (count_errors > 0 || telephones_error.length > 0) {
      return setErrorsPJ({
        ...errorsPJ,
        ...errors,
        telephones: telephones_error,
      });
    }
    setLoading(true);

    const memberTypeIds: string[] = [];

    // TODO:
    // editMemberPF.memberTypes.filter((type: any) => !type.visible).forEach((type: any) => memberTypeIds.push(type.id as string))

    // memberTypeIds.push(memberType as string)

    try {
      await axios.put(`/partners/juridical-persons/${id}`, {
        companyName,
        fantasyName,
        telephones,
        memberTypeIds,
        stateRegistration: inscState === 'required' ? inscStateValue : '',
        address: {
          street: address.logradouro,
          number: address.numero,
          complement: address.complemento,
          cep: address.cep,
          state: address.estado,
          city: address.cidade,
          district: address.bairro,
        },
      });
      const mbType = memberTypes.find((i) => i.id === memberType);
      if (!mbType) {
        resetForm();
      } else if (id) {
        editMember({
          docType: 'PJ',
          document: cnpj,
          id,
          name: companyName,
          type: mbType.type,
        });
        resetForm();
        if (urlParams.get('pendency') === '1') {
          history.push(
            `cadastro/documentos?pendency=1&doc=${urlParams.get(
              'doc'
            )}&type=pj&id=${urlParams.get('id')}`
          );
        }
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(signOut());
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const { data } = await axios.get(`/partners/juridical-persons/${id}`);
        setCnpj(data.cnpj);
        setCompanyName(data.companyName || '');
        setFantasyName(data.fantasyName || '');
        setMemberType(data.memberTypes[0].id);
        setAddress({
          bairro: data.address.district,
          cep: data.address.cep,
          cidade: data.address.city,
          complemento: data.address.complement,
          estado: data.address.state,
          logradouro: data.address.street,
          numero: data.address.number,
        });
        setTelephones(data.telephones || ['']);
        setInscState(data.stateRegistration === '' ? 'isent' : 'required');
        setInscStateValue(data.stateRegistration);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          dispatch(signOut());
          toast.error('Sua sessão expirou, entre novamente');
        } else if (err.response && err.response.status === 403) {
          toast.error('Você não está autorizado a acessar este recurso');
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
          );
        } else {
          toast.error('Ocorreu um erro, tente novamente mais tarde');
        }
      }
      setLoading(false);
    }
    loadData();
  }, [dispatch, id]);

  async function handleCep() {
    if (address.cep.length === 9) {
      const consult = await consultCep(address.cep);

      if (consult) {
        setAddress({
          ...address,
          ...consult,
        });
      }
    }
  }

  return (
    <>
      <div className="content_component">
        {loading && <LinearProgress />}
        <form>
          <FormControl
            disabled={
              loading || (status !== 'Incompleto' && status !== 'Em análise')
            }
            style={{ width: '100%' }}
            className="mb-5"
          >
            <InputLabel>Tipo de Sócio</InputLabel>
            <Select
              disabled={
                loading || (status !== 'Incompleto' && status !== 'Em análise')
              }
              value={memberType}
              onChange={(e) => setMemberType(e.target.value)}
              style={{ width: '100%' }}
            >
              {memberTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <fieldset>
            <h4>Dados Cadastrais</h4>
            <div className="row">
              <div className="col-md-4">
                <TextField
                  label="CNPJ"
                  value={normalizeCnpj(cnpj)}
                  disabled
                  style={{ width: '100%' }}
                  required
                  error={errorsPJ.cnpj !== ''}
                  helperText={errorsPJ.cnpj}
                />
              </div>
              <div className="col-md-4">
                <TextField
                  label="Razão Social"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  style={{ width: '100%' }}
                  required
                  error={errorsPJ.companyName !== ''}
                  helperText={errorsPJ.companyName}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                />
              </div>
              <div className="col-md-4">
                <TextField
                  label="Nome fantasia"
                  value={fantasyName}
                  onChange={(e) => setFantasyName(e.target.value)}
                  style={{ width: '100%' }}
                  required
                  error={errorsPJ.fantasyName !== ''}
                  helperText={errorsPJ.fantasyName}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-3">
                <div style={{ width: '100%' }}>
                  {telephones.length > 0 &&
                    telephones.map((item, index) => {
                      const error_item = errorsPJ.telephones.find(
                        (i) => i === index
                      );
                      return (
                        <div
                          className="d-flex"
                          key={index}
                          style={{ width: '100%' }}
                        >
                          <TextField
                            label="Telefone"
                            value={item}
                            style={{ width: '100%' }}
                            onChange={(e) => {
                              const telephones_lock = [...telephones];
                              telephones_lock[index] = normalizeTelephone(
                                e.target.value
                              );
                              setTelephones(telephones_lock);
                            }}
                            required
                            error={error_item !== undefined}
                            helperText={
                              error_item !== undefined
                                ? 'Preencha esse campo'
                                : ''
                            }
                            disabled={
                              loading ||
                              (status !== 'Incompleto' &&
                                status !== 'Em análise')
                            }
                          />
                          {index > 0 && (
                            <button
                              type="button"
                              className="btn btn-sm"
                              disabled={
                                loading ||
                                (status !== 'Incompleto' &&
                                  status !== 'Em análise')
                              }
                              onClick={() =>
                                setTelephones(
                                  telephones.filter((_, ind) => ind !== index)
                                )
                              }
                            >
                              <Close />
                            </button>
                          )}
                        </div>
                      );
                    })}
                </div>
                <button
                  type="button"
                  className="btn"
                  onClick={() => setTelephones([...telephones, ''])}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                >
                  <PlusOneRounded />
                </button>
              </div>
              <div className="col-md-4 mt-3">
                <FormLabel component="legend">Inscrição Estadual</FormLabel>
                <RadioGroup
                  value={inscState}
                  onChange={(e) => setInscState(e.target.value)}
                >
                  <div className="d-flex">
                    <FormControlLabel
                      value="isent"
                      control={
                        <Radio
                          color="primary"
                          disabled={
                            loading ||
                            (status !== 'Incompleto' && status !== 'Em análise')
                          }
                        />
                      }
                      label="Isento"
                    />
                    <FormControlLabel
                      value="required"
                      control={
                        <Radio
                          color="primary"
                          disabled={
                            loading ||
                            (status !== 'Incompleto' && status !== 'Em análise')
                          }
                        />
                      }
                      label="Obrigatório"
                    />
                  </div>
                </RadioGroup>
                {inscState === 'required' && (
                  <TextField
                    label="Obrigatório"
                    className="d-block"
                    value={inscStateValue}
                    onChange={(e) => setInscStateValue(e.target.value)}
                    error={errorsPJ.inscStateValue !== ''}
                    helperText={errorsPJ.inscStateValue}
                    required
                    disabled={
                      loading ||
                      (status !== 'Incompleto' && status !== 'Em análise')
                    }
                  />
                )}
              </div>
            </div>
          </fieldset>
          <fieldset>
            <h4>Endereço de Registro</h4>
            <div className="row">
              <div className="col-md-3 mt-2">
                <TextField
                  label="CEP"
                  value={address.cep}
                  required
                  onChange={(e) =>
                    setAddress({
                      ...address,
                      cep: normalizeCep(e.target.value),
                    })
                  }
                  onBlur={handleCep}
                  error={errorsPJ.address.cep !== ''}
                  helperText={errorsPJ.address.cep}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Logradouro"
                  value={address.logradouro}
                  required
                  onChange={(e) =>
                    setAddress({ ...address, logradouro: e.target.value })
                  }
                  error={errorsPJ.address.logradouro !== ''}
                  helperText={errorsPJ.address.logradouro}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Número"
                  value={address.numero}
                  required
                  onChange={(e) =>
                    setAddress({ ...address, numero: e.target.value })
                  }
                  error={errorsPJ.address.numero !== ''}
                  helperText={errorsPJ.address.numero}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Complemento"
                  value={address.complemento}
                  onChange={(e) =>
                    setAddress({ ...address, complemento: e.target.value })
                  }
                  error={errorsPJ.address.complemento !== ''}
                  helperText={errorsPJ.address.complemento}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <StateSelect
                  label="Estado"
                  value={address.estado}
                  required
                  onChange={(e: any) =>
                    setAddress({ ...address, estado: e.target.value })
                  }
                  error={errorsPJ.address.estado !== ''}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                  helperText={errorsPJ.address.estado}
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Cidade"
                  value={address.cidade}
                  required
                  onChange={(e) =>
                    setAddress({ ...address, cidade: e.target.value })
                  }
                  error={errorsPJ.address.cidade !== ''}
                  helperText={errorsPJ.address.cidade}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Bairro"
                  value={address.bairro}
                  required
                  onChange={(e) =>
                    setAddress({ ...address, bairro: e.target.value })
                  }
                  error={errorsPJ.address.bairro !== ''}
                  helperText={errorsPJ.address.bairro}
                  disabled={
                    loading ||
                    (status !== 'Incompleto' && status !== 'Em análise')
                  }
                />
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <div>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Salvar
        </Button>
        <Button
          variant="outlined"
          className="ml-2"
          onClick={() => resetInfos()}
          color="primary"
        >
          Voltar
        </Button>
      </div>
      {status !== 'Incompleto' && status !== 'Em análise' && (
        <small className="d-block text-muted mt-4">
          Deseja atualizar os dados cadastrais? Entre em contato com o nosso
          atendimento
        </small>
      )}
    </>
  );
};

export default memo(NewMemberPJ);
