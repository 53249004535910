import React, { createContext, useState, useContext } from 'react';
import { payerIsComplete } from '~/pages/main/utils/normalize';

interface OperationContext {
  titles: Title[];
  handleTitle: (item: Title | Title[]) => void;
  handleCheck: (item: Title | Title[]) => void;
  handleNfse: (item: Title | Title[]) => void;
  bankSelected: string;
  selectBank: (b: string) => void;
  resetOperation: () => void;
}
const OperationC = createContext<OperationContext>({} as OperationContext);
export const OperationProvider: React.FC = ({ children }) => {
  const [titles, setTitles] = useState<Title[]>([]);
  const [bankSelected, setBankSelected] = useState('');

  function handleTitle(item: Title | Title[]) {
    if (Array.isArray(item)) {
      let tt = titles;
      item.forEach((t) => {
        if (payerIsComplete(t)) {
          if (
            tt.find(
              (i) => `${i.chave}${i.nume_doct}` === `${t.chave}${t.nume_doct}`
            )
          ) {
            tt = tt.filter(
              (i) => `${i.chave}${i.nume_doct}` !== `${t.chave}${t.nume_doct}`
            );
          } else {
            tt = [...tt, t];
          }
        }
      });
      setTitles(tt);
    } else if (
      titles.find(
        (i) => `${i.chave}${i.nume_doct}` === `${item.chave}${item.nume_doct}`
      )
    ) {
      setTitles(
        titles.filter(
          (i) => `${i.chave}${i.nume_doct}` !== `${item.chave}${item.nume_doct}`
        )
      );
    } else {
      setTitles([...titles, item]);
    }
  }

  function handleCheck(item: Title | Title[]) {
    if (Array.isArray(item)) {
      let tt = titles;
      item.forEach((t) => {
        if (payerIsComplete(t)) {
          if (
            tt.find(
              (i) => `${i.cmc7}${i.valo_titu}` === `${t.cmc7}${t.valo_titu}`
            )
          ) {
            tt = tt.filter(
              (i) => `${i.cmc7}${i.valo_titu}` !== `${t.cmc7}${t.valo_titu}`
            );
          } else {
            tt = [...tt, t];
          }
        }
      });
      setTitles(tt);
    } else if (
      titles.find(
        (i) => `${i.cmc7}${i.valo_titu}` === `${item.cmc7}${item.valo_titu}`
      )
    ) {
      setTitles(
        titles.filter(
          (i) => `${i.cmc7}${i.valo_titu}` !== `${item.cmc7}${item.valo_titu}`
        )
      );
    } else {
      setTitles([...titles, item]);
    }
  }

  function handleNfse(item: Title | Title[]) {
    if (Array.isArray(item)) {
      let tt = titles;
      item.forEach((t) => {
        if (payerIsComplete(t)) {
          if (
            tt.find(
              (i) => `${i.chave}${i.nume_doct}` === `${t.chave}${t.nume_doct}`
            )
          ) {
            tt = tt.filter(
              (i) => `${i.chave}${i.nume_doct}` !== `${t.chave}${t.nume_doct}`
            );
          } else {
            tt = [...tt, t];
          }
        }
      });
      setTitles(tt);
    } else if (
      titles.find(
        (i) => `${i.chave}${i.nume_doct}` === `${item.chave}${item.nume_doct}`
      )
    ) {
      setTitles(
        titles.filter(
          (i) => `${i.chave}${i.nume_doct}` !== `${item.chave}${item.nume_doct}`
        )
      );
    } else {
      setTitles([...titles, item]);
    }
  }

  function resetOperation() {
    setTitles([]);
    setBankSelected('');
  }

  return (
    <OperationC.Provider
      value={{
        titles,
        handleTitle,
        handleCheck,
        handleNfse,
        bankSelected,
        selectBank: (b) => setBankSelected(b),
        resetOperation,
      }}
    >
      {children}
    </OperationC.Provider>
  );
};
export const useOperation = (): OperationContext => {
  const context = useContext(OperationC);
  if (!context) {
    throw new Error(
      'The hook useOperation must be used within an OperationProvider'
    );
  }
  return context;
};
