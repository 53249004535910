import styled from 'styled-components';

import { lighten } from 'polished';

export const Container = styled.div`
  padding: 15px 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  div.header_component {
    border-bottom: 1px solid #eee;
    width: 100%;
  }
  div.content_component {
    margin: 20px 0px;
    height: 100%;
    width: 100%;
    .table tr td {
      vertical-align: middle !important;
      color: ${(props) =>
        props.theme.title === 'dark' &&
        `${props.theme.colors.text} !important`};
      button {
        color: ${(props) =>
          props.theme.title === 'dark' &&
          `${props.theme.colors.text} !important`};
      }
    }
    label {
      background: ${(props) => props.theme.colors.primary};
      color: #fff;
      padding: 3px 10px;
      min-width: 64px;
      border-radius: 4px;
      letter-spacing: 0.02857em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      svg {
        margin-left: 5px;
        font-size: 14px;
      }
      cursor: pointer;
      &.labelDanger {
        background: #ff0047;
        color: #fff;
      }
      &.loadingLabel {
        background: #c9c9c9;
        color: #222;
      }
    }
    table {
      tr {
        td {
          padding: 7.5px;
          font-size: 12px !important;
          color: #4e4e4e;
          button {
            padding: 3px 10px;
            min-width: 64px;
            border-radius: 4px;
            letter-spacing: 0.02857em;
            font-size: 12px;
            font-weight: 500;
          }
        }
        &:first-child {
          td {
            border-top: none;
          }
        }
      }
    }
    div.grid-docs {
      width: 100%;
      padding: 10px 0px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
      @media screen and (max-width: 1000px) {
        & {
          grid-template-columns: 1fr;
        }
      }
      div {
        padding: 0px;
        min-height: 350px;
        width: 100%;
        background: ${(props) => lighten(0.1, props.theme.colors.background)};
        h4 {
          background: ${(props) => props.theme.colors.primary};
          color: #fff;
          font-weight: 900;
          border-radius: 8px 8px 0px 0px;
          padding: 20px 20px 10px;
          width: 100%;
          text-transform: uppercase;
          font-size: 16px;
          small {
            color: #fff;
            display: block;
            font-style: italic;
            font-size: 11px;
            margin-top: 3px;
          }
          &.member {
            background: ${(props) => props.theme.colors.secondary};
          }
        }
        table {
          margin: 15px;
          padding: 0px;
          width: calc(100% - 30px);
          tr {
            td {
              padding: 12px 10px;
              font-size: 12px !important;
              color: #4e4e4e;
            }
          }
        }
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        height: 100%;
        font-size: 12px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        button {
          display: block;
          width: 100%;
          width: calc(100% - 30px) !important;
          margin: auto 15px 15px;
        }
      }
    }
  }
  div {
    button {
      color: ${(props) =>
        props.theme.title === 'dark' &&
        `${props.theme.colors.text} !important`};
    }
  }
`;
