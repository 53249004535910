import React, { memo, useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import axios from 'axios';
import { toast } from 'react-toastify';

import { TextField, Button, LinearProgress } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { BankSelect, banks as bankOptions } from './BankSelect';
import { editActive, editUnActive } from '~/store/modules/register/actions';
import { Container } from './styles';
import { normalizeNumber, normalizeAccBank } from '../../utils';

import ModalConfirmation from './ModalConfirmation';
import { useAuth } from '~/hooks/useAuth';

interface INextItem {
  (): void;
}

interface IProps {
  handleNextItem: INextItem;
}

interface IBanks {
  id: string;
  bankNumber: string;
  legalName: string;
  agencyNumber: string;
  agencyDigit: string;
  accountNumber: string;
  accountDigit: string;
}

interface IDelete {
  id: null | string;
  active: boolean;
  loading: boolean;
}

const Bank: React.FC<IProps> = () => {
  const {
    currentCompany: { clieId: id },
    signOut,
  } = useAuth();
  const [deleteBank, setDeleteBank] = useState<IDelete>({
    active: false,
    id: null,
    loading: false,
  });
  const [newActive, setNewActive] = useState(false);
  const [banks, setBanks] = useState<IBanks[]>([]);
  const [values, setValues] = useState({
    bank: '',
    code: '',
    agency: '',
    acc: '',
  });
  const [errors, setErrors] = useState({
    bank: '',
    code: '',
    agency: '',
    acc: '',
  });
  const [edit, setEdit] = useState({
    id: '',
    bank: '',
    code: '',
    agency: '',
    acc: '',
  });
  const [errorEdit, setErrorEdit] = useState({
    bank: '',
    code: '',
    agency: '',
    acc: '',
  });
  const [status, setStatus] = useState<
    | 'Incompleto'
    | 'Em análise'
    | 'Aguardando geração do contrato'
    | 'Aguardando assinatura do contrato'
    | 'Em operação'
  >('Em operação');
  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setValues({
      bank: '',
      code: '',
      agency: '',
      acc: '',
    });
    setNewActive(false);
    setErrors({
      bank: '',
      code: '',
      agency: '',
      acc: '',
    });
  };
  const resetFormEdit = () => {
    setErrorEdit({
      bank: '',
      code: '',
      agency: '',
      acc: '',
    });
    setEdit({
      id: '',
      bank: '',
      code: '',
      agency: '',
      acc: '',
    });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (newActive) {
      dispatch(editActive());
    } else {
      dispatch(editUnActive());
    }
  }, [newActive]); // eslint-disable-line

  useEffect(() => {
    async function loadBanks() {
      setLoading(true);
      try {
        const { data } = await axios.get(`/companies/status/${id}`);
        setStatus(data.status);
      } catch (err) {}
      try {
        const { data } = await axios.get<IBanks[]>('/banks');
        const banks_d: IBanks[] = [];
        data.forEach((i) => {
          banks_d.push(i);
        });
        setBanks(banks_d);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          signOut();
          toast.error('Sua sessão expirou, entre novamente');
        } else if (err.response && err.response.status === 403) {
          toast.error('Você não está autorizado a acessar este recurso');
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
          );
        } else {
          toast.error('Ocorreu um erro, tente novamente mais tarde');
        }
      }
      setLoading(false);
    }
    loadBanks();
  }, [id]); // eslint-disable-line

  const handleSubmit = async () => {
    setErrors({
      bank: '',
      code: '',
      agency: '',
      acc: '',
    });

    const lock_errors = {
      bank: '',
      code: '',
      agency: '',
      acc: '',
    };

    if (values.bank === '') {
      lock_errors.bank = 'Preencha esse campo';
    }
    if (values.agency === '') {
      lock_errors.agency = 'Preencha esse campo';
    }
    if (values.acc === '') {
      lock_errors.acc = 'Preencha esse campo';
    }

    if (
      lock_errors.bank !== '' ||
      lock_errors.agency !== '' ||
      lock_errors.acc !== ''
    ) {
      return setErrors(lock_errors);
    }
    try {
      const created = await axios.post('/banks', {
        bankNumber: values.code,
        agencyNumber: values.agency.split('-')[0],
        agencyDigit: values.agency.split('-')[1] || null,
        accountNumber: values.acc.split('-')[0],
        accountDigit: values.acc.split('-')[1],
        companyId: id,
        name: values.bank,
      });
      setBanks([
        ...banks,
        {
          id: created.data.id,
          ...created.data,
        },
      ]);
      resetForm();
    } catch (err) {
      if (err.response && err.response.status === 401) {
        signOut();
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
  };

  const handleSubmitEdit = async () => {
    setErrorEdit({
      bank: '',
      code: '',
      agency: '',
      acc: '',
    });

    const lock_errors = {
      bank: '',
      code: '',
      agency: '',
      acc: '',
    };

    if (edit.bank === '') {
      lock_errors.bank = 'Preencha esse campo';
    }
    if (edit.agency === '') {
      lock_errors.agency = 'Preencha esse campo';
    }
    if (edit.acc === '') {
      lock_errors.acc = 'Preencha esse campo';
    }

    if (
      lock_errors.bank !== '' ||
      lock_errors.agency !== '' ||
      lock_errors.acc !== ''
    ) {
      return setErrorEdit(lock_errors);
    }
    try {
      await axios.put(`/banks/${edit.id}`, {
        bankNumber: edit.code,
        agencyNumber: edit.agency.split('-')[0],
        agencyDigit: edit.agency.split('-')[1] || null,
        accountNumber: edit.acc.split('-')[0],
        accountDigit: edit.acc.split('-')[1],
      });

      setBanks(
        banks.map((b) => {
          if (b.id === edit.id) {
            return {
              ...b,
              bankNumber: edit.code,
              agencyNumber: edit.agency.split('-')[0],
              agencyDigit: edit.agency.split('-')[1],
              accountNumber: edit.acc.split('-')[0],
              accountDigit: edit.acc.split('-')[1],
            };
          }
          return b;
        })
      );
      resetFormEdit();
    } catch (err) {
      if (err.response && err.response.status === 401) {
        signOut();
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
  };

  const handleDelete = async () => {
    try {
      setDeleteBank({
        ...deleteBank,
        loading: true,
      });
      await axios.delete(`/banks/${deleteBank.id}`);
      setBanks(banks.filter((b) => b.id !== deleteBank.id));
      setDeleteBank({
        active: false,
        id: null,
        loading: false,
      });
    } catch (err) {
      if (err.response && err.response.status === 401) {
        signOut();
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
      setDeleteBank({
        ...deleteBank,
        loading: false,
      });
    }
  };

  return (
    <Container>
      <ModalConfirmation
        open={deleteBank.active}
        setOpen={() => {
          setDeleteBank({
            active: false,
            id: null,
            loading: false,
          });
        }}
        onDelete={handleDelete}
        loading={deleteBank.loading}
      />
      <div className="header_component d-flex">
        <h4>Contas Bancárias</h4>
      </div>
      <div className="content_component">
        {loading && <LinearProgress />}
        <div className="content_component-banks">
          {!loading &&
            banks.length > 0 &&
            banks.map((b, index) => {
              if (edit.id === b.id) {
                return (
                  <div className="new-bank" key={String(index)}>
                    <div>
                      <BankSelect
                        label="Banco"
                        value={`${edit.code} ${edit.bank}`}
                        onChange={(e: any) => {
                          if (e) {
                            setEdit({
                              ...edit,
                              bank: e.slice(4, e.length),
                              code: e.slice(0, 3),
                            });
                          }
                        }}
                        error={errorEdit.bank !== ''}
                        required
                      />
                      <TextField
                        className="mr-2"
                        label="Agência"
                        value={edit.agency}
                        onChange={(e) =>
                          setEdit({
                            ...edit,
                            agency:
                              normalizeNumber(e.target.value).length > 4
                                ? normalizeAccBank(
                                    normalizeNumber(e.target.value).slice(0, 5)
                                  )
                                : normalizeNumber(e.target.value.slice(0, 4)),
                          })
                        }
                        error={errorEdit.agency !== ''}
                        helperText={errorEdit.agency}
                        style={{ width: '100%' }}
                      />
                      <TextField
                        className="mr-2"
                        label="Conta bancária"
                        value={edit.acc}
                        onChange={(e) =>
                          setEdit({
                            ...edit,
                            acc: normalizeAccBank(e.target.value),
                          })
                        }
                        error={errorEdit.acc !== ''}
                        helperText={errorEdit.acc}
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div className="d-flex mt-4">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmitEdit}
                      >
                        Salvar
                      </Button>
                      <Button
                        variant="outlined"
                        className="ml-2"
                        color="primary"
                        onClick={resetFormEdit}
                      >
                        Cancelar
                      </Button>
                    </div>
                  </div>
                );
              }
              return (
                <div className="bank-item" key={String(index)}>
                  <div className="bank-item-header">
                    {bankOptions.find((bank) => {
                      if (bank.slice(0, 3) === b.bankNumber) {
                        return b;
                      }
                      return false;
                    }) || b.bankNumber}
                  </div>
                  {(status === 'Incompleto' || status === 'Em análise') && (
                    <button
                      type="button"
                      className="button-close"
                      onClick={() =>
                        setDeleteBank({
                          active: true,
                          id: b.id,
                          loading: false,
                        })
                      }
                    >
                      <Close />
                    </button>
                  )}
                  <div>
                    <p>
                      <strong>Agência:</strong> {b.agencyNumber}
                      {b.agencyDigit !== '' &&
                        b.agencyDigit &&
                        `-${b.agencyDigit}`}
                    </p>
                    <p>
                      <strong>Conta:</strong> {b.accountNumber}-{b.accountDigit}
                    </p>
                  </div>
                  {(status === 'Incompleto' || status === 'Em análise') && (
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginTop: 'auto', width: '100%' }}
                      onClick={() =>
                        setEdit({
                          acc: `${b.accountNumber}-${b.accountDigit}`,
                          agency: `${b.agencyNumber}${
                            b.agencyDigit !== '' && b.agencyDigit
                              ? `-${b.agencyDigit}`
                              : ''
                          }`,
                          bank:
                            bankOptions.find((bank) => {
                              if (bank.slice(0, 3) === b.bankNumber) {
                                return b;
                              }
                              return false;
                            }) || b.bankNumber,
                          code: b.bankNumber,
                          id: b.id,
                        })
                      }
                    >
                      Editar
                    </Button>
                  )}
                </div>
              );
            })}
          {!newActive ? (
            <>
              {!loading &&
                (status === 'Incompleto' || status === 'Em análise') && (
                  <div className="bank-item detached">
                    <Button
                      onClick={() => setNewActive(true)}
                      color="primary"
                      variant="outlined"
                    >
                      Adicionar conta bancária
                    </Button>
                  </div>
                )}
            </>
          ) : (
            <div className="new-bank">
              <form>
                <BankSelect
                  label="Banco"
                  value={`${values.code} ${values.bank}`}
                  onChange={(e: any) =>
                    setValues({
                      ...values,
                      bank: e ? e.slice(4, e.length) : '',
                      code: e ? e.slice(0, 3) : '',
                    })
                  }
                  error={errors.bank !== ''}
                  required
                />
                <TextField
                  className="mr-2"
                  label="Agência"
                  value={values.agency}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      agency:
                        normalizeNumber(e.target.value).length > 4
                          ? normalizeAccBank(
                              normalizeNumber(e.target.value).slice(0, 5)
                            )
                          : normalizeNumber(e.target.value.slice(0, 4)),
                    })
                  }
                  error={errors.agency !== ''}
                  helperText={errors.agency}
                  style={{ width: '100%' }}
                />
                <TextField
                  className="mr-2"
                  label="Conta bancária"
                  value={values.acc}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      acc: normalizeAccBank(e.target.value),
                    })
                  }
                  error={errors.acc !== ''}
                  helperText={errors.acc}
                  style={{ width: '100%' }}
                />
                <div className="d-flex mt-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Salvar
                  </Button>
                  <Button
                    variant="outlined"
                    className="ml-2"
                    color="primary"
                    onClick={resetForm}
                  >
                    Cancelar
                  </Button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default memo(Bank);
