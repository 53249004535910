import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import storage from 'redux-persist/lib/storage';

import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  persistReducer(
    {
      key: 'bfc-digital-admin',
      storage,
      whitelist: ['auth', 'user', 'anticipation', 'checkAnticipation'],
    },
    rootReducer
  ),
  applyMiddleware(sagaMiddleware)
);

const persitor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persitor };
