import React, { memo, useState, useEffect } from 'react';

import {
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Button,
  FormHelperText,
  LinearProgress,
} from '@material-ui/core';

import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import StateSelect from './StateSelect';
import {
  normalizeCpf,
  normalizeDate,
  normalizeCep,
  normalizeTelephone,
} from '../../../utils';

import {
  validatorMember,
  validatorMemberSpouses,
} from '../utils/memberPFValidation';

import consultCep from '../../../../../../services/consultCep';

import { signOut } from '../../../../../../store/modules/auth/actions';

interface IMember {
  id: string;
  docType: string;
  type: string;
  name: string;
  document: string;
}
interface IAddMember {
  (value: IMember): void;
}

interface propsInt {
  resetForm: any;
  addMember: IAddMember;
}

interface IMemberType {
  id: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

const NewMemberPF: React.FC<propsInt> = ({ resetForm, addMember }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [memberTypes, setMemberTypes] = useState<IMemberType[]>([]);
  const [memberType, setMemberType] = useState<string | undefined | unknown>(
    ''
  );
  const [newMemberPF, setNewMemberPF] = useState({
    name: '',
    cpf: '',
    rg: '',
    rgEmmiter: '',
    eCpf: '',
    nacionality: '',
    profission: '',
    email: '',
    telephone: '',
    birthDate: '',
    motherName: '',
    maritalStatus: '',
    maritalStatusRegime: '',
    politicalExposedPerson: '',
    logradouro: '',
    numero: '',
    complemento: '',
    cep: '',
    estado: '',
    cidade: '',
    bairro: '',
  });
  const [newMemberPFErrors, setNewMemberPFErrors] = useState({
    name: '',
    cpf: '',
    rg: '',
    rgEmmiter: '',
    eCpf: '',
    nacionality: '',
    profission: '',
    email: '',
    telephone: '',
    birthDate: '',
    motherName: '',
    maritalStatus: '',
    maritalStatusRegime: '',
    logradouro: '',
    numero: '',
    complemento: '',
    cep: '',
    estado: '',
    cidade: '',
    bairro: '',
  });
  const [memberSpouses, setMemberSpouses] = useState({
    name: '',
    cpf: '',
    rg: '',
    rgEmmiter: '',
    eCpf: '',
    nacionality: '',
    profission: '',
    email: '',
    birthDate: '',
    motherName: '',
  });

  useEffect(() => {
    async function loadMemberTypes() {
      try {
        const { data } = await axios.get(`/partners/member-types`);
        setMemberTypes(data.filter((type) => type.visible));
      } catch (err) {
        if (err.response && err.response.status === 401) {
          dispatch(signOut());
          toast.error('Sua sessão expirou, entre novamente');
        } else if (err.response && err.response.status === 403) {
          toast.error('Você não está autorizado a acessar este recurso');
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
          );
        } else {
          toast.error('Ocorreu um erro, tente novamente mais tarde');
        }
      }
    }
    loadMemberTypes();
  }, []); // eslint-disable-line

  const [memberSpousesErrors, setMemberSpousesErrors] = useState({
    name: '',
    cpf: '',
    rg: '',
    rgEmmiter: '',
    eCpf: '',
    nacionality: '',
    profission: '',
    email: '',
    birthDate: '',
    motherName: '',
  });

  function resetInfos() {
    setNewMemberPF({
      name: '',
      cpf: '',
      rg: '',
      rgEmmiter: '',
      eCpf: '',
      nacionality: '',
      profission: '',
      email: '',
      telephone: '',
      birthDate: '',
      motherName: '',
      maritalStatus: '',
      maritalStatusRegime: '',
      politicalExposedPerson: '',
      logradouro: '',
      numero: '',
      complemento: '',
      cep: '',
      estado: '',
      cidade: '',
      bairro: '',
    });
    setMemberSpouses({
      name: '',
      cpf: '',
      rg: '',
      rgEmmiter: '',
      eCpf: '',
      nacionality: '',
      profission: '',
      email: '',
      birthDate: '',
      motherName: '',
    });
    setMemberSpousesErrors({
      name: '',
      cpf: '',
      rg: '',
      rgEmmiter: '',
      eCpf: '',
      nacionality: '',
      profission: '',
      email: '',
      birthDate: '',
      motherName: '',
    });
    setNewMemberPFErrors({
      name: '',
      cpf: '',
      rg: '',
      rgEmmiter: '',
      eCpf: '',
      nacionality: '',
      profission: '',
      email: '',
      telephone: '',
      birthDate: '',
      motherName: '',
      maritalStatus: '',
      maritalStatusRegime: '',
      logradouro: '',
      numero: '',
      complemento: '',
      cep: '',
      estado: '',
      cidade: '',
      bairro: '',
    });
    resetForm();
  }

  async function handleSubmit() {
    setMemberSpousesErrors({
      name: '',
      cpf: '',
      rg: '',
      rgEmmiter: '',
      eCpf: '',
      nacionality: '',
      profission: '',
      email: '',
      birthDate: '',
      motherName: '',
    });
    setNewMemberPFErrors({
      name: '',
      cpf: '',
      rg: '',
      rgEmmiter: '',
      eCpf: '',
      nacionality: '',
      profission: '',
      email: '',
      telephone: '',
      birthDate: '',
      motherName: '',
      maritalStatus: '',
      maritalStatusRegime: '',
      logradouro: '',
      numero: '',
      complemento: '',
      cep: '',
      estado: '',
      cidade: '',
      bairro: '',
    });
    setLoading(true);
    const {
      count_errors: count_errors_member,
      errors: errors_member,
    } = await validatorMember(newMemberPF);
    setLoading(false);
    let count_errors_spouses = 0;
    if (
      (newMemberPF.maritalStatus === 'Casado(a)' ||
        newMemberPF.maritalStatus === 'União estável') &&
      newMemberPF.maritalStatusRegime !== 'Separação total de bens'
    ) {
      const { count_errors, errors } = validatorMemberSpouses({
        ...memberSpouses,
        cpfMember: newMemberPF.cpf,
      });
      count_errors_spouses = count_errors;
      if (count_errors > 0) {
        setMemberSpousesErrors(errors);
      }
    }

    if (count_errors_member > 0) {
      setNewMemberPFErrors(errors_member);
    }
    if (
      count_errors_spouses > 0 ||
      count_errors_member > 0 ||
      memberType === ''
    ) {
      return;
    }
    setLoading(true);
    try {
      let birthDate: string | Date = '';
      const splited_date = newMemberPF.birthDate.split('/');
      birthDate = new Date(
        `${splited_date[2]}-${splited_date[1]}-${splited_date[0]}`
      );

      let spouses_birthDate: string | Date = '';

      if (
        (newMemberPF.maritalStatus === 'Casado(a)' ||
          newMemberPF.maritalStatus === 'União estável') &&
        newMemberPF.maritalStatusRegime !== 'Separação total de bens'
      ) {
        const splited_date_spouses = memberSpouses.birthDate.split('/');
        spouses_birthDate = new Date(
          `${splited_date_spouses[2]}-${splited_date_spouses[1]}-${splited_date_spouses[0]}`
        );
      }

      const response = await axios.post('/partners/physical-persons', {
        memberTypeId: memberType,
        name: newMemberPF.name,
        cpf: newMemberPF.cpf,
        rg: newMemberPF.rg,
        rgEmmiter: newMemberPF.rgEmmiter,
        eCpf: newMemberPF.eCpf === '1',
        nacionality: newMemberPF.nacionality,
        profission: newMemberPF.profission,
        email: newMemberPF.email,
        telephone: newMemberPF.telephone,
        birthDate,
        motherName: newMemberPF.motherName,
        maritalStatus: newMemberPF.maritalStatus,
        maritalStatusRegime: newMemberPF.maritalStatusRegime,
        politicalExposedPerson: newMemberPF.politicalExposedPerson,
        address: {
          street: newMemberPF.logradouro,
          number: newMemberPF.numero,
          complement: newMemberPF.complemento,
          cep: newMemberPF.cep,
          state: newMemberPF.estado,
          city: newMemberPF.cidade,
          district: newMemberPF.bairro,
        },
        spouses:
          (newMemberPF.maritalStatus === 'Casado(a)' ||
            newMemberPF.maritalStatus === 'União estável') &&
          newMemberPF.maritalStatusRegime !== 'Separação total de bens'
            ? {
                name: memberSpouses.name,
                birthDate: spouses_birthDate,
                cpf: memberSpouses.cpf,
                eCpf: memberSpouses.eCpf === '1',
                email: memberSpouses.email,
                rg: memberSpouses.rg,
                rgEmmiter: memberSpouses.rgEmmiter,
                motherName: memberSpouses.motherName,
                nacionality: memberSpouses.nacionality,
                profission: memberSpouses.profission,
              }
            : undefined,
      });
      const mbType = memberTypes.find((i) => i.id === memberType);
      if (!mbType) {
        resetForm();
      } else {
        addMember({
          id: response.data.id,
          name: newMemberPF.name,
          document: newMemberPF.cpf,
          docType: 'PF',
          type: mbType.type,
        });
        resetForm();
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(signOut());
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 400) {
        toast.error('Há campos inválidos');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
    setLoading(false);
  }

  async function handleCep() {
    const consult = await consultCep(newMemberPF.cep);

    if (consult) {
      setNewMemberPF({
        ...newMemberPF,
        ...consult,
      });
    }
  }

  return (
    <>
      <div className="content_component">
        {loading && <LinearProgress />}
        <form>
          <FormControl
            error={memberType === ''}
            style={{ width: '100%' }}
            className="mb-5"
          >
            <InputLabel>Tipo de Sócio</InputLabel>
            <Select
              value={memberType}
              onChange={(e) => setMemberType(e.target.value)}
              style={{ width: '100%' }}
            >
              {memberTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <fieldset>
            <h4>Identificação</h4>
            <div className="row">
              <div className="col-md-3 mb-2">
                <TextField
                  label="Nome completo"
                  className="d-flex"
                  value={newMemberPF.name}
                  onChange={(e) => {
                    setNewMemberPF({ ...newMemberPF, name: e.target.value });
                    if (newMemberPFErrors.name !== '') {
                      setNewMemberPFErrors({
                        ...newMemberPFErrors,
                        name: '',
                      });
                    }
                  }}
                  error={newMemberPFErrors.name !== ''}
                  helperText={newMemberPFErrors.name}
                />
              </div>
              <div className="col-md-3 mb-2">
                <TextField
                  label="CPF"
                  className="d-flex"
                  value={newMemberPF.cpf}
                  onChange={(e) => {
                    setNewMemberPF({
                      ...newMemberPF,
                      cpf: normalizeCpf(e.target.value),
                    });
                    if (newMemberPFErrors.cpf !== '') {
                      setNewMemberPFErrors({
                        ...newMemberPFErrors,
                        cpf: '',
                      });
                    }
                  }}
                  error={newMemberPFErrors.cpf !== ''}
                  helperText={newMemberPFErrors.cpf}
                />
              </div>
              <div className="col-md-3 mb-2">
                <TextField
                  label="RG/RNE"
                  className="d-flex"
                  value={newMemberPF.rg}
                  onChange={(e) => {
                    setNewMemberPF({ ...newMemberPF, rg: e.target.value });
                    if (newMemberPFErrors.rg !== '') {
                      setNewMemberPFErrors({
                        ...newMemberPFErrors,
                        rg: '',
                      });
                    }
                  }}
                  error={newMemberPFErrors.rg !== ''}
                  helperText={newMemberPFErrors.rg}
                />
              </div>
              <div className="col-md-3 mb-2">
                <TextField
                  label="Emissor RG/RNE"
                  className="d-flex"
                  value={newMemberPF.rgEmmiter}
                  onChange={(e) => {
                    setNewMemberPF({
                      ...newMemberPF,
                      rgEmmiter: e.target.value,
                    });
                    if (newMemberPFErrors.rgEmmiter !== '') {
                      setNewMemberPFErrors({
                        ...newMemberPFErrors,
                        rgEmmiter: '',
                      });
                    }
                  }}
                  error={newMemberPFErrors.rgEmmiter !== ''}
                  helperText={newMemberPFErrors.rgEmmiter}
                />
              </div>
              <div className="col-md-3 mb-2">
                <FormControl
                  style={{ width: '100%' }}
                  error={newMemberPFErrors.eCpf !== ''}
                >
                  <InputLabel id="eCpf_label">eCPF</InputLabel>
                  <Select
                    style={{ width: '100%' }}
                    value={newMemberPF.eCpf}
                    onChange={(e) => {
                      setNewMemberPF({
                        ...newMemberPF,
                        eCpf: String(e.target.value),
                      });
                      if (newMemberPFErrors.eCpf !== '') {
                        setNewMemberPFErrors({
                          ...newMemberPFErrors,
                          eCpf: '',
                        });
                      }
                    }}
                    labelId="eCpf_label"
                    error={newMemberPFErrors.eCpf !== ''}
                  >
                    <MenuItem value="1">Possui</MenuItem>
                    <MenuItem value="0">Não Possui</MenuItem>
                  </Select>
                  {newMemberPFErrors.eCpf !== '' && (
                    <FormHelperText error>
                      {newMemberPFErrors.eCpf}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              <div className="col-md-3 mb-2">
                <TextField
                  label="Nacionalidade"
                  className="d-flex"
                  value={newMemberPF.nacionality}
                  onChange={(e) => {
                    setNewMemberPF({
                      ...newMemberPF,
                      nacionality: e.target.value,
                    });
                    if (newMemberPFErrors.nacionality !== '') {
                      setNewMemberPFErrors({
                        ...newMemberPFErrors,
                        nacionality: '',
                      });
                    }
                  }}
                  error={newMemberPFErrors.nacionality !== ''}
                  helperText={newMemberPFErrors.nacionality}
                />
              </div>
              <div className="col-md-3 mb-2">
                <TextField
                  label="Profissão"
                  className="d-flex"
                  value={newMemberPF.profission}
                  onChange={(e) => {
                    setNewMemberPF({
                      ...newMemberPF,
                      profission: e.target.value,
                    });
                    if (newMemberPFErrors.profission !== '') {
                      setNewMemberPFErrors({
                        ...newMemberPFErrors,
                        profission: '',
                      });
                    }
                  }}
                  error={newMemberPFErrors.profission !== ''}
                  helperText={newMemberPFErrors.profission}
                />
              </div>
              <div className="col-md-3 mb-2">
                <TextField
                  label="Endereço de E-mail"
                  className="d-flex"
                  value={newMemberPF.email}
                  onChange={(e) => {
                    setNewMemberPF({ ...newMemberPF, email: e.target.value });
                    if (newMemberPFErrors.email !== '') {
                      setNewMemberPFErrors({
                        ...newMemberPFErrors,
                        email: '',
                      });
                    }
                  }}
                  error={newMemberPFErrors.email !== ''}
                  helperText={newMemberPFErrors.email}
                />
              </div>
              <div className="col-md-3 mb-2">
                <TextField
                  label="Telefone"
                  className="d-flex"
                  value={newMemberPF.telephone}
                  onChange={(e) => {
                    setNewMemberPF({
                      ...newMemberPF,
                      telephone: normalizeTelephone(e.target.value),
                    });
                    if (newMemberPFErrors.telephone !== '') {
                      setNewMemberPFErrors({
                        ...newMemberPFErrors,
                        telephone: '',
                      });
                    }
                  }}
                  error={newMemberPFErrors.telephone !== ''}
                  helperText={newMemberPFErrors.telephone}
                />
              </div>
              <div className="col-md-3 mb-2">
                <TextField
                  label="Data de Nascimento"
                  className="d-flex"
                  value={newMemberPF.birthDate}
                  onChange={(e) => {
                    setNewMemberPF({
                      ...newMemberPF,
                      birthDate: normalizeDate(e.target.value),
                    });
                    if (newMemberPFErrors.birthDate !== '') {
                      setNewMemberPFErrors({
                        ...newMemberPFErrors,
                        birthDate: '',
                      });
                    }
                  }}
                  error={newMemberPFErrors.birthDate !== ''}
                  helperText={newMemberPFErrors.birthDate}
                />
              </div>
              <div className="col-md-3 mb-2">
                <TextField
                  label="Nome da mãe"
                  className="d-flex"
                  value={newMemberPF.motherName}
                  onChange={(e) => {
                    setNewMemberPF({
                      ...newMemberPF,
                      motherName: e.target.value,
                    });
                    if (newMemberPFErrors.motherName !== '') {
                      setNewMemberPFErrors({
                        ...newMemberPFErrors,
                        motherName: '',
                      });
                    }
                  }}
                  error={newMemberPFErrors.motherName !== ''}
                  helperText={newMemberPFErrors.motherName}
                />
              </div>
            </div>
          </fieldset>
          <fieldset>
            <h4>Informações do Estado Civil</h4>
            <div className="row">
              <div className="col-md-3 mb-2">
                <FormControl
                  style={{ width: '100%' }}
                  error={newMemberPFErrors.maritalStatus !== ''}
                >
                  <InputLabel id="maritalStatus_label">Estado civil</InputLabel>
                  <Select
                    style={{ width: '100%' }}
                    labelId="maritalStatus_label"
                    value={newMemberPF.maritalStatus}
                    onChange={(e) => {
                      setNewMemberPF({
                        ...newMemberPF,
                        maritalStatus: String(e.target.value),
                      });
                      if (newMemberPFErrors.maritalStatus !== '') {
                        setNewMemberPFErrors({
                          ...newMemberPFErrors,
                          maritalStatus: '',
                        });
                      }
                    }}
                    error={newMemberPFErrors.maritalStatus !== ''}
                  >
                    <MenuItem value="Solteiro(a)">Solteiro(a)</MenuItem>
                    <MenuItem value="Casado(a)">Casado(a)</MenuItem>
                    <MenuItem value="Sepadaro(a)/Divorciado(a)">
                      Sepadaro(a)/Divorciado(a)
                    </MenuItem>
                    <MenuItem value="Viúvo(a)">Viúvo(a)</MenuItem>
                    <MenuItem value="União estável">União estável</MenuItem>
                  </Select>
                  {newMemberPFErrors.maritalStatus !== '' && (
                    <FormHelperText error>
                      {newMemberPFErrors.maritalStatus}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              {(newMemberPF.maritalStatus === 'Casado(a)' ||
                newMemberPF.maritalStatus === 'União estável') && (
                <div className="col-md-3" style={{ padding: '0px 5px' }}>
                  <FormControl
                    style={{ width: '100%' }}
                    error={newMemberPFErrors.maritalStatusRegime !== ''}
                  >
                    <InputLabel id="maritalStatusRegime_label">
                      Regime do estado civil
                    </InputLabel>
                    <Select
                      style={{ width: '100%' }}
                      labelId="maritalStatusRegime_label"
                      value={newMemberPF.maritalStatusRegime}
                      onChange={(e) => {
                        setNewMemberPF({
                          ...newMemberPF,
                          maritalStatusRegime: String(e.target.value),
                        });
                        if (newMemberPFErrors.maritalStatusRegime !== '') {
                          setNewMemberPFErrors({
                            ...newMemberPFErrors,
                            maritalStatusRegime: '',
                          });
                        }
                      }}
                      disabled={loading}
                      error={newMemberPFErrors.maritalStatusRegime !== ''}
                    >
                      <MenuItem value="Comunhão de Bens">
                        Comunhão de Bens
                      </MenuItem>
                      <MenuItem value="Comunhão Parcial de Bens">
                        Comunhão Parcial de Bens
                      </MenuItem>
                      <MenuItem value="Separação total de bens">
                        Separação total de bens
                      </MenuItem>
                      <MenuItem value="União estável">União estável</MenuItem>
                    </Select>
                    {newMemberPFErrors.maritalStatusRegime !== '' && (
                      <FormHelperText error>
                        {newMemberPFErrors.maritalStatusRegime}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
              )}
            </div>
            {(newMemberPF.maritalStatus === 'Casado(a)' ||
              newMemberPF.maritalStatus === 'União estável') &&
              newMemberPF.maritalStatusRegime !== 'Separação total de bens' &&
              newMemberPF.maritalStatusRegime !== '' && (
                <fieldset className="mt-4">
                  <h4>Dados do Cônjuge</h4>
                  <div className="row">
                    <div className="col-md-3 mb-2">
                      <TextField
                        label="Nome completo"
                        className="d-flex"
                        value={memberSpouses.name}
                        onChange={(e) => {
                          setMemberSpouses({
                            ...memberSpouses,
                            name: e.target.value,
                          });
                          if (memberSpousesErrors.name !== '') {
                            setMemberSpousesErrors({
                              ...memberSpousesErrors,
                              name: '',
                            });
                          }
                        }}
                        error={memberSpousesErrors.name !== ''}
                        helperText={memberSpousesErrors.name}
                      />
                    </div>
                    <div className="col-md-3 mb-2">
                      <TextField
                        label="CPF"
                        className="d-flex"
                        value={memberSpouses.cpf}
                        onChange={(e) => {
                          setMemberSpouses({
                            ...memberSpouses,
                            cpf: normalizeCpf(e.target.value),
                          });
                          if (memberSpousesErrors.cpf !== '') {
                            setMemberSpousesErrors({
                              ...memberSpousesErrors,
                              cpf: '',
                            });
                          }
                        }}
                        error={memberSpousesErrors.cpf !== ''}
                        helperText={memberSpousesErrors.cpf}
                      />
                    </div>
                    <div className="col-md-3 mb-2">
                      <TextField
                        label="RG/RNE"
                        className="d-flex"
                        value={memberSpouses.rg}
                        onChange={(e) => {
                          setMemberSpouses({
                            ...memberSpouses,
                            rg: e.target.value,
                          });
                          if (memberSpousesErrors.rg !== '') {
                            setMemberSpousesErrors({
                              ...memberSpousesErrors,
                              rg: '',
                            });
                          }
                        }}
                        error={memberSpousesErrors.rg !== ''}
                        helperText={memberSpousesErrors.rg}
                      />
                    </div>
                    <div className="col-md-3 mb-2">
                      <TextField
                        label="Emissor RG/RNE"
                        className="d-flex"
                        value={memberSpouses.rgEmmiter}
                        onChange={(e) => {
                          setMemberSpouses({
                            ...memberSpouses,
                            rgEmmiter: e.target.value,
                          });
                          if (memberSpousesErrors.rgEmmiter !== '') {
                            setMemberSpousesErrors({
                              ...memberSpousesErrors,
                              rgEmmiter: '',
                            });
                          }
                        }}
                        error={memberSpousesErrors.rgEmmiter !== ''}
                        helperText={memberSpousesErrors.rgEmmiter}
                      />
                    </div>
                    <div className="col-md-3 mb-2">
                      <FormControl
                        style={{ width: '100%' }}
                        error={memberSpousesErrors.eCpf !== ''}
                      >
                        <InputLabel id="eCpf_label-spouse">eCPF</InputLabel>
                        <Select
                          style={{ width: '100%' }}
                          value={memberSpouses.eCpf}
                          onChange={(e) => {
                            setMemberSpouses({
                              ...memberSpouses,
                              eCpf: String(e.target.value),
                            });
                            if (memberSpousesErrors.eCpf !== '') {
                              setMemberSpousesErrors({
                                ...memberSpousesErrors,
                                eCpf: '',
                              });
                            }
                          }}
                          error={memberSpousesErrors.eCpf !== ''}
                          labelId="eCpf_label-spouse"
                        >
                          <MenuItem value="1">Possui</MenuItem>
                          <MenuItem value="0">Não Possui</MenuItem>
                        </Select>
                        {memberSpousesErrors.eCpf !== '' && (
                          <FormHelperText error>
                            {memberSpousesErrors.eCpf}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <div className="col-md-3 mb-2">
                      <TextField
                        label="Nacionalidade"
                        className="d-flex"
                        value={memberSpouses.nacionality}
                        onChange={(e) => {
                          setMemberSpouses({
                            ...memberSpouses,
                            nacionality: e.target.value,
                          });
                          if (memberSpousesErrors.nacionality !== '') {
                            setMemberSpousesErrors({
                              ...memberSpousesErrors,
                              nacionality: '',
                            });
                          }
                        }}
                        error={memberSpousesErrors.nacionality !== ''}
                        helperText={memberSpousesErrors.nacionality}
                      />
                    </div>
                    <div className="col-md-3 mb-2">
                      <TextField
                        label="Profissão"
                        className="d-flex"
                        value={memberSpouses.profission}
                        onChange={(e) => {
                          setMemberSpouses({
                            ...memberSpouses,
                            profission: e.target.value,
                          });
                          if (memberSpousesErrors.profission !== '') {
                            setMemberSpousesErrors({
                              ...memberSpousesErrors,
                              profission: '',
                            });
                          }
                        }}
                        error={memberSpousesErrors.profission !== ''}
                        helperText={memberSpousesErrors.profission}
                      />
                    </div>
                    <div className="col-md-3 mb-2">
                      <TextField
                        label="Endereço de e-mail"
                        className="d-flex"
                        value={memberSpouses.email}
                        onChange={(e) => {
                          setMemberSpouses({
                            ...memberSpouses,
                            email: e.target.value,
                          });
                          if (memberSpousesErrors.email !== '') {
                            setMemberSpousesErrors({
                              ...memberSpousesErrors,
                              email: '',
                            });
                          }
                        }}
                      />
                    </div>
                    <div className="col-md-3 mb-2">
                      <TextField
                        label="Data de Nascimento"
                        className="d-flex"
                        value={memberSpouses.birthDate}
                        onChange={(e) => {
                          setMemberSpouses({
                            ...memberSpouses,
                            birthDate: normalizeDate(e.target.value),
                          });
                          if (memberSpousesErrors.birthDate !== '') {
                            setMemberSpousesErrors({
                              ...memberSpousesErrors,
                              birthDate: '',
                            });
                          }
                        }}
                        error={memberSpousesErrors.birthDate !== ''}
                        helperText={memberSpousesErrors.birthDate}
                      />
                    </div>
                    <div className="col-md-3 mb-2">
                      <TextField
                        label="Nome da mãe"
                        className="d-flex"
                        value={memberSpouses.motherName}
                        onChange={(e) => {
                          setMemberSpouses({
                            ...memberSpouses,
                            motherName: e.target.value,
                          });
                          if (memberSpousesErrors.motherName !== '') {
                            setMemberSpousesErrors({
                              ...memberSpousesErrors,
                              motherName: '',
                            });
                          }
                        }}
                        error={memberSpousesErrors.motherName !== ''}
                        helperText={memberSpousesErrors.motherName}
                      />
                    </div>
                  </div>
                </fieldset>
              )}
          </fieldset>
          <fieldset>
            <h4>Pessoas politicamente expostas?</h4>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        newMemberPF.politicalExposedPerson ===
                        'Sou políticamente exposto'
                      }
                      onChange={(e) => {
                        setNewMemberPF({
                          ...newMemberPF,
                          politicalExposedPerson: 'Sou políticamente exposto',
                        });
                      }}
                      color="primary"
                    />
                  }
                  label="Sou políticamente exposto"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        newMemberPF.politicalExposedPerson ===
                        'Não sou politicamente exposto'
                      }
                      onChange={(e) =>
                        setNewMemberPF({
                          ...newMemberPF,
                          politicalExposedPerson:
                            'Não sou politicamente exposto',
                        })
                      }
                      color="primary"
                    />
                  }
                  label="Não sou politicamente exposto"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        newMemberPF.politicalExposedPerson ===
                        'Tenho relacionamento com alguém que é políticamente exposto'
                      }
                      onChange={(e) =>
                        setNewMemberPF({
                          ...newMemberPF,
                          politicalExposedPerson:
                            'Tenho relacionamento com alguém que é políticamente exposto',
                        })
                      }
                      color="primary"
                    />
                  }
                  label="Tenho relacionamento com alguém que é políticamente exposto"
                />
              </FormGroup>
            </FormControl>
          </fieldset>
          <fieldset>
            <h4>Endereço do Sócio</h4>
            <div className="row">
              <div className="col-md-3 mb-2">
                <TextField
                  label="CEP"
                  value={newMemberPF.cep}
                  required
                  onChange={(e) => {
                    setNewMemberPF({
                      ...newMemberPF,
                      cep: normalizeCep(e.target.value),
                    });
                    if (newMemberPFErrors.cep !== '') {
                      setNewMemberPFErrors({
                        ...newMemberPFErrors,
                        cep: '',
                      });
                    }
                  }}
                  onBlur={handleCep}
                  error={newMemberPFErrors.cep !== ''}
                  helperText={newMemberPFErrors.cep}
                />
              </div>
              <div className="col-md-3 mb-2">
                <TextField
                  label="Logradouro"
                  value={newMemberPF.logradouro}
                  required
                  onChange={(e) => {
                    setNewMemberPF({
                      ...newMemberPF,
                      logradouro: e.target.value,
                    });
                    if (newMemberPFErrors.logradouro !== '') {
                      setNewMemberPFErrors({
                        ...newMemberPFErrors,
                        logradouro: '',
                      });
                    }
                  }}
                  error={newMemberPFErrors.logradouro !== ''}
                  helperText={newMemberPFErrors.logradouro}
                />
              </div>
              <div className="col-md-3 mb-2">
                <TextField
                  label="Número"
                  value={newMemberPF.numero}
                  required
                  onChange={(e) => {
                    setNewMemberPF({
                      ...newMemberPF,
                      numero: e.target.value,
                    });
                    if (newMemberPFErrors.numero !== '') {
                      setNewMemberPFErrors({
                        ...newMemberPFErrors,
                        numero: '',
                      });
                    }
                  }}
                  error={newMemberPFErrors.numero !== ''}
                  helperText={newMemberPFErrors.numero}
                />
              </div>
              <div className="col-md-3 mb-2">
                <TextField
                  label="Complemento"
                  value={newMemberPF.complemento}
                  onChange={(e) =>
                    setNewMemberPF({
                      ...newMemberPF,
                      complemento: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-md-3 mb-2">
                <StateSelect
                  label="Estado"
                  value={newMemberPF.estado}
                  onChange={(e) => {
                    setNewMemberPF({
                      ...newMemberPF,
                      estado: e.target.value,
                    });
                    if (newMemberPFErrors.estado !== '') {
                      setNewMemberPFErrors({
                        ...newMemberPFErrors,
                        estado: '',
                      });
                    }
                  }}
                  error={newMemberPFErrors.estado !== ''}
                  helperText={newMemberPFErrors.estado}
                  required
                />
              </div>
              <div className="col-md-3 mb-2">
                <TextField
                  label="Cidade"
                  value={newMemberPF.cidade}
                  required
                  onChange={(e) => {
                    setNewMemberPF({
                      ...newMemberPF,
                      cidade: e.target.value,
                    });
                    if (newMemberPFErrors.cidade !== '') {
                      setNewMemberPFErrors({
                        ...newMemberPFErrors,
                        cidade: '',
                      });
                    }
                  }}
                  error={newMemberPFErrors.cidade !== ''}
                  helperText={newMemberPFErrors.cidade}
                />
              </div>
              <div className="col-md-3 mb-2">
                <TextField
                  label="Bairro"
                  value={newMemberPF.bairro}
                  required
                  onChange={(e) => {
                    setNewMemberPF({
                      ...newMemberPF,
                      bairro: e.target.value,
                    });
                    if (newMemberPFErrors.bairro !== '') {
                      setNewMemberPFErrors({
                        ...newMemberPFErrors,
                        bairro: '',
                      });
                    }
                  }}
                  error={newMemberPFErrors.bairro !== ''}
                  helperText={newMemberPFErrors.bairro}
                />
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <div>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Salvar
        </Button>
        <Button
          variant="outlined"
          className="ml-2"
          onClick={() => resetInfos()}
          color="primary"
        >
          Voltar
        </Button>
      </div>
    </>
  );
};

export default memo(NewMemberPF);
