import { isPast } from 'date-fns';

interface ICompanyAddress {
  logradouro: string;
  numero: string;
  complemento: string;
  cep: string;
  estado: string;
  cidade: string;
  bairro: string;
}

interface ICompany {
  companyName: string;
  fantasyName: string;
  cnpj: string;
  inscState: string;
  inscStateValue: string;
  address: ICompanyAddress;
}

interface IFinalBeneficiario {
  name: string;
  document_type: string;
  document: string;
}

interface IRepresentant {
  name: string;
  cpf: string;
  rg: string;
  rgEmmiter: string;
  eCpf: string;
  nacionality: string;
  profission: string;
  email: string;
  telephone: string;
  birthDate: string;
  motherName: string;
  maritalStatus: string;
  maritalStatusRegime: string;
  politicalExposedPerson: boolean;
  relation_with_politicalExposedPerson: boolean;
  logradouro: string;
  numero: string;
  complemento: string;
  cep: string;
  estado: string;
  cidade: string;
  bairro: string;
}

interface IMember {
  name: string;
  document_type: string;
  document: string;
  nacionality: string;
}

enum EMember {
  name = 'name',
  cpf = 'cpf',
  rg = 'rg',
  rgEmmiter = 'rgEmmiter',
  eCpf = 'eCpf',
  nacionality = 'nacionality',
  profission = 'profission',
  email = 'email',
  telephone = 'telephone',
  birthDate = 'birthDate',
  motherName = 'motherName',
  maritalStatus = 'maritalStatus',
  maritalStatusRegime = 'maritalStatusRegime',
  logradouro = 'logradouro',
  numero = 'numero',
  complemento = 'complemento',
  cep = 'cep',
  estado = 'estado',
  cidade = 'cidade',
  bairro = 'bairro',
}

function cpfIsValid(cpf: string) {
  let soma;
  let resto;
  soma = 0;
  const strCPF = cpf.replace(/\D/g, '');
  if (strCPF === '00000000000') return false;

  for (let i = 1; i <= 9; i++)
    soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

function emailIsValid(email: string) {
  if (email.indexOf('@') === -1) {
    return false;
  }
  const after = email.split('@');
  if (after[1].indexOf('.') === -1) {
    return false;
  }

  return true;
}

const parseToDate = (value: string) => {
  const splitedDate = value.split('/');
  return new Date(
    Number(splitedDate[2]),
    Number(splitedDate[1]),
    Number(splitedDate[0])
  );
};

function cnpjIsValid(cnpj: any) {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj === '') return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  )
    return false;

  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(0))) return false;

  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(1))) return false;

  return true;
}

export function validateMemberPJ(values: ICompany) {
  const errors: ICompany = {
    companyName: '',
    fantasyName: '',
    cnpj: '',
    inscState: '',
    inscStateValue: '',
    address: {
      logradouro: '',
      numero: '',
      complemento: '',
      cep: '',
      estado: '',
      cidade: '',
      bairro: '',
    },
  };
  let count_errors = 0;

  if (values.companyName === '' || !values.companyName) {
    errors.companyName = 'Preencha esse campo';
    count_errors += 1;
  }

  if (values.fantasyName === '' || !values.fantasyName) {
    errors.fantasyName = 'Preencha esse campo';
    count_errors += 1;
  }

  if (!cnpjIsValid(values.cnpj)) {
    errors.cnpj = 'CNPJ não é válido';
    count_errors += 1;
  }

  if (values.inscState === '' || !values.inscState) {
    errors.inscState = 'Marque alguma opção';
    count_errors += 1;
  }

  if (
    values.inscState === 'required' &&
    (values.inscStateValue === '' || !values.inscStateValue)
  ) {
    errors.inscStateValue = 'Preencha esse campo';
    count_errors += 1;
  }

  if (values.address.logradouro === '' || !values.address.logradouro) {
    errors.address.logradouro = 'Preencha esse campo';
    count_errors += 1;
  }
  if (values.address.numero === '' || !values.address.numero) {
    errors.address.numero = 'Preencha esse campo';
    count_errors += 1;
  }
  if (values.address.cep === '' || !values.address.cep) {
    errors.address.cep = 'Preencha esse campo';
    count_errors += 1;
  } else if (values.address.cep.length < 9) {
    errors.address.cep = 'Preencha esse campo';
    count_errors += 1;
  }
  if (values.address.estado === '' || !values.address.estado) {
    errors.address.estado = 'Preencha esse campo';
    count_errors += 1;
  }
  if (values.address.cidade === '' || !values.address.cidade) {
    errors.address.cidade = 'Preencha esse campo';
    count_errors += 1;
  }
  if (values.address.bairro === '' || !values.address.bairro) {
    errors.address.bairro = 'Preencha esse campo';
    count_errors += 1;
  }

  return {
    count_errors,
    errors,
  };
}

export function validateMemberPJRepresentant(values: IRepresentant) {
  const errors = {
    name: '',
    cpf: '',
    rg: '',
    rgEmmiter: '',
    eCpf: '',
    nacionality: '',
    profission: '',
    email: '',
    telephone: '',
    birthDate: '',
    motherName: '',
    maritalStatus: '',
    maritalStatusRegime: '',
    logradouro: '',
    numero: '',
    complemento: '',
    cep: '',
    estado: '',
    cidade: '',
    bairro: '',
  };
  let count_errors = 0;

  const defaultError = (n: EMember) => {
    errors[n] = 'Preencha esse campo';
    count_errors += 1;
  };

  if (values.name === '' || !values.name) {
    defaultError(EMember.name);
  }

  if (values.cpf === '' || !values.cpf) {
    defaultError(EMember.cpf);
  } else if (values.cpf.length < 14) {
    defaultError(EMember.cpf);
  } else if (!cpfIsValid(values.cpf)) {
    errors.cpf = 'Cpf não é válido';
    count_errors += 1;
  }

  if (values.rg === '') {
    defaultError(EMember.rg);
  }

  if (values.rgEmmiter === '' || !values.rgEmmiter) {
    defaultError(EMember.rgEmmiter);
  }

  if (values.eCpf === '' || !values.eCpf) {
    defaultError(EMember.eCpf);
  }

  if (values.nacionality === '' || !values.nacionality) {
    defaultError(EMember.nacionality);
  }

  if (values.profission === '' || !values.profission) {
    defaultError(EMember.profission);
  }

  if (values.email === '' || !values.email) {
    defaultError(EMember.email);
  } else if (!emailIsValid(values.email)) {
    defaultError(EMember.email);
  }

  if (values.telephone === '' || !values.telephone) {
    defaultError(EMember.telephone);
  } else if (values.telephone.length < 14) {
    defaultError(EMember.telephone);
  }

  if (values.birthDate === '' || !values.birthDate) {
    defaultError(EMember.birthDate);
  } else if (values.birthDate.length < 10) {
    defaultError(EMember.birthDate);
  } else if (!isPast(parseToDate(values.birthDate))) {
    errors.birthDate = 'PREENCHA UMA DATA VÁLIDA';
    count_errors += 1;
  }

  if (values.motherName === '' || !values.motherName) {
    defaultError(EMember.motherName);
  }

  if (values.maritalStatus === '' || !values.maritalStatus) {
    defaultError(EMember.maritalStatus);
  }

  if (values.maritalStatusRegime === '' || !values.maritalStatusRegime) {
    defaultError(EMember.maritalStatusRegime);
  }

  if (values.logradouro === '' || !values.logradouro) {
    defaultError(EMember.logradouro);
  }

  if (values.numero === '' || !values.numero) {
    defaultError(EMember.numero);
  }

  if (values.cep === '' || !values.cep) {
    defaultError(EMember.cep);
  } else if (values.cep.length < 9) {
    defaultError(EMember.cep);
  }

  if (values.estado === '' || !values.estado) {
    defaultError(EMember.estado);
  }

  if (values.cidade === '' || !values.cidade) {
    defaultError(EMember.cidade);
  }

  if (values.bairro === '' || !values.bairro) {
    defaultError(EMember.bairro);
  }

  return {
    count_errors,
    errors,
  };
}

export function validateMember(values: IMember) {
  const errors = {
    name: '',
    document_type: '',
    document: '',
    nacionality: '',
  };
  let count_errors = 0;

  if (values.name !== '' || !values.name) {
    errors.name = 'Preencha esse campo';
    count_errors += 1;
  }

  if (values.document_type === '' || !values.document_type) {
    errors.document_type = 'Preencha esse campo';
    count_errors += 1;
  }

  if (values.document === '' || !values.document) {
    errors.document = 'Preencha esse campo';
    count_errors += 1;
  }

  if (values.nacionality === '' || !values.nacionality) {
    errors.nacionality = 'Preencha esse campo';
    count_errors += 1;
  }

  return {
    count_errors,
    errors,
  };
}

export function validateFinalBeneficiario(values: IFinalBeneficiario) {
  const errors = { name: '', document_type: '', document: '' };
  if (values.name !== '' || !values.name) {
    errors.name = 'Preencha esse campo';
  }

  if (values.document_type === '' || !values.document_type) {
    errors.document_type = 'Preencha esse campo';
  }

  if (values.document === '' || !values.document) {
    errors.document = 'Preencha esse campo';
  }
}
