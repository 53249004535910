import styled, { keyframes } from 'styled-components';

import { lighten, darken } from 'polished';

const fadeContainers = keyframes`
  0%{
    opacity:0;
    transform:translateX(-20px);
  }100%{
    opacity:1;
    transform:translateX(0);
  }
`;

export const Container = styled.div`
  width: 80px;
  &.opened-side {
    width: 100%;
  }
  border-radius: 15px;
  background: ${(props) => props.theme.colors.background};
  animation: ${fadeContainers} 300ms linear;
  animation-fill-mode: backwards;
  transition: 300ms;
  & + div {
    margin-top: 10px;
  }
  &.help-section-sidemenu {
    padding: 20px;
    span {
      display: block;
      padding: 0px 5px;
      border-radius: 22px 3px 22px 6px;
      width: 50px;
      height: 50px;
      margin-right: 10px;
      background: ${(props) => props.theme.colors.primary}
        url('/assets/images/avatar-suport.png') no-repeat 50% 50%;
      background-size: contain;
      transition: 300ms;
    }
    &:hover {
      span {
        transform: scale(1.1);
        background-color: ${(props) =>
          lighten(0.1, props.theme.colors.primary)};
      }
    }
  }
  &.antecipation-orange {
    overflow: hidden;
    padding: 20px;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.background};
    transition: 300ms;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      color: #fff;
      width: 0px;
      overflow: hidden;
      display: none;
    }
    > span {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 5px;
      background: ${(props) => props.theme.colors.background};
      border-radius: 15px;
      min-width: 40px;
      min-height: 40px;
      margin-right: 10px;
      background-size: contain;
      transition: 500ms;
      img {
        width: 15px;
        transition: 500ms;
      }
    }
    div {
      width: 0px;
      height: 0px;
      transition: 500ms;
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      overflow: hidden;
      h4 {
        font-size: 12px;
        font-weight: 900;
        margin: 0px;
        padding: 0px;
        color: #fff;
        transition: 500ms;
      }
      span {
        margin-top: 4px;
        font-size: 18px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: #fff;
        font-weight: 700;
        transition: 500ms;
        position: relative;
        &::before {
          content: '';
          width: 0%;
          height: 100%;
          background: rgba(255, 255, 255, 0.6);
          backdrop-filter: blur(6px);
          box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.1);
          position: absolute;
          top: 0;
          right: -2.5%;
          border-radius: 6px;
          transition: 350ms;
        }
        &.no-visible {
          &::before {
            width: 105%;
          }
        }
      }
    }
    /* &:hover {
      background:#ffc326;
      > span {
        opacity:0.7;
      }
      div {
        cursor:pointer;
        position:absolute;
        width:100%;
        height:100%;
        border-radius:30px;
        top:0px;
        left:0px;
        align-items:center;
        background:rgba(255, 185, 1, 0.6);
        h4 {
          font-size:10px;
          transform:translateY(-5px);
        }
        span {
          transform:scale(1.8);
        }
      }
    } */
    &.opened-side {
      border-radius: 15px;
      button {
        width: 40px;
        display: block;
        margin-top: 18px;
      }
      > span {
        min-width: 50px;
        min-height: 50px;
        img {
          width: 15px;
        }
      }
      div {
        width: 100%;
        height: 55px;
      }
    }
  }
  ul {
    overflow: hidden;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 0 15px;
    &.subDropdown {
      li {
        padding-left: 20px;
      }
    }
    li {
      width: 100%;
      overflow: hidden;
      margin: 0px;
      ul.dropdown-close_menu {
        height: 0px;
        overflow: hidden;
        opacity: 0;
        overflow: hidden;
      }
      ul.dropdown-open_menu {
        display: none;
        height: 100%;
        overflow: unset;
        opacity: 1;
        overflow: hidden;
      }
      ul {
        padding: 0px;
        transition: 300ms;
        li button {
          margin: 0px;
          background: none;
          padding: 10px 15px;
        }
        li + li {
          border-top: 1px solid
            ${(props) => darken(0.05, props.theme.colors.background)};
        }
      }
    }
    li {
      overflow: hidden;
    }
    li button {
      overflow: hidden;
      margin: 0px;
      span {
        background: ${(props) => darken(0.05, props.theme.colors.background)};
        min-height: 45px;
        min-width: 45px;
        padding: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        margin-right: 15px;
        transition: 300ms;
        color: ${(props) => props.theme.colors.secondary};
        img {
          transition: 300ms;
          width: 30px;
        }
      }
      text-align: left;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      min-width: 225px;
      max-width: 225px;
      margin: 5px 0px;
      padding: 5px 0px;
      border: none;
      background: none;
      color: ${(props) => lighten(0.2, props.theme.colors.text)};
      text-decoration: none !important;
      transition: 300ms;
      &.active {
        color: ${(props) => props.theme.colors.primary};
        span {
          background: ${(props) => props.theme.colors.primary};
          color: ${(props) => props.theme.colors.background};
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
      &:hover {
        color: ${(props) => props.theme.colors.primary};
        span {
          background: ${(props) => props.theme.colors.primary};
          color: ${(props) => props.theme.colors.background};
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
    li + li {
      border-top: 1px solid
        ${(props) => darken(0.05, props.theme.colors.background)};
    }
  }
  &.opened-side {
    ul.dropdown-open_menu {
      display: block;
    }
    ul li button span {
      min-width: 40px;
      min-height: 40px;
      width: 40px;
      height: 40px;
      padding: 10px;
      img {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 980px) {
    #fixed_menu_button {
      display: none;
    }
  }
`;
