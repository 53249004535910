import React, { useState, useEffect, memo } from 'react';

import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import useSelector from '~/store/useSelector';
import { modalOpen } from '~/store/modules/register/actions';
import { factaApi } from '~/services/facta';
import { Container } from './styles';

import Dropdown from './Dropdown';
import { normalizeBrl } from '../../utils/normalize';
import { useAuth } from '~/hooks/useAuth';

interface IChangeCurrent {
  to: string;
  currentNumber: number;
}

interface IProps {
  open: boolean;
  setOpen: (v: boolean) => void;
}

const SubMenu: React.FC<IProps> = ({ open, setOpen }) => {
  const [openDropdown, setOpenDropdown] = useState<null | number>(null);
  const [path, setPath] = useState<null | string>(null);
  const [pathSub, setPathSub] = useState<null | string>(null);
  const [pathSubSub, setPathSubSub] = useState<null | string>(null);
  const [current, setCurrent] = useState<null | number>(null);
  const [limit, setLimit] = useState<number | null>(null);
  const [menuVisible, setMenuVisible] = useState(false);

  const {
    currentCompany: { clieId },
  } = useAuth();

  const [menuItems] = useState([
    // {
    //   item: 'cadastro',
    //   page: '',
    //   icon: '/assets/images/icon-register.png',
    //   description: 'Cadastro',
    //   isDropdown: true,
    //   submenu: [
    //     {
    //       item: 'informacao-geral',
    //       page: '/cadastro/informacao-geral',
    //       description: 'Informação Geral',
    //     },
    //     {
    //       item: 'ecnpj',
    //       page: '/cadastro/ecnpj',
    //       description: 'e-CNPJ',
    //     },
    //     {
    //       item: 'contas-bancarias',
    //       page: '/cadastro/contas-bancarias',
    //       description: 'Contas Bancárias',
    //     },
    //     {
    //       item: 'socios',
    //       page: '/cadastro/socios',
    //       description: 'Sócios',
    //     },
    //     {
    //       item: 'documentos',
    //       page: '/cadastro/documentos',
    //       description: 'Documentos',
    //     },
    //   ],
    // },
    {
      item: 'operacoes',
      page: '',
      icon: '/assets/images/icon-antecipation.png',
      description: 'Operações',
      isDropdown: true,
      submenu: [
        {
          item: 'antecipar-recebiveis',
          page: '',
          description: 'Antecipar Recebíveis',
          isDropdown: true,
          submenu: [
            {
              item: 'nfe',
              page: '/operacoes/antecipar-recebiveis/nfe',
              description: 'NF-e',
            },
            {
              item: 'cte',
              page: '/operacoes/antecipar-recebiveis/cte',
              description: 'CT-e',
            },
            {
              item: 'nfse',
              page: '/operacoes/antecipar-recebiveis/nfse',
              description: 'NFS-e',
            },
            {
              item: 'cheque',
              page: '/operacoes/antecipar-recebiveis/cheque',
              description: 'Cheque',
            },
          ],
        },
        {
          item: 'lista',
          page: '/operacoes/lista',
          description: 'Borderôs',
        },
        {
          item: 'remessas',
          page: '/operacoes/remessas',
          description: 'Remessas',
        },
      ],
    },
    {
      item: 'boletos',
      page: '/boletos',
      icon: '/assets/images/icon-billet.png',
      description: 'Boletos',
      isDropdown: false,
      submenu: [],
    },
    {
      item: 'titulos',
      page: '',
      icon: '/assets/images/icon-titles.png',
      description: 'Títulos',
      isDropdown: true,
      submenu: [
        {
          item: 'a-vencer',
          page: '/titulos/a-vencer',
          description: 'A vencer',
        },
        {
          item: 'liquidados',
          page: '/titulos/liquidados',
          description: 'Liquidados',
        },
        {
          item: 'vencidos',
          page: '/titulos/vencidos',
          description: 'Vencidos',
        },
      ],
    },
    {
      item: 'tarifas',
      page: '',
      icon: '/assets/images/icon-fees.png',
      description: 'Tarifas',
      isDropdown: true,
      submenu: [
        {
          item: 'abertos',
          page: '/tarifas/abertos',
          description: 'Em Aberto',
        },
        {
          item: 'liquidadas',
          page: '/tarifas/liquidadas',
          description: 'Liquidadas',
        },
      ],
    },
    {
      item: 'servicos',
      page: '',
      icon: '/assets/images/icon-services.png',
      description: 'Serviços de consulta',
      isDropdown: true,
      submenu: [
        // {
        //   item: 'notas-contra',
        //   page: '/servicos/notas-contra',
        //   description: 'Notas Emitidas Contra',
        // },
        {
          item: 'empresa',
          page: '/servicos/empresa',
          description: 'Consulta de Empresa',
        },
        {
          item: 'protesto',
          page: '/servicos/protesto',
          description: 'Consulta de Protestos',
        },
      ],
    },
    {
      item: 'configuracoes',
      page: '',
      icon: '/assets/images/icon-config.png',
      description: 'Configurações',
      isDropdown: true,
      submenu: [
        {
          item: 'usuarios',
          page: '/configuracoes/usuarios',
          description: 'Usuários',
        },
      ],
    },
  ]);
  const { isEditing } = useSelector((state) => state.register);
  const dispatch = useDispatch();
  const history = useHistory();

  function handlePath() {
    const pathArray = window.location.pathname.split('/');
    setPath(pathArray[1]);
    if (pathArray[2]) {
      setPathSub(pathArray[2]);
    }
    if (pathArray[3]) {
      setPathSubSub(pathArray[3]);
    }
    switch (pathArray[1]) {
      // case 'cadastro':
      //   setCurrent(1);
      //   setOpenDropdown(1);
      //   break;
      case 'operacoes':
        setCurrent(1);
        setOpenDropdown(1);
        break;
      case 'boletos':
        setCurrent(2);
        setOpenDropdown(2);
        break;
      case 'titulos':
        setCurrent(3);
        setOpenDropdown(3);
        break;
      case 'tarifas':
        setCurrent(4);
        setOpenDropdown(4);
        break;
      case 'servicos':
        setCurrent(5);
        setOpenDropdown(5);
        break;
      case 'configuracoes':
        setCurrent(7);
        setOpenDropdown(7);
        break;
      default:
        setCurrent(999);
        setOpenDropdown(999);
        break;
    }
  }

  useEffect(() => {
    handlePath();
  }, []);

  useEffect(() => {
    async function loadAssignor() {
      const { data } = await factaApi.get('/me');
      setLimit(data.limit);
    }
    loadAssignor();
  }, [clieId]);

  const handleFixed = () => {
    setMenuVisible(!menuVisible);
    setOpen(!open);
  };

  const handleChangeCurrent = ({ to, currentNumber }: IChangeCurrent) => {
    if (isEditing) {
      if (window.location.pathname !== to) {
        dispatch(modalOpen({ modal_to: to }));
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      history.push(to);
    }
    handlePath();
  };

  return (
    <>
      <Container
        className={`shadow antecipation-orange d-flex ${
          !menuVisible ? 'opened-side' : open ? 'opened-side' : ''
        }`}
        style={{ animationDelay: '200ms' }}
      >
        <span>
          <img src="/assets/images/icon-money.png" alt="Money Icon" />
        </span>
        <div>
          <h4>Limite disponível</h4>
          {limit && <span>{normalizeBrl(limit)}</span>}
        </div>
      </Container>
      <Container
        className={`shadow ${
          !menuVisible ? 'opened-side' : open ? 'opened-side' : ''
        }`}
        id="menu_items"
        style={{ animationDelay: '300ms', position: 'relative' }}
      >
        <ul>
          {current &&
            openDropdown &&
            menuItems.map((li, index) => {
              if (li.isDropdown) {
                return (
                  <li key={String(index)}>
                    <button
                      type="button"
                      className={path === li.item ? 'active' : ''}
                      onClick={() => {
                        setOpenDropdown(index + 1);
                      }}
                    >
                      <span>
                        <img src={li.icon} alt={li.item} />
                      </span>
                      {li.description}
                    </button>
                    <Dropdown open={openDropdown === index + 1}>
                      {li.submenu.map((liSub, ind) => (
                        <li key={String(ind)}>
                          {liSub.isDropdown ? (
                            <>
                              <button
                                type="button"
                                className={
                                  pathSub === liSub.item ? 'active' : ''
                                }
                              >
                                {liSub.description}
                              </button>
                              <ul className="subDropdown">
                                {liSub.submenu.map((el, i) => (
                                  <li key={String(i)}>
                                    <button
                                      type="button"
                                      className={
                                        pathSubSub === el.item ? 'active' : ''
                                      }
                                      onClick={() =>
                                        handleChangeCurrent({
                                          to: el.page,
                                          currentNumber: index,
                                        })
                                      }
                                    >
                                      {el.description}
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            </>
                          ) : (
                            <button
                              type="button"
                              className={pathSub === liSub.item ? 'active' : ''}
                              onClick={() =>
                                handleChangeCurrent({
                                  to: liSub.page,
                                  currentNumber: index,
                                })
                              }
                            >
                              {liSub.description}
                            </button>
                          )}
                        </li>
                      ))}
                    </Dropdown>
                  </li>
                );
              }
              return (
                <li key={String(index)}>
                  <button
                    type="button"
                    className={current === index + 1 ? 'active' : ''}
                    onClick={() =>
                      handleChangeCurrent({
                        to: li.page,
                        currentNumber: index,
                      })
                    }
                  >
                    <span>
                      <img src={li.icon} alt={li.item} />
                    </span>
                    {li.description}
                  </button>
                </li>
              );
            })}
        </ul>
        <button
          type="button"
          id="fixed_menu_button"
          style={{
            position: 'absolute',
            right: '0',
            bottom: '-55px',
            color: '#3757a1',
            fontSize: '2rem',
            textAlign: 'center',
            width: '100%',
          }}
          onClick={() => handleFixed()}
        >
          {menuVisible ? <FaAngleRight /> : <FaAngleLeft />}
        </button>
      </Container>
    </>
  );
};

export default memo(SubMenu);
