import React, { useState, useEffect } from 'react';

import { TextField, Button, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { Container } from './styles';

import { signOut } from '~/store/modules/auth/actions';
import { normalizeCurrency, normalizeDate } from '../../utils/normalize';

interface IProps {
  id: number | null;
  open: boolean;
  setOpen: () => void;
  onLoad: () => void;
  dataTitu: string | null;
  valoTitu: number | null;
  numeDoct: string | null;
  editDataTitu: number | null;
  editNumeDoc: number | null;
  editValoTitu: number | null;
}

const ModalEditDuplicate: React.FC<IProps> = ({
  id,
  open,
  setOpen,
  onLoad,
  valoTitu,
  dataTitu,
  numeDoct,
  editDataTitu,
  editNumeDoc,
  editValoTitu,
}) => {
  const [valueValoTitu, setValueValoTitu] = useState('');
  const [valueDataTitu, setValueDataTitu] = useState('');
  const [valueNumeDoct, setValueNumeDoct] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setValueValoTitu(
      valoTitu
        ? normalizeCurrency(Number(valoTitu.toFixed(2).replace(/[^\d]/g, '')))
        : ''
    );
    setValueDataTitu(
      dataTitu ? String(format(new Date(dataTitu), 'dd/MM/yyyy')) : ''
    );
    setValueNumeDoct(numeDoct || '');
  }, [valoTitu, dataTitu, numeDoct]);

  function parseToDate(date: string): Date {
    const s = date.split('/');
    return new Date(`${s[2]}-${s[1]}-${s[0]}`);
  }

  async function handleSubmit() {
    setLoading(true);
    try {
      const data = {
        valo_titu: valueValoTitu
          ? Number(valueValoTitu.replace(/[^\d]/g, '')) / 100
          : '',
        data_titu: valueDataTitu ? parseToDate(valueDataTitu) : '',
        nume_doct: valueNumeDoct || '',
      };
      await axios.put(
        `${process.env.REACT_APP_API_FACTA_AUTH}/duplicatas/${id}`,
        data
      );
      onLoad();
      toast.success('Dados de Título atualizado');
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(signOut());
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.message);
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
    setLoading(false);
  }

  return (
    <Container
      show={open}
      onHide={() => {
        if (!loading) {
          setOpen();
        }
      }}
      centered
      size="sm"
    >
      <Container.Body>
        {editNumeDoc === 1 && (
          <>
            <p className="mb-0 small">Número do Título</p>
            <TextField
              type="text"
              id="numeDoct"
              value={valueNumeDoct}
              onChange={(e) => setValueNumeDoct(e.target.value)}
              disabled={loading}
              variant="outlined"
              size="small"
              className="mb-2"
            />
          </>
        )}
        {editValoTitu === 1 && (
          <>
            <p className="mb-0 small">Valor</p>
            <TextField
              type="text"
              value={valueValoTitu}
              onChange={(e) =>
                setValueValoTitu(
                  normalizeCurrency(
                    Number(e.target.value.replace(/[^\d]/g, ''))
                  )
                )
              }
              disabled={loading}
              variant="outlined"
              size="small"
              className="mb-2"
            />
          </>
        )}
        {editDataTitu === 1 && (
          <>
            <p className="mb-0 small">Data de Vencimento</p>
            <TextField
              type="text"
              value={valueDataTitu}
              onChange={(e) => setValueDataTitu(normalizeDate(e.target.value))}
              disabled={loading}
              variant="outlined"
              size="small"
              className="mb-2"
            />
          </>
        )}

        <div className="d-flex justify-content-center mt-3">
          <Button
            type="button"
            color="primary"
            variant="contained"
            size="small"
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading && <CircularProgress size={16} className="mr-2" />}
            Salvar
          </Button>
          <Button
            type="button"
            color="primary"
            variant="outlined"
            size="small"
            className="ml-2"
            onClick={setOpen}
            disabled={loading}
          >
            Cancelar
          </Button>
        </div>
      </Container.Body>
    </Container>
  );
};

export default ModalEditDuplicate;
