import styled from 'styled-components';

import { lighten } from 'polished';

export const Container = styled.div`
  padding: 15px 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  div.header_component {
    border-bottom: 1px solid #eee;
    width: 100%;
  }
  div.content_component {
    margin: 20px 0px;
    padding: 20px 10px;
    height: 100%;
    width: 100%;
    div.content_component-banks {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 25px;
      @media screen and (max-width: 850px) {
        & {
          grid-template-columns: 1fr;
        }
      }
      div.bank-item:not(.detached) {
        button:not(.button-close) {
          width: calc(100% - 30px) !important;
        }
      }
      div.bank-item {
        width: 100%;
        padding: 0 0 15px;
        border-radius: 8px;
        background: ${(props) => lighten(0.1, props.theme.colors.background)};
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        div {
          width: 100%;
          padding: 20px 20px 10px;
        }
        div.bank-item-header {
          background: ${(props) => props.theme.colors.primary};
          color: #fff;
          font-weight: 900;
          width: 100%;
          border-radius: 8px 8px 0px 0px;
        }
        button.button-close {
          position: absolute;
          top: -10px;
          right: -10px;
          width: 25px;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
          background: #f7f7f7;
          border: none;
          transition: 300ms;
          &:hover {
            background: #f1f1f1;
            transform: scale(1.1);
          }
          svg {
            font-size: 1.1rem;
          }
        }
        p {
          font-size: 13px;
        }
      }
      div.new-bank {
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        background: ${(props) =>
          props.theme.title === 'dark' &&
          lighten(0.1, props.theme.colors.background)};
        * {
          color: ${(props) =>
            props.theme.title === 'dark' &&
            `${props.theme.colors.text} !important`};
        }
      }
      div.detached {
        border: 2px dashed rgba(55, 87, 161, 0.3);
        padding: 20px;
        button {
          margin: auto;
        }
      }
    }
  }
`;
