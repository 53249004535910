import React, { useState, useEffect } from 'react';
import { Checkbox, Button, LinearProgress, TextField } from '@material-ui/core';
import { Keyboard, Warning, Delete } from '@material-ui/icons';
import { Table, Row, Col } from 'react-bootstrap';
import { format, addHours } from 'date-fns';

import { FaTimes } from 'react-icons/fa';

import Lottie from 'react-lottie';
import { toast } from 'react-toastify';
import animationData from '~/animations/box_empty.json';

import ModalTapNote from './ModalTapNote';
import ModalDelete from './ModalDelete';
import ModalDeleteAll from './ModalDeleteAll';
import {
  normalizeCurrency,
  normalizeCpfCnpj,
  normalizeNumber,
} from '../utils/normalize';
import { useOperation } from '~/hooks/useOperation';
import { factaApi } from '~/services/facta';
import { normalizeBrl, payerIsComplete } from '../../utils/normalize';
import ModalEditPayer from './ModalEditPayer';
import { useAuth } from '~/hooks/useAuth';
import { authApi } from '~/services/auth';

const TableOne: React.FC = () => {
  const { titles: selected, handleCheck, resetOperation } = useOperation();
  const [isSearch, setIsSearch] = useState(false);
  const [titles, setTitles] = useState<Title[]>([]);
  const [loading, setLoading] = useState(false);
  const [updatePayer, setUpdatePayer] = useState({
    open: false,
    title: {} as Title,
  });
  const [confirmDelete, setConfirmDelete] = useState({
    open: false,
    id: 0,
  });
  const [confirmDeleteAll, setConfirmDeleteAll] = useState({
    open: false,
  });
  const [filters, setFilters] = useState({
    dataTituStart: '',
    dataTituEnd: '',
    valoTituStart: '',
    valoTituEnd: '',
    cmc7: '',
    sacaId: '',
  });
  const [digitOpen, setDigitOpen] = useState(false);

  const {
    currentCompany: { clieId: id },
  } = useAuth();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  async function loadData(refresh?: boolean) {
    setLoading(true);
    try {
      const query: string[] = [];
      if (filters.dataTituStart !== '') {
        query.push(`dataTituStart=${filters.dataTituStart}`);
      }
      if (filters.dataTituEnd !== '') {
        query.push(`dataTituEnd=${filters.dataTituEnd}`);
      }
      if (filters.valoTituStart !== '') {
        query.push(
          `valoTituStart=${parseFloat(
            filters.valoTituStart.split('.').join('').split(',').join('.')
          )}`
        );
      }
      if (filters.valoTituEnd !== '') {
        query.push(
          `valoTituEnd=${parseFloat(
            filters.valoTituEnd.split('.').join('').split(',').join('.')
          )}`
        );
      }
      if (filters.cmc7 !== '') {
        query.push(`cmc7=${filters.cmc7}`);
      }
      if (filters.sacaId !== '') {
        query.push(`sacaId=${filters.sacaId.replace(/[^\d]/g, '')}`);
      }
      const { data } = await factaApi.get(
        `${process.env.REACT_APP_API_FACTA}/checks?${query
          .map((q) => q)
          .join('&')}`
      );
      setTitles(data);
    } catch (error) {} // eslint-disable-line
    setLoading(false);
  }

  async function handleDelete() {
    setLoading(true);
    try {
      await authApi.delete(`/duplicatas/${confirmDelete.id}`);
      handleCheck(selected.filter((t) => t.id !== confirmDelete.id));
      setTitles(titles.filter((t) => t.id !== confirmDelete.id));
      setConfirmDelete({
        open: false,
        id: 0,
      });
      toast.info('Cheque excluído');
    } catch (err) {
      if (err.response?.status === 400) {
        toast.error(err.response?.data?.message);
      }
    }
    setLoading(false);
  }

  async function handleDeleteAll() {
    setLoading(true);
    for (let i = 0; i < titles.length; i += 1) {
      const title = titles[i];
      try {
        await authApi.delete(`/duplicatas/${title.id}`);
        handleCheck(selected.filter((t) => t.id !== title.id));
      } catch (err) {} // eslint-disable-line
    }
    setConfirmDeleteAll({
      open: false,
    });
    loadData();
    toast.info('Cheques excluídos');
    setLoading(false);
  }

  function handleAll() {
    handleCheck(titles);
  }

  useEffect(() => {
    resetOperation();
  }, []) // eslint-disable-line

  useEffect(() => {
    loadData();
  }, [id]); // eslint-disable-line

  return (
    <div id="anticipation_table_wrap">
      <div className="d-flex justify-content-between align-items-center">
        <h4>Antecipar Recebíveis - Cheque</h4>
        <div>
          <Button
            type="button"
            onClick={() => setDigitOpen(true)}
            color="primary"
            className="mr-2 text-white"
            variant="contained"
          >
            Digitar Cheque
          </Button>
          <Button
            type="button"
            onClick={() => {
              if (isSearch) {
                loadData();
              } else {
                setIsSearch(true);
              }
            }}
            className="mr-2"
            disabled={loading}
            color="primary"
            variant="outlined"
          >
            {isSearch ? 'Filtrar' : 'Exibir filtro'}
          </Button>
          {isSearch &&
            Object.entries(filters).filter(([k, v], i) => !!v).length > 0 && (
              <Button
                color="default"
                variant="contained"
                type="button"
                onClick={() => {
                  setFilters({
                    dataTituStart: '',
                    dataTituEnd: '',
                    valoTituStart: '',
                    valoTituEnd: '',
                    cmc7: '',
                    sacaId: '',
                  });
                  loadData(true);
                }}
              >
                <FaTimes className="mr-2" />
                Limpar filtros
              </Button>
            )}
          {!isSearch && titles.length > 0 && (
            <Button
              type="button"
              onClick={() => {
                setConfirmDeleteAll({
                  open: true,
                });
              }}
              disabled={loading}
              color="primary"
              variant="outlined"
            >
              Excluir todos
            </Button>
          )}
        </div>
      </div>
      {isSearch && (
        <Row className="mb-3 mt-0 filters-wrap">
          <Col lg={6} className="mb-0 mt-0">
            <small className="d-block mb-2">Data de Vencimento</small>
            <div className="d-flex align-items-center">
              <TextField
                type="date"
                value={filters.dataTituStart}
                onChange={(e) =>
                  setFilters({ ...filters, dataTituStart: e.target.value })
                }
              />
              <small className="d-block ml-2 mr-2">até</small>
              <TextField
                type="date"
                value={filters.dataTituEnd}
                onChange={(e) =>
                  setFilters({ ...filters, dataTituEnd: e.target.value })
                }
              />
            </div>
          </Col>
          <Col lg={6} className="mb-0 mt-0">
            <small className="d-block mb-2">Valor</small>
            <div className="d-flex align-items-center">
              <TextField
                placeholder="0,00"
                value={filters.valoTituStart}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    valoTituStart: normalizeCurrency(
                      Number(e.target.value.replace(/[^\d]/g, ''))
                    ),
                  })
                }
              />
              <small className="d-block ml-2 mr-2">até</small>
              <TextField
                placeholder="0,00"
                value={filters.valoTituEnd}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    valoTituEnd: normalizeCurrency(
                      Number(e.target.value.replace(/[^\d]/g, ''))
                    ),
                  })
                }
              />
            </div>
          </Col>
          <Col lg={6} className="mb-0 mt-0 d-flex align-items-end">
            <TextField
              label="CMC7"
              value={filters.cmc7}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  cmc7: normalizeNumber(e.target.value),
                })
              }
            />
          </Col>
          <Col lg={6} className="mb-0 mt-0 d-flex align-items-end">
            <TextField
              label="CPF/CNPJ Sacado"
              value={filters.sacaId}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  sacaId: normalizeCpfCnpj(e.target.value),
                })
              }
            />
          </Col>
        </Row>
      )}
      <ModalDelete
        open={confirmDelete.open}
        setOpen={() =>
          setConfirmDelete({
            id: 0,
            open: false,
          })
        }
        onConfirm={() => handleDelete()}
      />
      <ModalDeleteAll
        open={confirmDeleteAll.open}
        setOpen={() =>
          setConfirmDeleteAll({
            open: false,
          })
        }
        onConfirm={() => handleDeleteAll()}
      />
      <ModalEditPayer
        open={updatePayer.open}
        onAdd={() => {
          setUpdatePayer({
            open: false,
            title: {} as Title,
          });
          loadData();
        }}
        setOpen={() =>
          setUpdatePayer({
            open: false,
            title: {} as Title,
          })
        }
        note={updatePayer.title}
      />
      <ModalTapNote
        open={digitOpen}
        setOpen={(e) => setDigitOpen(e)}
        onAdd={() => loadData()}
      />
      {loading && <LinearProgress />}
      <Table responsive hover>
        <thead>
          <tr>
            <th className="text-left">Cliente</th>
            <th>CMC7</th>
            <th>Vencimento</th>
            <th>Valor</th>
            {/* {permissions?.find((i) => i.name === 'DEFI_CC_HF') && (
              <th>Desconto</th>
            )} */}
            <th />
            <th>
              <Checkbox
                checked={selected.length === titles.length}
                onClick={handleAll}
                color="primary"
                disabled={loading}
              />
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {titles.map((n) => {
            const checkedV = selected.find(
              (i) => `${i.cmc7}${i.valo_titu}` === `${n.cmc7}${n.valo_titu}`
            );
            return (
              <tr
                key={`${n.cmc7}${n.valo_titu}`}
                className={checkedV ? 'selected' : ''}
              >
                <td>
                  <strong>{n.nome}</strong>
                  <br />
                  <small>{normalizeCpfCnpj(n.saca_id || '')}</small>
                </td>
                <td>{n.cmc7}</td>
                <td>
                  {n.data_titu
                    ? format(addHours(new Date(n.data_titu), 3), 'dd/MM/yyyy')
                    : ''}
                </td>
                <td>{normalizeBrl(n.valo_titu)}</td>
                <td style={{ width: '10px' }}>
                  {!payerIsComplete(n, false) && (
                    <button
                      type="button"
                      onClick={() =>
                        setUpdatePayer({
                          open: true,
                          title: n,
                        })
                      }
                    >
                      <Warning className="text-warning" />
                    </button>
                  )}
                </td>
                <td
                  style={{ width: '10px' }}
                  onClick={() => {
                    if (!payerIsComplete(n, false)) {
                      setUpdatePayer({
                        open: true,
                        title: n,
                      });
                    } else if (!loading) {
                      handleCheck(n);
                    }
                  }}
                >
                  <Checkbox checked={!!checkedV} color="primary" />
                </td>
                <td style={{ width: '20px' }}>
                  <button
                    type="button"
                    onClick={() =>
                      setConfirmDelete({
                        open: true,
                        id: n.id || 0,
                      })
                    }
                  >
                    <Delete className="text-danger" />
                  </button>
                </td>
              </tr>
            );
          })}
          {!loading && titles.length === 0 && (
            <tr style={{ height: '350px' }}>
              <td className="text-center" colSpan={8}>
                <Lottie options={defaultOptions} height={160} width={160} />
                <h4>Nenhum cheque encontrado.</h4>
                <h5>
                  Digite cheques que deseja antecipar para torná-los visíveis
                  nesta lista
                </h5>
                .
                <Button
                  type="button"
                  startIcon={<Keyboard />}
                  onClick={() => setDigitOpen(true)}
                  color="primary"
                  variant="contained"
                  className="mt-3 text-white"
                >
                  Digitar Cheque
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableOne;
